import {
  InspectionType,
  ReviewRequiredPolicy,
} from '@main-application/inspections/models/rest-inspections-model.interface';
import { oneSecondInMilliseconds } from '@shared/functions/date-utils.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export const InspectionTypeListConst: IRadioButtonOption<InspectionType>[] = [
  {
    value: InspectionType.MoveOutVacant,
    label: 'Move-out Vacant',
    sort: 0,
    description: 'Occurs immediately after resident move out to thoroughly assess unit condition.',
  },
  {
    value: InspectionType.CustomInspection,
    label: 'Custom',
    sort: 1,
  },
];

export const GeneratingInspectionsDuration = oneSecondInMilliseconds * 30;

export const ReviewRequiredPolicyList = [
  {
    value: ReviewRequiredPolicy.Always,
    label: 'Always',
  },
  {
    value: ReviewRequiredPolicy.NotRequired,
    label: 'Not required',
  },
  {
    value: ReviewRequiredPolicy.RequireIfNonDefaultRatingSelected,
    label: 'If non-default ratings are submitted',
  },
];
