import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromInspections from './inspections.reducer';

export const selectInspectionState = createFeatureSelector<fromInspections.InspectionState>(
  fromInspections.inspectionsFeatureKey
);

export const selectInspections = createSelector(selectInspectionState, inspectionState => inspectionState.listItems);

export const selectInspectionsLoading = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.listItemsLoading
);

export const selectDynamicInspections = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.dynamicListItems
);
export const selectDynamicInspectionsLoading = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.dynamicListItemsLoading
);

export const selectRepeatingInspections = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.repeatingListItems
);
export const selectRepeatingInspectionsLoading = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.repeatingListItemsLoading
);

export const selectInspectionFilter = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.inspectionFilter
);

export const selectInspectionsSummaryReport = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.summaryStats
);

export const selectInspectionsSummaryReportLoading = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.summaryReportDataLoading
);

export const selectInspectionsSummaryReportAppliedFilter = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.summaryStatsAppliedFilters
);

export const selectInReviewInspections = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.inReviewListItems
);

export const selectInReviewInspectionsLoading = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.inReviewListItemsLoading
);

export const selectInReviewInspectionFilter = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.inReviewInspectionFilter
);

export const selectRepeatingInspectionFilter = createSelector(
  selectInspectionState,
  inspectionState => inspectionState.repeatingInspectionFilter
);
