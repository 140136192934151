import { isDefAndNotNull } from '@app/utils/def';
import { RestTicketModel } from '@shared/interfaces/turnover.interface';

import { InspectionPage } from './inspection-area.model';
import { PropertyState } from './inspection.model';

export class InspectionStepModel {
  readonly propertyState: PropertyState;
  readonly propertyStateCount: number;
  readonly fileUploadCount: number;
  readonly notesCount: number;
  readonly imagesCount: number;
  note: string;
  readonly files: { fileUploadId: number }[];
  readonly questions: [string, boolean][];
  readonly emoji: string;
  readonly emojiName: string;
  readonly emojiCode: string;
  readonly stepName: string;
  readonly quantity: string;
  readonly hours: string;
  readonly tickets: RestTicketModel[];

  constructor(
    public readonly page: InspectionPage,
    tickets: RestTicketModel[],
    public readonly areaName = '',
    public readonly areaId: number,
    public readonly index: number
  ) {
    this.tickets = tickets?.filter(t => t.surveyPageId === index || t.surveyPageId === undefined) || [];
    const noteElms = page.elements?.filter(elm => elm.type?.toLowerCase() === 'comment');
    const fileElms = page.elements?.filter(elm => elm.type?.toLowerCase() === 'file');
    const stateElms = page.elements?.filter(elm => elm.type?.toLowerCase() === 'propertystate');
    const quantityElms = page.elements?.filter(elm => elm.type?.toLowerCase() === 'quantity');
    const hoursElms = page.elements?.filter(elm => elm.type?.toLowerCase() === 'hours');

    let questionsElms = [];
    const panel = page.elements?.find(elm => elm.type === 'panel');
    if (panel) {
      questionsElms = page.elements?.find(elm => elm.type === 'panel' && elm.elements.length)?.elements;
    } else {
      questionsElms = page.elements?.filter(elm => ![...noteElms, ...fileElms, ...stateElms].includes(elm));
    }

    this.stepName = page.title || '';
    this.questions =
      questionsElms
        ?.filter(qElm => qElm.type?.toLowerCase() !== 'quantity' && qElm.type?.toLowerCase() !== 'hours')
        ?.filter(qElm => isDefAndNotNull(qElm.value) || isDefAndNotNull(qElm.defaultValue))
        ?.map(qElm => [
          qElm.title,
          isDefAndNotNull(qElm.value) ? qElm.value === 'true' : qElm.defaultValue === 'true',
        ]) || [];
    this.notesCount = noteElms?.length ?? 0;
    this.fileUploadCount = fileElms?.length ?? 0;
    this.propertyStateCount = stateElms?.length ?? 0;
    this.imagesCount = page.mainImage?.length ?? 0;

    this.note = noteElms?.[0]?.value ?? '';
    this.files = page.mainImage;
    if (this.propertyStateCount > 0) {
      this.propertyState = stateElms[0].value as PropertyState;
      if (stateElms[0].items?.length) {
        const elementItem = stateElms[0].items.find(x => x.item.id === this.propertyState);
        if (elementItem) {
          const emojiCode = Number('0x' + elementItem.item.emoji);
          if (!isNaN(emojiCode)) {
            this.emoji = String.fromCodePoint(emojiCode);
            this.emojiName = elementItem.item.text;
            this.emojiCode = elementItem.item.emoji;
          }

          if (quantityElms?.length && elementItem.item.showQtyWidget) {
            this.quantity = quantityElms[0].value;
          }

          if (hoursElms?.length && elementItem.item.onSelect?.includes('showHoursWidget')) {
            if (hoursElms[0].value) {
              //TODO: convert to pipe? https://stackoverflow.com/a/50558928 but we need to save it as 00:00 to auto pass it to ticket creation
              const value = parseInt(hoursElms[0].value);
              const minutes = Math.floor(value / 60);

              this.hours =
                minutes.toString().padStart(2, '0') + ':' + (value - minutes * 60).toString().padStart(2, '0');
            } else {
              this.hours = '0';
            }
          }
        }
      } else {
        const { emoji, emojiCode, emojiName } = this.getDefaultEmojiCode(this.propertyState);
        this.emoji = emoji;
        this.emojiCode = emojiCode;
        this.emojiName = emojiName;
      }
    }
  }

  get isCustomStep() {
    return this.notesCount === 1 && this.fileUploadCount === 1 && this.propertyStateCount === 0;
  }

  getDefaultEmojiCode(state: PropertyState) {
    switch (state) {
      case 'good':
        return { emoji: String.fromCodePoint(Number('0x1f60d')), emojiCode: '1f60d', emojiName: 'Good' };
      case 'fair':
        return { emoji: String.fromCodePoint(Number('0x1f610')), emojiCode: '1f610', emojiName: 'Fair' };
      case 'poor':
        return { emoji: String.fromCodePoint(Number('0x1f4a9')), emojiCode: '1f4a9', emojiName: 'Poor' };
    }
    return {};
  }

  get flagsCount() {
    return 0; // TODO
  }
}
