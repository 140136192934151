import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

import { WorkflowPhaseType } from './workflow-phase-type';

export const WorkflowPhaseToStepConst: { [key: number]: IRadioButtonOption<WorkflowStepEnumType>[] } = {
  [WorkflowPhaseType.NotReady]: [
    {
      value: WorkflowStepEnumType.Notice,
      label: 'NOTICE',
    },
    {
      value: WorkflowStepEnumType.MoveOut,
      label: 'MOVE OUT',
    },
    {
      value: WorkflowStepEnumType.MakeReady,
      label: 'MAKE READY',
    },
    {
      value: WorkflowStepEnumType.Reno,
      label: 'RENO',
    },
    {
      value: WorkflowStepEnumType.Punch,
      label: 'PUNCH',
    },
    {
      value: WorkflowStepEnumType.Approval,
      label: 'APPROVAL',
    },
  ],
  [WorkflowPhaseType.Ready]: [
    {
      value: WorkflowStepEnumType.LeaseUp,
      label: 'LEASE UP',
    },
    {
      value: WorkflowStepEnumType.MoveIn,
      label: 'MOVE IN',
    },
  ],
};
