import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';

import { ScrollToRequiredContainerDirective } from './scroll-to-required-container.directive';

@Directive({
  selector: '[appScrollToReq]',
})
export class ScrollToRequiredDirective extends PuSubscribable {
  // requires the defined scroll container - [appScrollToReqContainer]
  // requires the submit button inside the form or workaround
  // see AddTicketModalComponent for workaround example - use of external button with OnSubmit()

  private get containerEl(): HTMLElement {
    return this.scrollContainer.containerEl;
  }

  constructor(
    private el: ElementRef,
    private formGroup: FormGroupDirective,
    private scrollContainer: ScrollToRequiredContainerDirective
  ) {
    super();
  }

  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroup.control.invalid) {
      this.scrollToFirstReq();
    }
  }

  private scrollToFirstReq() {
    const firstReq: HTMLElement = this.el.nativeElement.querySelector('.ng-invalid');

    this.containerEl.scroll({
      top: this.getTopOffset(firstReq),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(this.containerEl, 'scroll')
      .pipe(debounceTime(50), take(1))
      .subscribe(() => firstReq.focus())
      .untilDestroyed(this);
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const controlElTop = controlEl.getBoundingClientRect().top;
    const containerTop = this.containerEl.getBoundingClientRect().top;
    const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;
    return absoluteControlElTop - containerTop;
  }
}
