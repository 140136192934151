import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { RestWorkflowStepCommentModel } from '@shared/interfaces/comments.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export const WorkflowStepListConst: IRadioButtonOption<WorkflowStepEnumType>[] = [
  {
    value: WorkflowStepEnumType.Notice,
    label: 'NOTICE',
  },
  {
    value: WorkflowStepEnumType.MoveOut,
    label: 'MOVE OUT',
  },
  {
    value: WorkflowStepEnumType.Bid,
    label: 'BID',
  },
  {
    value: WorkflowStepEnumType.MakeReady,
    label: 'MAKE READY',
  },
  {
    value: WorkflowStepEnumType.Reno,
    label: 'RENO',
  },
  {
    value: WorkflowStepEnumType.Punch,
    label: 'PUNCH',
  },
  {
    value: WorkflowStepEnumType.Approval,
    label: 'APPROVAL',
  },
  {
    value: WorkflowStepEnumType.Marketing,
    label: 'MARKETING',
  },
  {
    value: WorkflowStepEnumType.LeaseUp,
    label: 'LEASE UP',
  },
  {
    value: WorkflowStepEnumType.MoveIn,
    label: 'MOVE IN',
  },
  {
    value: WorkflowStepEnumType.Archive,
    label: 'ARCHIVE',
  },
];

export const WorkflowStepListActive: IRadioButtonOption<WorkflowStepEnumType>[] = WorkflowStepListConst.filter(
  e => ![WorkflowStepEnumType.Bid, WorkflowStepEnumType.Marketing, WorkflowStepEnumType.Archive].includes(e.value)
);

export function workflowStepCommentsWithLinks(list: RestWorkflowStepCommentModel[]): RestWorkflowStepCommentModel[] {
  return list.map(el => {
    const urlMatch = el.commentText.match(/https:\/\/[^\s]+/g);
    if (urlMatch) {
      urlMatch.forEach(url => {
        el.commentText = el.commentText.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
      });
    }

    return el;
  });
}
