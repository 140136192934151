import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { selectUserPermissionLevel } from '@main-application/store/selectors/user.selectors';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { checkPermissionLevel } from '@shared/functions/check-permission-level.function';

@Directive({
  selector: '[appHavePermission]',
})
@UntilDestroy()
export class HavePermissionDirective extends PuSubscribable implements OnInit {
  permissionList: PermissionLevelType[] = [];
  permissionLevel: PermissionLevelType | PermissionLevelType[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<{}>
  ) {
    super();
  }

  @Input() set appHavePermission(permissionLevel: PermissionLevelType | PermissionLevelType[]) {
    this.permissionLevel = permissionLevel;
    this.checkPermission();
  }

  ngOnInit(): void {
    this.store
      .select(selectUserPermissionLevel)
      .pipe(
        untilDestroyed(this),
        filter((permissionList: PermissionLevelType[]) => !!permissionList),
        tap((permissionList: PermissionLevelType[]) => {
          this.permissionList = permissionList;
          this.checkPermission();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private checkPermission() {
    const hasPermission = checkPermissionLevel(this.permissionList, this.permissionLevel);
    this.viewContainer.clear();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
