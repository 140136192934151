import { Injectable } from '@angular/core';
import { sumBy } from 'lodash';
import * as moment from 'moment/moment';
import { BehaviorSubject } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import {
  ActiveWorkflowStepI,
  WorkflowStepI,
} from '@dashboards/components/avg-turn-chart-card/avg-turn-chart.interface';
import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import { KpiWindowType } from '@shared/enums/kpi-window-type';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  windowType$ = new BehaviorSubject<KpiWindowType>(null);
  workflowStep$ = new BehaviorSubject<WorkflowStepI>(null);
  activeWorkflowStep$ = new BehaviorSubject<ActiveWorkflowStepI>(null);

  constructor(private restApiService: RestapiService) {}

  private filterKpiByType(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType): RestPortfolioKpi[] {
    return kpis.filter(k => k.kpiType === kpiType);
  }

  avg(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType, precision = 0, currentMonth = null): number | undefined {
    let filtered = this.filterKpiByType(kpis, kpiType);
    if (currentMonth) {
      filtered = filtered.filter(el => moment(el.calculationDate).format('MMM YYYY') === currentMonth);
    }
    const avg = sumBy(filtered, k => k.value * k.unitsCount) / sumBy(filtered, k => k.unitsCount);
    if (isNaN(avg)) {
      return undefined;
    } else {
      return !precision ? Math.round(avg) : parseFloat(avg.toFixed(precision));
    }
  }

  sum(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType, precision = 0): number {
    const filtered = this.filterKpiByType(kpis, kpiType);
    return parseFloat(sumBy(filtered, k => k.value).toFixed(precision));
  }

  percent(kpis: RestPortfolioKpi[], kpiType: PortfolioKpiType): number | undefined {
    const filtered = this.filterKpiByType(kpis, kpiType);
    const value = sumBy(filtered, k => k.value) / sumBy(filtered, k => k.unitsCount);
    if (isNaN(value)) {
      return undefined;
    }
    return Math.round(value * 100);
  }

  fetch(portfolioId: number, dateOffset: KpiWindowType, kpiTypes: PortfolioKpiType[]) {
    return this.restApiService.getData<RestPortfolioKpi[]>(
      `portfolio/${portfolioId}/kpis?dateOffset=${dateOffset}&${kpiTypes.map(k => `kpiIds=${k}`).join('&')}`
    );
  }

  trend(portfolioId: number, kpiTypes: PortfolioKpiType[], trendDays: number[]) {
    return this.restApiService.getData<RestPortfolioKpi[]>(
      `portfolio/${portfolioId}/kpisTrend?kpi=[${kpiTypes.map(k => k).join(',')}]&trend_line=[${trendDays.join(',')}]`
    );
  }
}
