<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle">Accept charges?</div>
  </div>

  <mat-dialog-content>
    <div style="padding-bottom: 16px">{{ acceptChargesText$ | async }}</div>
    <div class="no-shadow non-pms-modal-form" [formGroup]="form">
      <app-radio-button-dropdown
        [containerCss]="'new-turnover-modal-fields w-100'"
        [items]="userProperties$ | async"
        [allowSearch]="(userProperties$ | async)?.length > 7"
        [attrDisable]="false"
        [label]="'Property'"
        formControlName="propertyId"
        [allowClear]="false"
        [attrPlaceholder]="'Select property'"
        [showValidationError]="formSubmited"
        attrAppendTo="body"></app-radio-button-dropdown>
      <app-input
        [labelCss]="'nowrap pre-label'"
        [containerCss]="'display-flex flex-column grid-gap-4'"
        [attrPlaceholder]="'unit name...'"
        formControlName="unitName"
        [attrLabel]="'Name'"
        [showValidationError]="formSubmited"></app-input>
    </div>
    <div class="remark">
      <b>Note:</b>
      <span>you can edit or delete this unit anytime from Unit Details</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="display-flex grid-gap-8 justify-content-end w-100">
      <button mat-stroked-button class="modal-button" (click)="close()">No, go back</button>
      <button mat-raised-button color="warn" class="modal-button" (click)="ok()">I accept</button>
    </div>
  </mat-dialog-actions>
</div>
