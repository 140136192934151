<div class="leasing-units-chart-card-container">
  <app-chart-card
    [chartIcon]="EIcon.KEY"
    [title]="'Leasing'"
    [subTitle]="subTitle"
    [isLoading]="isLoading"
    class="w-100"
    [customDescriptionTemplate]="switcher">
    <div chart class="display-flex w-100">
      <app-chart
        [chartDataList]="chartDataList"
        [chartType]="ChartTypes.BAR"
        [chartConfig]="chartConfig"
        [displayLegend]="false"
        class="w-100"></app-chart>
    </div>
  </app-chart-card>
</div>

<ng-template #switcher>
  <div class="switcher">
    <app-slide-toggle
      [attrLeftLabel]="'Absolute'"
      [attrRightLabel]="'Percentage'"
      [formControl]="modeControl"></app-slide-toggle>
  </div>
</ng-template>
