import { CdkDrag } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PuSubscribable } from '@app/utils/pu-subscribe';

@UntilDestroy()
@Directive({
  selector: '[appDragCursor]',
})
export class DragCursorDirective extends PuSubscribable implements OnInit {
  @Input() cursor = 'grabbing';

  constructor(@Inject(DOCUMENT) private document: Document, private cdkDrag: CdkDrag) {
    super();
  }

  public ngOnInit(): void {
    this.cdkDrag.started
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.document.body.style.cursor = this.cursor;
      })
      .untilDestroyed(this);

    this.cdkDrag.ended
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.document.body.style.cursor = 'auto';
      })
      .untilDestroyed(this);
  }
}
