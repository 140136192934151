<div
  class="inspection-name"
  #headerName
  [attr.contenteditable]="editMode"
  (click)="enterEditMode()"
  (blur)="leaveEditMode(headerName.textContent)"
  (keydown)="nameChanged($event)"
  placeholder="Template name...">
  <ng-container *ngIf="header">
    <div [appFadeTextOnWidth]="445" [titleLength]="header?.length" [matTooltip]="header">
      {{ header }}
    </div>
  </ng-container>
</div>
