<ng-container>
  <div mat-dialog-title class="display-flex justify-content-space-between">
    <h4 class="flex-1">{{ data.inspectionId ? 'Edit' : 'Add' }} inspection</h4>
    <div class="flex-30" *ngIf="!data.inspectionId">
      <app-radio-button-dropdown
        class="flex-grow-1"
        [containerCss]="'w-100'"
        [items]="inspectionScheduleTypeList$ | async"
        [formControl]="inspectionTypeControl"
        [allowClear]="false"
        [allowSearch]="false"
        [autoSortList]="false"
        [showValidationError]="false"
        [optionCss]="'inspection-format-option'"
        attrAppendTo="body"></app-radio-button-dropdown>
    </div>
  </div>
  <mat-dialog-content>
    <ng-container [ngSwitch]="inspectionTypeControl.value">
      <app-inspection-add-dynamic-form
        *ngIf="(formType$ | async) === 'dynamic'"
        [inspectionId]="data.inspectionId"
        [portfolioId]="data?.portfolioId"
        [inspectionType]="inspectionTypeControl.value"
        [saveAction]="saveAction"
        [formDefaults]="formDefaults"
        (loadingDynamicError)="closeModal()"
        (formChanged)="formChanged($event)"
        (formPostedEvent)="formPosted($event)"></app-inspection-add-dynamic-form>
      <app-inspection-add-regular-form
        *ngIf="(formType$ | async) === 'regular'"
        [inspectionId]="data.inspectionId"
        [inspectionType]="inspectionTypeControl.value"
        [saveAction]="saveAction"
        [formDefaults]="formDefaults"
        (formChanged)="formChanged($event)"
        (inspectionTypeChangedEvent)="inspectionTypeChanged($event)"
        (formPostedEvent)="formPosted($event)"></app-inspection-add-regular-form>
    </ng-container>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions class="actions">
  <app-button
    [buttonColor]="'warn'"
    [buttonType]="ButtonType.STROKED"
    (clickEvent)="cancel()"
    [buttonLabel]="'Cancel'"></app-button>
  <button mat-raised-button color="primary" [disabled]="creating" (click)="save()">
    <div class="display-flex align-items-center grid-gap-4">
      {{ data.inspectionId ? 'Save' : 'Create' }}
      <mat-spinner *ngIf="creating" color="accent" diameter="20"></mat-spinner>
    </div>
  </button>
</mat-dialog-actions>
