import { Injectable } from '@angular/core';
import { BehaviorSubject, asapScheduler, observeOn } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubjectsService {
  templatesFocusOutEditor$ = new BehaviorSubject<void | null>(null);
  templatesFocusOutEditorWithObserveOn$ = this.templatesFocusOutEditor$.pipe(observeOn(asapScheduler));
}
