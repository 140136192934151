import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputAreaComponent extends CustomControlAbstract<string> implements OnInit, ControlValueAccessor {
  errors: ValidationErrors;
  invalid = false;
  disabled = false;

  @Input() autoresize = false;
  @Input() maxHeight: number;
  @Input() displayNewLineHint = false;
  @Input() attrPlaceholder = '';
  @Input() attrName: string;
  @Input() label: string;
  @Input() labelCss = 'nowrap body-small-bold';
  @Input() containerCss = '';
  @Input() inputCss = '';
  @Input() errorSection = true;
  @Input() markAsInvalid = false;
  @Input() displayCounter = false;
  @Input() maxCounter: number;
  @Input() inputDisabled = false;
  @Input() scrollEnabled = false;
  @Input() displaySkeleton = false;
  @Input() isSmallFont = false;
  @Output() keydownEnterEvent = new EventEmitter<void>();
  @Output() blurEvent = new EventEmitter<void>();
  @Output() focusEvent = new EventEmitter<boolean>();

  focusIn = false;

  @ViewChild('textArea') textArea!: ElementRef;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  @Input() set attrDisable(attrDisable: boolean) {
    if (attrDisable) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.disabled = attrDisable;
    this.cdr.detectChanges();
  }

  @Input() set value(value: string) {
    this.control.setValue(value);
  }

  ngOnInit(): void {
    this.initControlBase();

    this.control.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        this.onChanged(value);
        this.onTouched();
      })
      .untilDestroyed(this);
  }

  writeValue(value: string): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  onBlur() {
    this.blurEvent.emit();
  }

  enter($event) {
    if (this.keydownEnterEvent.observed) {
      this.keydownEnterEvent.emit();
      $event.preventDefault();
    }
  }

  insertNewline($event) {
    const newValue = `${this.control?.value || ''}\n`;
    this.control.setValue(newValue);
  }

  onFocusIn(): void {
    this.focusIn = true;
    this.focusEvent.emit(true);
    if (this.scrollEnabled) this.scrollToBottomIfScrollable();
  }

  onFocusOut(): void {
    this.focusIn = false;
    this.focusEvent.emit(false);
    if (this.scrollEnabled) this.scrollToTopIfScrollable();
  }

  scrollToBottomIfScrollable(): void {
    const textareaEl = this.textArea.nativeElement;
    const isScrollable = textareaEl.scrollHeight > textareaEl.clientHeight;

    if (isScrollable) {
      textareaEl.scrollTo({
        top: textareaEl.scrollHeight,
        behavior: 'smooth',
      });
    }
    setTimeout(() => {
      textareaEl.setSelectionRange(textareaEl.value.length, textareaEl.value.length);
      textareaEl.focus();
    });
  }

  scrollToTopIfScrollable(): void {
    const textareaEl = this.textArea.nativeElement;

    const isScrollable = textareaEl.scrollHeight > textareaEl.clientHeight;

    if (isScrollable) {
      textareaEl.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
