import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatChipList } from '@angular/material/chips';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { PillOptions } from '@shared/enums/pill-options.enum';
import { ITooltipRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

@UntilDestroy()
@Component({
  selector: 'app-pill-list',
  templateUrl: './pill-list.component.html',
  styleUrls: ['./pill-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillListComponent<T> extends PuSubscribable implements OnInit, ControlValueAccessor {
  availableOptionsValue: ITooltipRadioButtonOption<T>[] = [];

  @Input() set availableOptions(value: (PillOptions | string | ITooltipRadioButtonOption<T>)[]) {
    if (value.length && typeof value[0] != 'object') {
      this.availableOptionsValue = value.map(e => ({
        label: e as string,
        value: e as T,
        tooltip: '',
      }));
    } else {
      this.availableOptionsValue = value as ITooltipRadioButtonOption<T>[];
    }
  }

  @Input() selectable = true;
  @Input() pillCss = '';
  @Input() pillListCss: string;
  @Input() disabled = false;

  @ViewChild(MatChipList) chipList!: MatChipList;

  control = new UntypedFormControl();

  constructor(@Self() @Optional() protected ngControl: NgControl, private cdr: ChangeDetectorRef) {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: string): void {
    this.control.setValue(value);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.onChanged(value);
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  selectOption(option: T) {
    if (this.selectable) {
      this.control.setValue(option);
    }
  }

  private onChanged = (value: string) => {
    // to set later
  };

  private onTouched = () => {
    // to set later
  };
}
