<div class="wysiwyg-editor">
  <quill-editor
    [class.hideToolbar]="readonly"
    [styles]="styles"
    [modules]="modules"
    [formControl]="control"
    (onFocus)="onFocus.emit(); focused = true"
    (onBlur)="onBlur.emit(); focused = false"
    (onEditorCreated)="editorReady($event)"
    (onContentChanged)="textChanged($event)"
    [maxLength]="maxLength"
    [placeholder]="placeholder"></quill-editor>
  <div class="counter-container">
    <div [hidden]="!showCurrentLength || readonly" [class.invalid]="!isValid" class="counter">
      {{ (currentLength$ | async) - 1 }}/{{ maxLength }}
    </div>
  </div>
</div>
