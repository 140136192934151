import { MetaReducer, createReducer, on } from '@ngrx/store';

import {
  initPropertySelector,
  setJoinedProperties,
  setPropertySelectorSwitchStatus,
  setSelectedCollectionIds,
  setSelectedPortfolioId,
  setSelectedPropertyIds,
} from '@dashboards/store/actions/property-selector.actions';
import {
  dynamicHydrationMetaReducer,
  patchInitialValue,
} from '@main-application/store/reducers/hydration-meta.reducer';

export const propertySelectorFeatureKey = 'property-selector';
let dynamicPropertySelectorFeatureKey = '';

const getPropertySelectorFeatureKey = (tenantId: number, userId: number) =>
  `${propertySelectorFeatureKey}_${tenantId}_${userId}`;

export enum PropertySelectorSwitchStatus {
  PROPERTY = 'property',
  COLLECTION = 'collection',
}

export interface PropertySelectorState {
  portfolioId?: number;
  propertySelectorSwitchStatus: PropertySelectorSwitchStatus.PROPERTY | PropertySelectorSwitchStatus.COLLECTION;
  propertyIds?: number[];
  collectionIds?: number[];
  allPropertiesSelected?: boolean;
  joinProperties: boolean;
}

export const initialState: PropertySelectorState = patchInitialValue(
  {
    portfolioId: undefined,
    propertySelectorSwitchStatus: PropertySelectorSwitchStatus.PROPERTY,
    propertyIds: undefined,
    allPropertiesSelected: undefined,
    joinProperties: false,
  },
  propertySelectorFeatureKey
);

export const metaPropertySelectorReducers: Array<MetaReducer<any, any>> = [
  dynamicHydrationMetaReducer<PropertySelectorState>(
    () => dynamicPropertySelectorFeatureKey,
    propertySelectorFeatureKey
  ),
];

export const propertySelectorReducer = createReducer(
  initialState,
  on(initPropertySelector, (state, action) => {
    dynamicPropertySelectorFeatureKey = getPropertySelectorFeatureKey(action.tenantId, action.userId);
    return {
      ...patchInitialValue(state, dynamicPropertySelectorFeatureKey),
    };
  }),
  on(setSelectedPortfolioId, (state, action) => ({ ...state, portfolioId: action.portfolioId })),
  on(setSelectedPropertyIds, (state, action) => {
    const newState = {
      ...state,
      propertyIds: action.propertyIds,
      allPropertiesSelected: action.allPropertiesSelected,
    };
    return newState;
  }),
  on(setSelectedCollectionIds, (state, action) => {
    const newState = {
      ...state,
      collectionIds: action.collectionIds,
    };
    return newState;
  }),
  on(setPropertySelectorSwitchStatus, (state, action) => ({
    ...state,
    propertySelectorSwitchStatus: action.propertySelectorSwitchStatus,
  })),
  on(setJoinedProperties, (state, action) => ({
    ...state,
    joinProperties: action.state,
  }))
);
