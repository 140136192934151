import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import * as fromDashboardKpis from '@dashboards/store/reducers/kpi.reducer';
import { selectSelectedPropertyIds } from '@dashboards/store/selectors/property-selector.selectors';

export const selectDashboardKpisState = createFeatureSelector<fromDashboardKpis.DashboardKpisState>(
  fromDashboardKpis.kpiFeatureKey
);

/**
 * dashboards portfolio kpi
 */

export const selectPortfolioKpis = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpis
);

const selectPortfolioKpisTrend = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpisTrend
);

export const selectPortfolioKpisLoading = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpisLoading
);

export const selectPortfolioKpisTrendLoading = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpiTrendLinesLoading
);

export const selectPortfolioKpisFilter = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpisFilter
);

export const selectPortfolioKpisBasedOnView = createSelector(
  selectPortfolioKpis,
  selectSelectedPropertyIds,
  (kpis, propertyIds: number[]) => {
    return getFilteredKpis(kpis, propertyIds);
  }
);

export const selectPortfolioKpisTrendBasedOnView = createSelector(
  selectPortfolioKpisTrend,
  selectSelectedPropertyIds,
  (kpis, propertyIds: number[]) => {
    return getFilteredKpis(kpis, propertyIds);
  }
);

export const selectPortfolioKpiTrendLines = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpiTrendLines
);

export const selectPortfolioKpiWorkflowSteps = createSelector(
  selectDashboardKpisState,
  dashboardsState => dashboardsState.portfolioKpiWorkflowSteps
);

export const selectChartSelectorValue = (selectorId: string) =>
  createSelector(selectDashboardKpisState, dashboardsState => dashboardsState.chartSelectors[selectorId]);

export const selectChartSettingsValue = <T>(chartId: string) =>
  createSelector(selectDashboardKpisState, dashboardsState => dashboardsState.chartSettings[chartId] as T);

function getFilteredKpis(kpis: RestPortfolioKpi[], propertyIds: number[]): RestPortfolioKpi[] {
  return kpis.filter(k => !propertyIds || propertyIds.includes(k.propertyId));
}
