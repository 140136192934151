import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { includes } from 'lodash';
import { Observable, map, shareReplay } from 'rxjs';
import { distinctUntilChanged, filter, tap, withLatestFrom } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { getWorkflowStepFromSchedule } from '@main-application/inspections/components/inspection-add-dialog/functions/get-workflow-step-from-schedule';
import { InspectionFormPostedResult } from '@main-application/inspections/components/inspection-add-dialog/inspection-add-regular-form/inspection-add-regular-form.component';
import { InspectionScheduleTypeListConst } from '@main-application/inspections/models/inspection-type-list.const';
import { InspectionScheduleType } from '@main-application/inspections/models/rest-inspections-model.interface';
import { InspectionService } from '@main-application/inspections/services/inspection.service';
import { setNewlyAddedInspection } from '@main-application/inspections/store/inspections.actions';
import { selectWorkflowStepsEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { selectTenantData, selectUserData } from '@main-application/store/selectors/user.selectors';
import { GeneratingInspectionsDuration } from '@shared/constants/inspection-type-list.const';
import { ButtonType } from '@shared/enums/button-type';
import { EIcon } from '@shared/enums/icon.enum';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { checkPermissionLevel } from '@shared/functions/check-permission-level.function';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserData } from '@shared/interfaces/user-data';
import { HelpRouterService } from '@shared/services/help-router.service';
import { valueChanges } from '@shared/utils/rxjs/value-changes.rxjs.util';

export interface InspectionDialogData {
  inspectionId?: number;
  portfolioId?: number;
  inspectionFormat?: InspectionScheduleType;
}

export interface InspectionFormDefaults {
  inspectionTemplateId: number | null;
  repeatUntil: Date | null;
  propertyId?: number | null;
  propertyIds?: number[] | null;
}

@UntilDestroy()
@Component({
  selector: 'app-inspection-add-dialog',
  templateUrl: './inspection-add-dialog.component.html',
  styleUrls: ['./inspection-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionAddDialogComponent extends PuSubscribable implements OnInit {
  protected readonly ButtonType = ButtonType;
  protected readonly EIcon = EIcon;
  private userData?: UserData;

  creating = false;
  inspectionTypeControl = new UntypedFormControl(InspectionScheduleType.Standard);
  saveAction = new EventEmitter<void>();
  formType$ = valueChanges(this.inspectionTypeControl).pipe(
    map(type => this.getInspectionFormType(type)),
    distinctUntilChanged(),
    shareReplay()
  );
  formDefaults: InspectionFormDefaults = {
    inspectionTemplateId: null,
    repeatUntil: null,
    propertyId: null,
  };

  inspectionScheduleTypeList$ = this.getWorkflowStepEnumeration().pipe(
    withLatestFrom(this.store.select(selectTenantData)),
    map(([workflowSteps, settings]) => {
      const getMappedOptions = InspectionScheduleTypeListConst.map(i => {
        const data: IRadioButtonOption<any> = {
          ...i,
        };
        const step = getWorkflowStepFromSchedule(i.value);
        const workflowStep = workflowSteps.find(s => s.enumerationValue === step);
        if (workflowStep) {
          const stepName = workflowStep.displayText.toUpperCase();
          data.label = `> ${stepName}`;
          data.displayValue = `Dynamic: ${stepName}`;
        }
        return data;
      });
      // TODO
      if (
        !settings?.residentInspectionEnabled ||
        !checkPermissionLevel(this.userData?.permissionLevel, PermissionLevelType.ResidentInspections_List)
      ) {
        return getMappedOptions.filter(i => i.value !== InspectionScheduleType.Resident);
      }

      return getMappedOptions;
    })
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private helpRouterService: HelpRouterService,
    private store: Store<{}>,
    public dialogRef: MatDialogRef<InspectionAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: InspectionDialogData,
    private inspectionService: InspectionService
  ) {
    super();
    this.data = data || {};
  }

  ngOnInit(): void {
    this.inspectionTypeControl.setValue(this.data.inspectionFormat || InspectionScheduleType.Standard);

    this.store
      .select(selectUserData)
      .pipe(
        untilDestroyed(this),
        filter((userData: UserData) => !!userData),
        tap((userData: UserData) => {
          this.userData = userData;
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.inspectionTypeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => {
          this.checkTurnsPackage();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.checkTurnsPackage();
  }

  private checkTurnsPackage() {
    const { value } = this.inspectionTypeControl;
    if (![InspectionScheduleType.Standard, InspectionScheduleType.Repeating].includes(value)) {
      // TODO?
    }
    this.cdr.detectChanges();
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.creating = true;
    this.saveAction.emit();
  }

  formPosted(data: InspectionFormPostedResult) {
    if (!data.result) {
      this.creating = false;
      this.cdr.detectChanges();
      return;
    }

    const queryParams = this.helpRouterService.getGlobalPreservedParams();
    const isDynamic = includes(
      [
        InspectionScheduleType.DynamicNotice,
        InspectionScheduleType.DynamicMoveIn,
        InspectionScheduleType.DynamicMoveOut,
        InspectionScheduleType.DynamicMakeReady,
      ],
      this.inspectionTypeControl.value
    );

    const isStandart = this.inspectionTypeControl.value === InspectionScheduleType.Standard;
    const isResindent = this.inspectionTypeControl.value === InspectionScheduleType.Resident;
    const isRepeating = this.inspectionTypeControl.value === InspectionScheduleType.Repeating;

    const useVerifyCreatedInspection = data.result && data?.inspection?.['bulkUnitIds']?.length && isStandart;

    const handleResult = () => {
      this.dialogRef.close(true);

      if (useVerifyCreatedInspection) {
        const useFetchOneTime$ = this.inspectionService.fetchOneTimeInspectionList$;
        const getTime = GeneratingInspectionsDuration;
        setTimeout(() => useFetchOneTime$.next(), getTime);

        return;
      }
      this.store.dispatch(
        setNewlyAddedInspection({ inspection: data.inspection, inspectionType: this.inspectionTypeControl.value })
      );
    };
    if (isStandart) this.router.navigate(['inspections/user'], { queryParams }).then(handleResult);
    if (isResindent) this.router.navigate(['inspections/resident'], { queryParams }).then(handleResult);
    if (isRepeating) this.router.navigate(['inspections/repeating'], { queryParams }).then(handleResult);
    if (isDynamic) this.router.navigate(['inspections/dynamic'], { queryParams }).then(handleResult);
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  inspectionTypeChanged($event: InspectionScheduleType) {
    this.inspectionTypeControl.setValue($event);
    this.cdr.detectChanges();
  }

  private getInspectionFormType(type: InspectionScheduleType): 'dynamic' | 'regular' {
    if (
      [
        InspectionScheduleType.DynamicNotice,
        InspectionScheduleType.DynamicMoveIn,
        InspectionScheduleType.DynamicMoveOut,
        InspectionScheduleType.DynamicMakeReady,
      ].includes(type)
    ) {
      return 'dynamic';
    } else if (
      [InspectionScheduleType.Standard, InspectionScheduleType.Resident, InspectionScheduleType.Repeating].includes(
        type
      )
    ) {
      return 'regular';
    }
  }

  formChanged($event: InspectionFormDefaults) {
    this.formDefaults = $event;
  }

  private getWorkflowStepEnumeration(): Observable<EnumerationItem[]> {
    return this.store.select(selectWorkflowStepsEnumeration);
  }
}
