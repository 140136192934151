import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  inject,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';

import { debounceSafeDefault } from '../constants/debounce-time-safe-default.constant';
import { DestroyService } from '../services/destroy.service';
import { resizeObserver } from '../utils/dom/resize-observer.util';

@Directive({ selector: '[appTextOverflowCheck]', standalone: true, providers: [DestroyService] })
export class TextOverflowCheckDirective extends PuSubscribable implements AfterViewInit, OnDestroy {
  @Input('appTextOverflowCheck') additionalClass = '';
  @Input('appTextOverflowCheckDisabled') disabled = false;
  @Output() textOverflowed = new EventEmitter<boolean>();

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private resizeObserver!: ResizeObserver | null;
  private resizeSubject = new Subject<void>();

  destroy$ = inject(DestroyService);

  constructor() {
    super();
    if (this.disabled) return;
    this.resizeSubject
      .pipe(debounceTime(debounceSafeDefault), takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkOverflow();
      })
      .untilDestroyed(this);
  }

  ngAfterViewInit() {
    if (this.disabled) return;
    this.resizeObserver = resizeObserver(this.el.nativeElement, () => {
      this.resizeSubject.next();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.disabled) return;
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.resizeSubject.complete();
  }

  private checkOverflow(): void {
    if (this.disabled) return;
    const cell = this.el.nativeElement;
    const isOverflowing = cell.scrollWidth > cell.clientWidth;
    if (isOverflowing) {
      if (this.additionalClass) {
        this.renderer.addClass(cell, this.additionalClass);
      }
      this.textOverflowed.emit(true);
    }
  }
}
