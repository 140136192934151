import { Injectable } from '@angular/core';

import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';

@Injectable()
export class TurnoverKanbanService {
  getExpandedSections(userId: number): number[] {
    return (JSON.parse(localStorage.getItem(this.getFieldName(userId))) as number[]) || [];
  }

  setExpandedSection(userId: number, sectionsId: number[]) {
    localStorage.setItem(this.getFieldName(userId), JSON.stringify(sectionsId));
  }

  private getFieldName(userId: number): string {
    return `${LocalStorageDataEnum.KANBAN_ACTIVE_SECTIONS}_${userId}`;
  }
}
