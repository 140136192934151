import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { chunk } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { tap } from 'rxjs/operators';
import SwiperCore, { SwiperOptions, Virtual } from 'swiper';
import { Swiper } from 'swiper/types';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { RatingGroupList } from '@main-application/shared/template-editor-dialog/datas/rating-group-list.data';
import {
  TemplateEditorDefaultValue,
  TemplateEditorEmojiContent,
  TemplateEditorRatingGroupItem,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { AnimatedEmojiService } from '@main-application/shared/template-editor-dialog/services/animated-emoji.service';
import { ScoringModelsService } from '@main-application/shared/template-editor-dialog/services/scoring-models.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { SubjectsService } from '@shared/services/subjects.service';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import { CompulsoryItems } from '../../../../../../models/template-editor-model';

SwiperCore.use([Virtual]);

@UntilDestroy()
@Component({
  selector: 'app-template-editor-rating-group-item',
  templateUrl: './template-editor-rating-group-item.component.html',
  styleUrls: ['./template-editor-rating-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class TemplateEditorDialogRatingGroupItemComponent extends PuSubscribable implements OnInit, OnChanges {
  @Input() showEmoji = false;
  @Input() ratingGroupItem: TemplateEditorRatingGroupItem;
  @Input() ratingGroupItems: TemplateEditorRatingGroupItem[];
  @Input() showResultScore?: boolean;
  @Input() parentTitle?: string;
  @Input() greatGrandParent: RestTemplateModel;
  @Output() ratingGroupItemChange = new EventEmitter<TemplateEditorRatingGroupItem>();
  @Output() ratingGroupItemDelete = new EventEmitter();
  @Output() makeDefault = new EventEmitter<TemplateEditorRatingGroupItem>();

  protected readonly TemplateEditorDefaultValue = TemplateEditorDefaultValue;
  protected readonly EIcon = EIcon;
  protected readonly EColorPalette = EColorPalette;
  public ratingGroupList = RatingGroupList;

  public getRatingGroups() {
    const getCurrentItem = this.ratingGroupItem;
    const getAllItems = this.ratingGroupItems;

    const isItemActive = (name: string, item: TemplateEditorRatingGroupItem) => {
      if (!item) return false;
      if (name === 'showQtyWidget') return item?.item?.showQtyWidget;
      return item?.item?.onSelect?.includes(name as CompulsoryItems);
    };

    const getResult = this.ratingGroupList.map(iconItem => {
      const getAllWitchoutCurrent = getAllItems.filter(item => item?.item?.id !== getCurrentItem?.item?.id);
      const isSelectedElsewhere = getAllWitchoutCurrent.some(item => isItemActive(iconItem.name, item));
      const isActive = isItemActive(iconItem.name, getCurrentItem);

      return {
        ...iconItem,
        isActive,
        isSelectedElsewhere,
        isShow: isActive || isSelectedElsewhere,
      };
    });

    return getResult;
  }

  public get getRatingGroupsFiltred() {
    return this.getRatingGroups().filter(item => item.isShow);
  }

  form: FormGroup;
  editMode = false;
  emojisOpen = false;
  config: SwiperOptions = {
    virtual: true,
    loop: true,
    initialSlide: -1,
  };
  private swiper: Swiper;
  private emojiPerSlide = 15;
  activeEmojiSlideIndex = 0;
  emojiSlides: TemplateEditorEmojiContent[][] = [];
  resultScoreItems = [];

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('ratingName', { read: ElementRef, static: false }) ratingName: ElementRef<HTMLDivElement>;

  constructor(
    private animatedEmojiService: AnimatedEmojiService,
    private scoringModelsService: ScoringModelsService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private subjectsService: SubjectsService
  ) {
    super();
  }

  ngOnInit() {
    this.animatedEmojiService
      .getEmojiContent()
      .pipe(
        untilDestroyed(this),
        tap(emojis => {
          this.emojiSlides = chunk(emojis, this.emojiPerSlide);
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.scoringModelsService.scoringModel$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdr.detectChanges();
      })
      .untilDestroyed(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ratingGroupItem) {
      this.initForm();
    }
  }

  initForm() {
    this.form = this.fb.group({
      timeMultiplier: [this.ratingGroupItem.item.timeMultiplier, Validators.required],
      materialsMultiplier: [this.ratingGroupItem.item.materialsMultiplier, Validators.required],
      showQtyWidget: [this.ratingGroupItem.item.showQtyWidget],
      ticketRequired: [this.ratingGroupItem.item.onSelect.includes('ticketRequired')],
      showHoursWidget: [this.ratingGroupItem.item.onSelect.includes('showHoursWidget')],
      showSpareParts: [this.ratingGroupItem.item.onSelect.includes('showSpareParts')],
      sparePartsSelectionRequired: [this.ratingGroupItem.item.onSelect.includes('sparePartsSelectionRequired')],
      resultScore: [this.ratingGroupItem.item.resultScore ?? 0],
    });
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(
          (a, b) =>
            a.timeMultiplier == b.timeMultiplier &&
            a.materialsMultiplier == b.materialsMultiplier &&
            a.showQtyWidget == b.showQtyWidget &&
            a.ticketRequired == b.ticketRequired &&
            a.sparePartsSelectionRequired == b.sparePartsSelectionRequired &&
            a.showSpareParts == b.showSpareParts &&
            a.showHoursWidget == b.showHoursWidget &&
            a.resultScore == b.resultScore
        )
      )
      .subscribe(v => this.ratingGroupItemChange.emit({ item: { ...this.ratingGroupItem.item, ...v } }))
      .untilDestroyed(this);
    this.resultScoreItems = [
      { label: '100% of ' + this.parentTitle, value: 100 },
      { label: '75% of ' + this.parentTitle, value: 75 },
      { label: '50% of ' + this.parentTitle, value: 50 },
      { label: '25% of ' + this.parentTitle, value: 25 },
      { label: '0% of ' + this.parentTitle, value: 0 },
    ];
    this.cdr.detectChanges();
  }

  onMakeDefault(item: TemplateEditorRatingGroupItem) {
    this.makeDefault.emit(item);
  }

  private toggleOnSelect(onSelect: CompulsoryItems) {
    const newItem = {
      item: {
        ...this.ratingGroupItem.item,
        onSelect: this.ratingGroupItem.item.onSelect.includes(onSelect)
          ? this.ratingGroupItem.item.onSelect.filter(onSelectInner => onSelectInner !== onSelect)
          : [...this.ratingGroupItem.item.onSelect, onSelect],
      },
    };

    this.ratingGroupItemChange.emit(newItem);
  }

  toggleCommentOnSelect() {
    this.toggleOnSelect('commentRequired');
  }

  togglePhotoOnSelect() {
    this.toggleOnSelect('photoRequired');
  }

  changeIcon(icon: TemplateEditorEmojiContent) {
    this.trigger.closeMenu();
    this.ratingGroupItemChange.emit({ item: { ...this.ratingGroupItem.item, emoji: icon.emojiCode } });
  }

  enterEditMode() {
    if (this.editMode) {
      return;
    }
    this.editMode = true;
    this.cdr.detectChanges();
    const div = this.ratingName.nativeElement;
    setTimeout(() => {
      const range = document.createRange();
      range.selectNodeContents(div);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  onKeydown($event: KeyboardEvent, value: string) {
    if (
      value?.length >= 13 &&
      !($event.key === 'Backspace' || $event.key === 'Delete' || window.getSelection().getRangeAt(0).toString().length)
    ) {
      $event.stopPropagation();
      $event.preventDefault();
    }
    if ($event.key === 'Enter') {
      this.ratingName.nativeElement.blur();
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  leaveEditMode(value: string) {
    value = value.trim();
    this.editMode = false;
    if (this.ratingGroupItem.item.text !== value) {
      const nodes = this.ratingName.nativeElement.childNodes;
      let textNodes = 0;
      nodes.forEach(e => {
        if (e.nodeType == e.TEXT_NODE) textNodes++;
      });
      if (textNodes > 1) {
        nodes.forEach(e => {
          if (e.nodeType == e.TEXT_NODE) {
            e.remove();
          }
        });
      }
      this.ratingGroupItemChange.emit({
        item: { ...this.ratingGroupItem.item, text: value },
      });
    }

    setTimeout(() => {
      const div = this.ratingName.nativeElement;
      div.focus();
      div.blur();
    }, 1);
  }

  delete() {
    if (this.editMode) {
      return;
    }
    this.ratingGroupItemDelete.emit();
  }

  toggleShowQty(): void {
    this.form.get('showQtyWidget')!.setValue(!this.form.get('showQtyWidget')!.value);
  }

  toggleShowHours(): void {
    this.form.get('showHoursWidget')!.setValue(!this.form.get('showHoursWidget')!.value);
  }

  toggleTicketRequired(): void {
    this.form.get('ticketRequired')!.setValue(!this.form.get('ticketRequired')!.value);
  }

  toggleShowSpareParts(): void {
    this.form.get('showSpareParts')!.setValue(!this.form.get('showSpareParts')!.value);
  }

  toggleSparePartsSelectionRequired(): void {
    this.form.get('sparePartsSelectionRequired')!.setValue(!this.form.get('sparePartsSelectionRequired')!.value);
  }

  slideLeft() {
    if (this.activeEmojiSlideIndex > 0) {
      this.swiper.slideTo(this.activeEmojiSlideIndex - 1);
    }
  }

  public handleClickRatingGroupItem(name: string, event?: MouseEvent) {
    if (event?.shiftKey) event?.stopPropagation();
    if (name === 'showQtyWidget') {
      this.toggleShowQty();
    }
    if (name === 'showHoursWidget') {
      this.toggleShowHours();
    }
    if (name === 'ticketRequired') {
      this.toggleTicketRequired();
    }
    if (name === 'showSpareParts') {
      this.toggleShowSpareParts();
    }
    if (name === 'sparePartsSelectionRequired') {
      this.toggleSparePartsSelectionRequired();
    }
  }

  slideRight() {
    if (this.activeEmojiSlideIndex < this.emojiSlides.length) {
      this.swiper.slideTo(this.activeEmojiSlideIndex + 1);
    }
  }

  onSwiper($event: any) {
    this.swiper = $event;
  }

  setEmojiSlideIndex(index: number) {
    this.swiper.slideTo(index);
  }

  emojiSlideChanged([swiper]: [swiper: Swiper]) {
    this.activeEmojiSlideIndex = swiper.activeIndex;
    this.cdr.detectChanges();
  }

  public trackByFn(index: number) {
    return index;
  }

  onSaveArea(): void {
    this.subjectsService.templatesFocusOutEditor$.next();
  }
}
