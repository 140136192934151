import { InspectionScheduleType } from '@main-application/inspections/models/rest-inspections-model.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export const InspectionScheduleTypeListConst: IRadioButtonOption<InspectionScheduleType>[] = [
  {
    label: 'Standard',
    sort: 1,
    value: InspectionScheduleType.Standard,
  },
  {
    label: 'Resident',
    sort: 2,
    value: InspectionScheduleType.Resident,
  },
  {
    label: 'Repeating',
    sort: 3,
    value: InspectionScheduleType.Repeating,
  },
  {
    label: 'Dynamic:',
    sort: 4,
    value: InspectionScheduleType.None,
    disabled: true,
  },
  {
    label: '→ NOTICE',
    displayValue: 'Dynamic: NOTICE',
    sort: 5,
    value: InspectionScheduleType.DynamicNotice,
  },
  {
    label: '→ MOVE OUT',
    displayValue: 'Dynamic: MOVE OUT',
    sort: 6,
    value: InspectionScheduleType.DynamicMoveOut,
  },
  {
    label: '→ MAKE READY',
    displayValue: 'Dynamic: MAKE READY',
    sort: 7,
    value: InspectionScheduleType.DynamicMakeReady,
  },
  {
    label: '→ MOVE IN',
    displayValue: 'Dynamic: MOVE IN',
    sort: 8,
    value: InspectionScheduleType.DynamicMoveIn,
  },
];
