import { EIcon } from '@shared/enums/icon.enum';

export const RatingGroupList = [
  {
    name: 'showSpareParts',
    label: 'Show Spare Parts',
    icon: EIcon.EYE,
  },
  {
    name: 'showQtyWidget',
    label: 'Show Qty',
    icon: EIcon.PLUS_ONE,
  },
  {
    name: 'showHoursWidget',
    label: 'Show Hours',
    icon: EIcon.CLOCK,
  },
  {
    name: 'ticketRequired',
    label: 'Ticket Required',
    icon: EIcon.TICKET_SIMPLE,
  },
  {
    name: 'sparePartsSelectionRequired',
    label: 'Spare Parts Required',
    icon: EIcon.SETTINGS2,
  },
];
