import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PropertySelectorState,
  propertySelectorFeatureKey,
} from '@dashboards/store/reducers/property-selector.reducer';

export const selectPropertySelectorState = createFeatureSelector<PropertySelectorState>(propertySelectorFeatureKey);

export const selectPropertySelectorSwitchStatus = createSelector(
  selectPropertySelectorState,
  state => state.propertySelectorSwitchStatus
);

export const selectSelectedPortfolioId = createSelector(selectPropertySelectorState, state => state.portfolioId);

export const selectSelectedPropertyIds = createSelector(selectPropertySelectorState, state => state.propertyIds);
export const selectIsAllPropertiesSelected = createSelector(
  selectPropertySelectorState,
  state => state.allPropertiesSelected
);
export const selectJoinedProperties = createSelector(selectPropertySelectorState, state => state.joinProperties);

export const selectSelectedCollectionIds = createSelector(selectPropertySelectorState, state => state.collectionIds);
