<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle mr-4">
      <ng-container *ngIf="form.value.isNonPms; else withPmsName">Create non-PMS unit and turn</ng-container>
      <ng-template #withPmsName>
        <ng-container *ngIf="systemConfigPmsName$ | async as systemConfigPmsName; else noPmsName">
          New {{ systemConfigPmsName }}
        </ng-container>
        <ng-template #noPmsName>New Turn</ng-template>
      </ng-template>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" *ngIf="form">
      <div class="display-flex flex-column grid-gap-16">
        <app-slide-toggle
          [attrLeftLabel]="systemConfigPmsName$ | async"
          [attrRightLabel]="'non-PMS'"
          formControlName="isNonPms"
          [activeBold]="false"></app-slide-toggle>
        <div class="grid">
          <div class="display-flex">
            <app-radio-button-dropdown
              class="flex-grow-1"
              [containerCss]="'new-turnover-modal-fields w-100'"
              [items]="userProperties"
              [allowSearch]="allowSearchUserProperties"
              [attrDisable]="!userProperties?.length || (selectPropertiesLoading$ | async)"
              [label]="'Property'"
              formControlName="PropertyId"
              [allowClear]="false"
              [attrPlaceholder]="'Select property'"
              [showValidationError]="showValidationError"
              attrAppendTo="body"></app-radio-button-dropdown>
            <mat-spinner *ngIf="selectPropertiesLoading$ | async" color="accent" diameter="20"></mat-spinner>
          </div>
          <div class="display-flex" *ngIf="!form.value.isNonPms">
            <app-radio-button-dropdown
              class="flex-grow-1"
              [containerCss]="'new-turnover-modal-fields w-100'"
              [items]="userUnits"
              [allowSearch]="userUnits?.length > 7"
              [attrDisable]="(!userUnits?.length && !form.value.PropertyId) || (unitListLoading$ | async)"
              [label]="'Unit'"
              formControlName="UnitId"
              [allowClear]="false"
              [attrPlaceholder]="'Select unit'"
              [showValidationError]="showValidationError"
              attrAppendTo="body"></app-radio-button-dropdown>

            <mat-spinner *ngIf="unitListLoading$ | async" color="accent" diameter="20"></mat-spinner>
          </div>
          <div *ngIf="form.value.isNonPms">
            <app-input
              class="non-pms-modal-control"
              [labelCss]="'nowrap pre-label'"
              [containerCss]="'display-flex flex-column'"
              [attrPlaceholder]="'unit name...'"
              formControlName="unitName"
              [attrLabel]="'Unit name'"
              [showValidationError]="showValidationError"></app-input>
          </div>
          <div class="moveout-container">
            <app-date-dropdown
              formControlName="MoveOutDate"
              [buttonCss]="'w-100'"
              [containerCss]="'display-flex flex-column new-turnover-modal-fields w-100'"
              [label]="'Move out'"
              [attrPlaceholder]="'Select date'"
              [showValidationError]="showValidationError"
              [editable]="true"></app-date-dropdown>
          </div>
          <div class="display-flex">
            <app-radio-button-dropdown
              class="flex-grow-1"
              [containerCss]="'new-turnover-modal-fields w-100'"
              [items]="workflowStepTypes"
              [allowSearch]="workflowStepTypes?.length > 7"
              [label]="'Start in'"
              formControlName="startWorkflowStepType"
              [allowClear]="false"
              [showValidationError]="showValidationError"
              attrAppendTo="body"></app-radio-button-dropdown>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button
      mat-stroked-button
      color="warn"
      class="turnover-modal-button"
      [disabled]="newTurnoverLoadingInProgress$ | async"
      (click)="cancel()">
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="turnover-modal-button"
      [disabled]="newTurnoverLoadingInProgress$ | async"
      (click)="newTurnover()">
      <div class="display-flex align-items-center justify-content-center grid-gap-4">
        {{ form.value.isNonPms ? 'Create unit & turn' : 'Create turn' }}
        <mat-spinner *ngIf="newTurnoverLoadingInProgress$ | async" color="accent" diameter="20"></mat-spinner>
      </div>
    </button>
  </mat-dialog-actions>
</div>
