import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentEditableDirective } from '@shared/directives/content-editable.directive';
import { DropZoneDirective } from '@shared/directives/drop-zone.directive';
import { HavePermissionDirective } from '@shared/directives/have-permission.directive';
import { ScrollElementDirective } from '@shared/directives/scroll-element.directive';
import { ScrollDirective } from '@shared/directives/scroll.directive';
import { SortableDirective } from '@shared/directives/sortable.directive';
import { CustomTooltipModule } from '@ui-components/components/custom-tooltip/custom-tooltip.module';

import { CurrencyFormatterDirective } from './currency-formatter.directive';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { LastElementDirective } from './last-element.directive';
import { ScrollToRequiredContainerDirective } from './scroll-to-required-container.directive';
import { ScrollToRequiredDirective } from './scroll-to-required.directive';
import { SortHeaderDirective } from './sort-header.directive';
import { VerticalResizeDividerDirective } from './vertical-resize.directive';

const DIRECTIVES = [
  HavePermissionDirective,
  SortHeaderDirective,
  LastElementDirective,
  CustomTooltipDirective,
  ContentEditableDirective,
  DropZoneDirective,
  CurrencyFormatterDirective,
  ScrollToRequiredContainerDirective,
  ScrollToRequiredDirective,
  ScrollElementDirective,
  SortableDirective,
  VerticalResizeDividerDirective,
];

@NgModule({
  imports: [CommonModule, CustomTooltipModule, ReactiveFormsModule, ScrollDirective],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
