import { InspectionScheduleType } from '@main-application/inspections/models/rest-inspections-model.interface';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';

export const getWorkflowStepFromSchedule = (inspectionType: InspectionScheduleType): WorkflowStepEnumType | null => {
  switch (inspectionType) {
    case InspectionScheduleType.DynamicNotice:
      return WorkflowStepEnumType.Notice;
    case InspectionScheduleType.DynamicMoveIn:
      return WorkflowStepEnumType.MoveIn;
    case InspectionScheduleType.DynamicMoveOut:
      return WorkflowStepEnumType.MoveOut;
    case InspectionScheduleType.DynamicMakeReady:
      return WorkflowStepEnumType.MakeReady;
    default:
      return null;
  }
};
