import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'conditionalDatePipe',
})
export class ConditionalDatePipe implements PipeTransform {
  transform(value: Date | string): string {
    const date = moment(value);
    const currentYear = moment().year();
    const format = date.year() === currentYear ? 'MM/DD' : 'MM/DD/YY';

    return date.format(format);
  }
}
