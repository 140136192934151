<div
  class="chart-container display-flex flex-auto"
  [ngClass]="[
    containerCss
      ? containerCss
      : hasData
      ? displayLegend
        ? 'justify-content-space-between'
        : 'justify-content-end'
      : 'justify-content-center'
  ]">
  <ng-container *ngIf="displayLegend" [ngTemplateOutlet]="legendTemplate"></ng-container>
  <ng-container *ngIf="hasData" [ngTemplateOutlet]="chartTemplate"></ng-container>
  <ng-container *ngIf="!hasData" [ngTemplateOutlet]="emptyChartTemplate"></ng-container>
</div>

<ng-template #chartTemplate>
  <div class="chart-content">
    <canvas [ngClass]="[!hasData ? 'display-none' : '']" #canvas [id]="id"></canvas>
  </div>
</ng-template>

<ng-template #legendTemplate>
  <!--  <div class="display-flex flex-column grid-gap-8 flex-wrap">-->
  <!--    <div-->
  <!--      class="display-flex grid-gap-8 align-items-center legend-items"-->
  <!--      [ngClass]="legendCss"-->
  <!--      (mouseover)="showActiveElement(index)"-->
  <!--      (mouseout)="reset()"-->
  <!--      *ngFor="let item of _chartDataList; let index = index"-->
  <!--    >-->
  <!--      <div class="assigned-color" [style.background-color]="item.backgroundColor"></div>-->
  <!--      <div>{{ item.label }}</div>-->
  <!--      <div *ngIf="!displayPercentageInLegend">({{ item.value }})</div>-->
  <!--      <div *ngIf="displayPercentageInLegend">{{ item.value | getPercentageValue: dataSum }}</div>-->
  <!--    </div>-->
  <!--  </div>-->
</ng-template>

<ng-template #emptyChartTemplate>
  <ng-container [ngTemplateOutlet]="emptyTemplate ? emptyTemplate : predefinedEmptyChartTemplate"></ng-container>
</ng-template>

<ng-template #predefinedEmptyChartTemplate>
  <div class="display-flex grid-gap-20 align-items-center justify-content-start empty-chart-container">
    <div class="empty-chart"></div>
    <div class="body-small-bold text-color gray nowrap">{{ emptyChartLabel }}</div>
  </div>
</ng-template>
