import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appVerticalResizeDivider]',
})
export class VerticalResizeDividerDirective implements AfterViewInit {
  @Input() storageKey = 'divider-sizes';
  @Output() sizeChanged = new EventEmitter<{ topHeight: number; bottomHeight: number }>();

  private startY: number;
  private initialTopHeight: number;
  private topElement: HTMLElement;
  private bottomElement: HTMLElement;
  private isResizing = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.topElement = this.el.nativeElement.previousElementSibling;
      this.bottomElement = this.el.nativeElement.nextElementSibling;

      if (!this.topElement || !this.bottomElement) {
        return;
      }

      const savedSizes = localStorage.getItem(this.storageKey);
      if (savedSizes) {
        const { topHeight, bottomHeight } = JSON.parse(savedSizes);
        const containerHeight = this.el.nativeElement.parentElement.offsetHeight;
        const dividerHeight = this.el.nativeElement.offsetHeight;

        if (topHeight > 72 && bottomHeight > 72 && topHeight + bottomHeight + dividerHeight <= containerHeight) {
          this.setElementSizes(topHeight, bottomHeight);
        } else {
          this.applyDefaultSizes(containerHeight, dividerHeight);
        }
      } else {
        const containerHeight = this.el.nativeElement.parentElement.offsetHeight;
        const dividerHeight = this.el.nativeElement.offsetHeight;
        this.applyDefaultSizes(containerHeight, dividerHeight);
      }
    });
  }

  private applyDefaultSizes(containerHeight: number, dividerHeight: number): void {
    const defaultTopHeight = Math.floor((containerHeight - dividerHeight) / 2);
    const defaultBottomHeight = containerHeight - defaultTopHeight - dividerHeight;
    this.setElementSizes(defaultTopHeight, defaultBottomHeight);
  }

  private setElementSizes(topHeight: number, bottomHeight: number): void {
    this.renderer.setStyle(this.topElement, 'height', `${topHeight}px`);
    this.renderer.setStyle(this.bottomElement, 'height', `${bottomHeight}px`);
    this.sizeChanged.emit({ topHeight, bottomHeight });
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    event.preventDefault();

    if (!this.topElement || !this.bottomElement) {
      return;
    }

    this.isResizing = true;
    this.startY = event.clientY;
    this.initialTopHeight = this.topElement.offsetHeight;

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private onMouseMove = (event: MouseEvent): void => {
    if (!this.isResizing) return;

    const deltaY = event.clientY - this.startY;
    const newTopHeight = this.initialTopHeight + deltaY;

    const containerHeight = this.el.nativeElement.parentElement.offsetHeight;
    const dividerHeight = this.el.nativeElement.offsetHeight;
    const newBottomHeight = containerHeight - newTopHeight - dividerHeight;

    if (newTopHeight > 72 && newBottomHeight > 72) {
      this.setElementSizes(newTopHeight, newBottomHeight);
    }
  };

  private onMouseUp = (): void => {
    this.isResizing = false;

    const topHeight = this.topElement.offsetHeight;
    const bottomHeight = this.bottomElement.offsetHeight;
    localStorage.setItem(this.storageKey, JSON.stringify({ topHeight, bottomHeight }));

    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };
}
