import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { groupBy, last, sortBy, sumBy } from 'lodash';
import * as moment from 'moment';
import { Observable, combineLatest, firstValueFrom, map, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import createTrend from 'trendline';

import { KpiService } from '@app/services/kpi.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import { setVacancyLossThreshold } from '@dashboards/store/actions/dashboard.actions';
import {
  clearPortfolioKpiTrendLines,
  loadPortfolioKpiTrendLines,
  setChartSettingsValue,
} from '@dashboards/store/actions/kpi.actions';
import {
  selectSelectedVacancyLossThreshold,
  selectSelectedVacancyLossThresholdKpiType,
  selectSelectedVacancyLossThresholdKpiTypePerTurn,
} from '@dashboards/store/selectors/dashboards.selectors';
import { selectChartSettingsValue, selectPortfolioKpiTrendLines } from '@dashboards/store/selectors/kpi.selectors';
import {
  selectSelectedPortfolioId,
  selectSelectedPropertyIds,
} from '@dashboards/store/selectors/property-selector.selectors';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { selectUserData } from '@main-application/store/selectors/user.selectors';
import { TurnoverKanbanService } from '@main-application/turnovers/components/turnover-kanban/sevices/turnover-kanban.service';
import { VacancyLossAttrType } from '@main-application/turnovers/interfaces/vacancy-loss-attr';
import {
  VacancyLostThreshold,
  VacancyLostThresholdList,
} from '@main-application/turnovers/interfaces/vacancy-loss-threshold';
import { setActiveSections } from '@main-application/turnovers/store/actions/turnovers.actions';
import { selectProperties } from '@portfolio/store/portfolio.selectors';
import { VacancyLossTypeGross, VacancyLossTypeListConst } from '@shared/constants/vacancy-loss-type-list.const';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { KpiWindowType, getTrendDaysDiff } from '@shared/enums/kpi-window-type';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';
import { ChartDataItem } from '@shared/interfaces/chart-data-item';
import { UserData } from '@shared/interfaces/user-data';
import { CustomCurrencyShortWithSuffixPipe } from '@shared/pipes/custom-currency-short-with-suffix.pipe';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import {
  LineChartDatasetOptions,
  LineChartDateset,
  LineChartOptions,
} from '@ui-components/components/line-chart/line-chart.component';

class VacancyLossData {
  attrType: VacancyLossAttrType;
  dataSet: LineChartDateset;
  current: number;
}

@UntilDestroy()
@Component({
  selector: 'app-vacancy-loss-chart-card',
  templateUrl: './vacancy-loss-chart-card.component.html',
  styleUrls: ['./vacancy-loss-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TurnoverKanbanService],
})
export class VacancyLossChartCardComponent extends PuSubscribable implements OnInit {
  @Input() chartId: string;

  @Input() set trendDays(value: number | undefined) {
    this.trendDaysValue = value;
    this.loadKpis();
    this.cdr.detectChanges();
  }

  vacancyLossThreshold$ = this.store.select(selectSelectedVacancyLossThreshold).pipe(distinctUntilChanged());
  readonly vacancyLossThresholdList = VacancyLostThresholdList;
  tooltipValue: string | undefined;
  showTooltipValue = false;

  kpis: RestPortfolioKpi[];
  chartOptions: LineChartOptions = {
    interaction: {
      mode: 'x',
      intersect: false,
    },
    scales: {
      yAxes: {
        grid: {
          drawBorder: false,
          display: false,
        },
        title: {
          color: EColorPalette.cGray5,
        },
        ticks: {
          color: EColorPalette.cGray7,
          font: {
            size: 10,
            weight: '400',
          },
          callback: (value, index, ticks) => {
            if (index === 0 || index === ticks.length - 1 || index === Math.round((ticks.length - 1) / 2)) {
              return this.customCurrencyShortWithSuffix.transform(parseFloat(value.toString()), 1);
            }
            return '';
          },
        },
      },
      xAxes: {
        grid: {
          drawBorder: false,
          borderDash: [4, 8],
          borderWidth: 1,
          borderColor: EColorPalette.cGray8,
          tickWidth: 0,
        },
        title: {
          color: EColorPalette.cGray5,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          color: EColorPalette.cGray7,
          font: {
            size: 10,
            weight: '400',
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'x',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: '#767575',
        titleFont: {
          size: 12,
        },
        bodyColor: '#212121',
        bodyFont: {
          size: 14,
          weight: '700',
        },
        caretSize: 0,
        borderColor: '#dadada',
        borderWidth: 1,
        caretPadding: 10,
        cornerRadius: 4,
        displayColors: false,
        callbacks: {
          label: (tooltipItem: any): string | string[] => {
            this.tooltipValue = `$${tooltipItem.formattedValue}`;
            this.cdr.detectChanges();
            return this.tooltipValue;
          },
        },
      },
    },
  };
  chartData: VacancyLossData;
  isLoading = true;
  trendDaysValue?: number;
  portfolioId: number;
  trendDirection: number;
  thresholdTypeKpiType: PortfolioKpiType;
  thresholdTypeKpiTypePerTurn: PortfolioKpiType;
  allPropertyIds$: Observable<number[]> = this.getAllPropertyIds();

  protected readonly EIcon = EIcon;
  protected readonly WorkflowPhaseType = WorkflowPhaseType;

  vacancyAttrTypeControl = new UntypedFormControl(VacancyLossTypeGross);
  readonly vacancyAttrTypeList = VacancyLossTypeListConst.map(k => k.label);

  constructor(
    private store: Store<{}>,
    private cdr: ChangeDetectorRef,
    private kpiService: KpiService,
    private timezoneService: TimezoneService,
    private customCurrencyShortWithSuffix: CustomCurrencyShortWithSuffixPipe,
    private turnoverKanbanService: TurnoverKanbanService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dispatchActiveSections();

    this.store.dispatch(clearPortfolioKpiTrendLines());

    combineLatest([this.store.select(selectSelectedPortfolioId), this.store.select(selectSelectedVacancyLossThreshold)])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(([portfolioId]) => !!portfolioId),
        tap(async ([portfolioId]) => {
          this.portfolioId = portfolioId;
          this.thresholdTypeKpiType = await firstValueFrom(
            this.store.select(selectSelectedVacancyLossThresholdKpiType)
          );
          this.thresholdTypeKpiTypePerTurn = await firstValueFrom(
            this.store.select(selectSelectedVacancyLossThresholdKpiTypePerTurn)
          );

          this.loadKpis();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    combineLatest([
      this.store.select(selectPortfolioKpiTrendLines),
      this.store.select(selectChartSettingsValue<{ vacancyType: VacancyLossAttrType }>(this.chartId)),
      this.store.select(selectSelectedPropertyIds),
      this.allPropertyIds$,
    ])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(([kpis]) => !!kpis?.length),
        tap(([kpis, settings, selectedPropertyIds]) => {
          this.kpis = kpis.filter(x => selectedPropertyIds && selectedPropertyIds.includes(x.propertyId));
          const vacancyAttrType = settings?.vacancyType || VacancyLossAttrType.Gross;
          this.fillVacancyLoss(vacancyAttrType);
          this.isLoading = false;
          this.vacancyAttrTypeControl.setValue(VacancyLossTypeListConst.find(x => x.value === vacancyAttrType).label);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.vacancyAttrTypeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        // prevent infinite loop between this observer and above
        distinctUntilChanged(),
        tap((vacancyType: string) => {
          const vacancyAttrType = VacancyLossTypeListConst.find(x => x.label === vacancyType).value;
          this.store.dispatch(
            setChartSettingsValue({ chartId: this.chartId, value: { vacancyType: vacancyAttrType } })
          );
          this.fillVacancyLoss(vacancyAttrType);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  updateThreshold(value: VacancyLostThreshold) {
    this.store.dispatch(setVacancyLossThreshold({ thresholdType: value }));
  }

  loadKpis() {
    if (!this.portfolioId) return;

    this.store.dispatch(
      loadPortfolioKpiTrendLines({
        portfolioId: this.portfolioId,
        trendDays: getTrendDaysDiff(this.trendDaysValue, this.timezoneService.getCurrentDate()),
        kpiTypes: [this.thresholdTypeKpiType, this.thresholdTypeKpiTypePerTurn],
      })
    );
  }

  fillVacancyLoss(vacancyType: VacancyLossAttrType) {
    switch (vacancyType) {
      case VacancyLossAttrType.Gross:
        this.chartData = this.getGrossVacancyLoss();
        break;
      case VacancyLossAttrType.Unit:
        this.chartData = this.getUnitVacancyLoss();
        break;
      case VacancyLossAttrType.Turn:
        this.chartData = this.getTurnVacancyLoss();
        break;
    }
    this.cdr.markForCheck();
  }

  getGrossVacancyLoss(): VacancyLossData {
    let grossData = this.prepareVacancyLossGross();

    this.trendDaysValue === KpiWindowType.Months12
      ? (grossData = this.getGroupedByMonth(grossData))
      : (grossData = grossData.map(el => ({ ...el, label: moment(el.label, 'MMM DD YYYY').format('MMM D') })));

    return {
      dataSet: {
        labels: grossData.map(d => d.label),
        datasets: [this.getDataset(grossData)],
      },
      attrType: VacancyLossAttrType.Gross,
      current: Math.round(grossData.length ? last(grossData).value : 0),
    };
  }

  getUnitVacancyLoss(): VacancyLossData {
    let unitData = this.prepareVacancyLoss(this.thresholdTypeKpiType);

    this.trendDaysValue === KpiWindowType.Months12
      ? (unitData = this.getGroupedByMonth(unitData))
      : (unitData = unitData.map(el => ({ ...el, label: moment(el.label, 'MMM DD YYYY').format('MMM D') })));

    return {
      dataSet: {
        labels: unitData.map(d => d.label),
        datasets: [this.getDataset(unitData)],
      },
      attrType: VacancyLossAttrType.Unit,
      current: Math.round(unitData.length ? last(unitData).value : 0),
    };
  }

  getTurnVacancyLoss() {
    let turnData = this.prepareVacancyLoss(this.thresholdTypeKpiTypePerTurn);
    this.trendDaysValue === KpiWindowType.Months12
      ? (turnData = this.getGroupedByMonth(turnData))
      : (turnData = turnData.map(el => ({ ...el, label: moment(el.label, 'MMM DD YYYY').format('MMM D') })));

    return {
      dataSet: {
        labels: turnData.map(d => d.label),
        datasets: [this.getDataset(turnData)],
      },
      attrType: VacancyLossAttrType.Turn,
      current: Math.round(turnData.length ? last(turnData).value : 0),
    };
  }

  prepareVacancyLossGross(): ChartDataItem[] {
    const data = groupBy(
      this.kpis.filter(x => x.kpiType === this.thresholdTypeKpiType),
      k => moment(k.calculationDate).format('MMM DD YYYY')
    );
    return sortBy(
      Object.keys(data).map(key => {
        const sumValue = sumBy(data[key], k => k.value * k.unitsCount);
        return {
          value: parseFloat(sumValue.toFixed(0)),
          backgroundColor: EColorPalette.cGray5,
          label: key,
          additionalValue: moment(key, 'MMM DD YYYY').toDate().getTime(),
        };
      }),
      v => v.additionalValue
    );
  }

  prepareVacancyLoss(kpiType: PortfolioKpiType): ChartDataItem[] {
    const data = groupBy(
      this.kpis.filter(x => x.kpiType === kpiType),
      k => moment(k.calculationDate).format('MMM DD YYYY')
    );
    return sortBy(
      Object.keys(data).map(key => {
        return {
          value: this.kpiService.avg(data[key], kpiType, 0),
          backgroundColor: EColorPalette.cGray5,
          label: key,
          additionalValue: moment(key, 'MMM DD YYYY').toDate().getTime(),
        };
      }),
      v => v.additionalValue
    );
  }

  getDataset(chartData: ChartDataItem[]): LineChartDatasetOptions {
    this.calculateTrendDirection(chartData);
    const canvas = document.createElement('canvas');
    document.body.append(canvas);
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    if (this.trendDirection < 0) {
      gradient.addColorStop(0, 'rgba(78, 176, 141, 0.4)');
      gradient.addColorStop(1, 'rgba(111, 237, 192, 0)');
    } else {
      gradient.addColorStop(0, 'rgba(233, 58, 79, 0.4)');
      gradient.addColorStop(1, 'rgba(231,109,109,0)');
    }

    canvas.remove();
    return {
      data: chartData.map(d => d.value),
      cubicInterpolationMode: 'monotone',
      borderColor: this.trendDirection < 0 ? EColorPalette.cGreen3 : EColorPalette.cRed2,
      backgroundColor: gradient,
      pointRadius: 0,
      pointHitRadius: 25,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderWidth: 4,
      fill: true,
    };
  }

  insideChart(inside: boolean) {
    if (!inside) {
      this.tooltipValue = undefined;
    }
    this.showTooltipValue = inside;
    this.cdr.detectChanges();
  }

  calculateTrendDirection(chartData: ChartDataItem[]) {
    const trend = createTrend(chartData, 'additionalValue', 'value');
    const timestamps = chartData.map(data => data.additionalValue);
    const xMax = Math.max(...timestamps);
    const xMin = Math.min(...timestamps);
    this.trendDirection = trend.calcY(xMax) - trend.calcY(xMin);
  }

  private dispatchActiveSections(): void {
    this.store
      .select(selectUserData)
      .pipe(
        untilDestroyed(this),
        filterNullish$(),
        tap((userData: UserData) => {
          this.store.dispatch(
            setActiveSections({ activePropertyIds: this.turnoverKanbanService.getExpandedSections(userData.id) })
          );
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private getAllPropertyIds(): Observable<number[]> {
    return this.store.select(selectSelectedPortfolioId).pipe(
      switchMap((portfolioId: number) =>
        this.store
          .select(selectProperties)
          .pipe(map(data => data.filter(el => el.portfolioId === portfolioId).map(el => el.id)))
      ),
      untilDestroyed(this)
    );
  }

  getGroupedByMonth(grossData: ChartDataItem[]): ChartDataItem[] {
    const groupedByMonth: { [key: string]: ChartDataItem } = {};
    grossData.forEach(el => {
      const month = moment(el.label).format('MMM YY');

      if (groupedByMonth[month]) {
        groupedByMonth[month].value += el.value;
      } else {
        groupedByMonth[month] = { ...el, label: month };
      }
    });
    return Object.keys(groupedByMonth).map(month => {
      return { label: month, ...groupedByMonth[month] };
    });
  }

  protected readonly EColorPalette = EColorPalette;
}
