import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RestapiService } from '@app/services/restapi.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import { Upload } from '@shared/interfaces';
import { SparePartGroup, SparePartItem } from '@shared/interfaces/spare-parts.interface';
import { AuthService } from '@shared/services/auth.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SparePartsService extends PuSubscribable {
  constructor(private restApiService: RestapiService, private authService: AuthService) {
    super();
  }

  getItemList(): Observable<SparePartItem[]> {
    return this.restApiService.getData<SparePartItem[]>(`SparePart`);
  }

  deleteItem(id: number): Observable<void> {
    return this.restApiService.delete(`SparePart/${id}`);
  }

  updateItem(item: SparePartItem) {
    return this.restApiService.update(`SparePart/${item.id}`, item);
  }

  createItem(
    item: Pick<SparePartItem, 'title' | 'cost' | 'lowBound' | 'upperBound' | 'description' | 'sparePartGroupParentId'>
  ) {
    return this.restApiService.create(`SparePart`, item);
  }

  getGroupList(): Observable<SparePartGroup[]> {
    return this.restApiService.getData<SparePartGroup[]>(`SparePartGroup`);
  }

  deleteGroup(id: number): Observable<void> {
    return this.restApiService.delete(`SparePartGroup/${id}`);
  }

  updateGroup(item: SparePartGroup) {
    return this.restApiService.update(`SparePartGroup/${item.id}`, item);
  }

  createGroup(item: Pick<SparePartGroup, 'title' | 'parentGroupId' | 'propertyOrPortfolioId'>) {
    return this.restApiService.create(`SparePartGroup`, item);
  }

  downloadPriceList(portfolioOrPropertyId: number) {
    return this.authService
      .getAccessToken()
      .pipe(
        tap(token => {
          const a = document.createElement('a');
          a.href = `${environment.apiUrl}/SparePartGroup/downloadPriceList/${portfolioOrPropertyId}?access_token=${token}`;
          a.target = '_parent';
          a.download = 'true';
          document.body.append(a);
          a.click();
          a.remove();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  uploadPriceList(file: File, portfolioOrPropertyId: number): Observable<Upload> {
    return this.restApiService.upload<Upload>(`SparePartGroup/uploadPriceList/${portfolioOrPropertyId}`, file, {});
  }
}
