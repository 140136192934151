import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserService } from '@app/services/user.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';
import { selectUpdateStepAssigneeInProgress } from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { WorkflowStepListConst } from '@shared/constants/workflow-step-list.const';
import { ManagementRoute } from '@shared/enums/management-route.enum';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestTurnoverDataModel } from '@shared/interfaces/turnover.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { WorkflowAction } from '@shared/interfaces/workflow-action.interface';

export class NextStepAssigneeModalData {
  nextWorkflowStep: WorkflowStepEnumType;
  turnoverData: RestTurnoverDataModel;
  propertyId: number;
  propertyName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-next-step-assignee-modal',
  templateUrl: './next-step-assignee-modal.component.html',
  styleUrls: ['./next-step-assignee-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NextStepAssigneeModalComponent extends PuSubscribable implements OnInit {
  form = this.formBuilder.group({
    assigneeId: [null as number, [Validators.required]],
    rememberIt: [false as boolean],
  });

  userListModel: RestUserModel[] = [];
  propertyUserListLoading$ = new BehaviorSubject(true);
  updateInProgress$: Observable<boolean>;
  currentWorkflowStep: WorkflowStepEnumType;
  ManagementRoute = ManagementRoute;
  workflowStepList: IRadioButtonOption<WorkflowStepEnumType>[] = WorkflowStepListConst;

  get propertyId() {
    return this.data.propertyId ?? this.data.turnoverData.property.id;
  }

  get propertyName() {
    return this.data.propertyName ?? this.data.turnoverData.property.name;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected store: Store<{}>,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<NextStepAssigneeModalComponent, WorkflowAction>,
    @Inject(MAT_DIALOG_DATA) public data: NextStepAssigneeModalData
  ) {
    super();
    this.currentWorkflowStep = this.data.turnoverData.activeWorkflowStepType;
  }

  ngOnInit(): void {
    this.updateInProgress$ = this.store.select(selectUpdateStepAssigneeInProgress);

    this.propertyUserListLoading$.next(true);
    this.userService
      .getCachedPropertyUserList(this.propertyId, true)
      .pipe(untilDestroyed(this))
      .subscribe(userListModel => {
        this.propertyUserListLoading$.next(false);
        this.userListModel = userListModel;
        this.cdr.detectChanges();
      })
      .untilDestroyed(this);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const { assigneeId, rememberIt } = this.form.value;
    const workflowAction: WorkflowAction = {
      turnoverId: this.data.turnoverData.id,
      nextWorkflowStep: this.data.nextWorkflowStep,
      assigneeId,
      rememberIt,
    };

    this.dialogRef.close(workflowAction);
  }
}
