import { AbstractControl, ValidatorFn } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { EMAIL_REGEXP } from '@shared/constants/email.regexp.const';

export const EmailPatternValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } => {
    return EMAIL_REGEXP.test(control?.value) ? null : { email: true };
  };
};

export const correctEmailInput = (control: AbstractControl) => {
  return control.valueChanges.pipe(
    tap(email => {
      const trimmed = email.trim();
      if (trimmed !== email) {
        control.setValue(trimmed);
      }
    })
  );
};
