import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { AttachmentComponentType } from '@shared/enums/attachment-component-type.enum';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { Upload } from '@shared/interfaces/upload';

enum AttachmentComponentForm {
  AttachmentType = 'attachmentType',
  File = 'file',
  Link = 'link',
}

@UntilDestroy()
@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentComponent extends ComponentAbstract implements OnInit {
  form: UntypedFormGroup;
  _attachmentItem: AttachmentItem;
  currentLink = null;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  fileUploadLoading = false;

  @Input() label = '';
  @Input() lockFields = false;
  @Input() linkContentCss = 'display-flex flex-column align-items-start flex-1';
  @Input() inputFileCss = 'flex-1';
  @Input() attrLabel = 'Attachment';
  @Input() containerCss = 'grid-gap-8';
  @Input() labelRequired = false;
  @Input() isEmpty = false;
  @Input() uploadInProgress = false;
  @Input() allowLink = true;
  @Input() keepSelection = false;
  @Input() acceptedFormats: string[];
  @Input() isResident = false;
  @Input() cleanInputAttachment: EventEmitter<void>;
  @Output() attachmentItemEvent: EventEmitter<AttachmentItem> = new EventEmitter<AttachmentItem>();

  constructor(protected cdr: ChangeDetectorRef, private formBuilder: UntypedFormBuilder) {
    super(cdr);
  }

  get attachmentType(): UntypedFormControl {
    return this.form.get(AttachmentComponentForm.AttachmentType) as UntypedFormControl;
  }

  get file(): UntypedFormControl {
    return this.form.get(AttachmentComponentForm.File) as UntypedFormControl;
  }

  get link(): UntypedFormControl {
    return this.form.get(AttachmentComponentForm.Link) as UntypedFormControl;
  }

  setIsTouched() {
    this.showValidationError = true;
  }

  ngOnInit(): void {
    this.cleanInputAttachment
      ?.pipe(
        untilDestroyed(this),
        tap(() => {
          this.file.reset();
          this.link.reset();
          this._attachmentItem = {};
          this.currentLink = '';
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.initForm();

    this.file.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((uploads: Upload[]) => !!uploads?.length),
        tap((uploads: Upload[]) => {
          this.attachmentItemEvent.emit({ upload: uploads[0] });
          if (!this.keepSelection) {
            this.file.reset();
          }
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.link.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        filter((link: string) => !!link && this.link.valid && link !== this.currentLink),
        tap((link: string) => {
          this.currentLink = link;
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      [AttachmentComponentForm.AttachmentType]: [AttachmentComponentType.File],
      [AttachmentComponentForm.Link]: [null, [Validators.pattern(this.urlRegex)]],
      [AttachmentComponentForm.File]: [null, [Validators.required]],
    });
  }

  submitLink() {
    if (this.link.valid) {
      this.attachmentItemEvent.emit({ link: this.link.value });
      if (!this.keepSelection) {
        this.link.reset();
      }
    }
  }

  clearUploadedFile() {
    this.attachmentItemEvent.emit(null);
  }
}
