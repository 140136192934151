<div class="vacancy-loss-chart-card-component" (mouseenter)="insideChart(true)" (mouseleave)="insideChart(false)">
  <app-chart-card
    [chartIcon]="EIcon.VACANCY_LOSS"
    [title]="'Vacancy Loss'"
    [subTitle]="
      showTooltipValue && tooltipValue ? tooltipValue : (chartData?.current | currency: 'USD':'symbol':'1.0-0')
    "
    [subTitleColor]="trendDirection < 0 ? EColorPalette.cGreen3 : EColorPalette.cRed2"
    [isLoading]="isLoading"
    class="w-100"
    [customDescriptionTemplate]="switcher">
    <div chart class="display-flex flex-1 w-100">
      <app-line-chart
        *ngIf="chartData"
        [dataset]="chartData.dataSet"
        [options]="chartOptions"
        [displayLegend]="false"
        class="display-flex flex-auto h-100"></app-line-chart>
    </div>
  </app-chart-card>
</div>

<ng-template #switcher>
  <div class="switcher">
    <app-pill-list [availableOptions]="vacancyAttrTypeList" [formControl]="vacancyAttrTypeControl"></app-pill-list>
    <app-radio-button-dropdown
      [labelCss]="'pre-text text-size s12 text-color gray'"
      [valueCss]="'pre-title fs s10'"
      [ngModel]="vacancyLossThreshold$ | async"
      (ngModelChange)="updateThreshold($event)"
      [containerWidthCss]="'w-auto threshold-dropdown'"
      [containerCss]="'control-horizontal display-flex mt-8 flex-row grid-gap-4'"
      [autoSortList]="false"
      [items]="vacancyLossThresholdList"
      [allowClear]="false"
      [isSmallFont]="true"
      [label]="'Threshold'"
      [activeOnFocus]="true"></app-radio-button-dropdown>
  </div>
</ng-template>
