import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * You should inject it directly in place where you use it
 * Remember to add it to LOCAL providers
 *
 * !! Use it when you need to unsubscribe manually
 * !! Do not use it instead of AutoUnsubscribe
 *
 * */
@Injectable()
export class DestroyService extends Subject<void> implements OnDestroy {
  // eslint-disable-next-line custom-rules/no-super-call-for-ng-on-destroy
  ngOnDestroy(): void {
    this.next();
    this.complete();
  }
}
