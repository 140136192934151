import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { ConfirmationModalData } from '@shared/interfaces/modal-data';
import { ModalsService } from '@ui-components/modals/modals.service';

import { DialogResult } from '../config/dialog-result.enum';

const CANCEL_DEFAULT_LABEL = 'Cancel';
const CANCEL_DEFAULT_COLOR = '';
const CONFIRM_DEFAULT_LABEL = 'OK';
const CONFIRM_DEFAULT_COLOR = 'primary';

@UntilDestroy()
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationModalComponent extends PuSubscribable implements OnInit {
  cancelLabel: string;
  cancelColor: string;
  confirmLabel: string;
  confirmColor: 'primary' | 'warn';
  doNotShowAgain = new UntypedFormControl(false);
  doNotShowAgainMessage: string;
  hideConfirmBtn: boolean;

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private modalsService: ModalsService,
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData
  ) {
    super();
  }

  ngOnInit(): void {
    const overlay = this.elementRef.nativeElement.closest('.cdk-overlay-container');
    if (overlay) {
      overlay.style.zIndex = '1001';
    }
    this.cancelLabel = this.data.cancelLabel || CANCEL_DEFAULT_LABEL;
    this.cancelColor = this.data.cancelColor || CANCEL_DEFAULT_COLOR;
    this.confirmLabel = this.data.confirmLabel || CONFIRM_DEFAULT_LABEL;
    this.confirmColor = this.data.confirmColor || CONFIRM_DEFAULT_COLOR;
    this.hideConfirmBtn = this.data.hideConfirmBtn || false;
    this.doNotShowAgainMessage = this.data.doNotShowAgainMessage || "Don't show again";
    this.doNotShowAgain.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.modalsService.updateDialogHiddenState(value, this.data);
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  cancel() {
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close(DialogResult.Success);
  }
}
