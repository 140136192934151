import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs';

import {
  addCompany,
  addCompanySuccess,
  addCompanyWithOwner,
  addCompanyWithOwnerFailureUserExist,
  addCompanyWithOwnerSuccess,
} from '@companies/store/companies.actions';
import { selectAddCompanyWithOwnerLoading } from '@companies/store/companies.selectors';
import { AdministrationAbstract } from '@main-application/administration/abstract/administration-abstract';
import { VENDOR_ROLE_NAME } from '@main-application/administration/constants/vendor-role-name.constant';
import { formatPropertyStructureToTree } from '@main-application/administration/functions/propertyStructureToTree';
import {
  inviteNewUser,
  loadAllGeneralRoles,
} from '@main-application/administration/store/actions/administration.actions';
import {
  selectActiveUsers,
  selectAllGeneralRoles,
  selectAllPropertiesStructure,
} from '@main-application/administration/store/selectors/administration.selectors';
import { selectNativeLanguagesEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { UserType } from '@shared/enums/user-type';
import { getEnumerationRadioListFunction } from '@shared/functions/get-enumeration-radio-list.function';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { InviteRestUserModel, RestUserModel } from '@shared/interfaces/user.interface';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { UserExistsValidator } from '@shared/validators/user-exists.validator';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { correctEmailInput } from '@ui-components/validators/email.validator';

enum NewVendorFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Company = 'company',
  PhoneNumber = 'phoneNumber',
  NativeLanguage = 'nativeLanguage',
  RoleId = 'roleId',
}

interface NewVendor {
  [NewVendorFields.FirstName]: string;
  [NewVendorFields.LastName]: string;
  [NewVendorFields.Email]: string;
  [NewVendorFields.Company]: string;
  [NewVendorFields.PhoneNumber]: string;
  [NewVendorFields.NativeLanguage]: number;
  [NewVendorFields.RoleId]: number;
}

export interface AddVendorModalData {
  addOnlyVendor?: boolean;
  onlyAddBtn?: boolean;
  withPortfolioPropertiesSelector?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-add-vendor-modal',
  templateUrl: './add-vendor-modal.component.html',
  styleUrls: ['./add-vendor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddVendorModalComponent extends AdministrationAbstract implements OnInit {
  form: FormGroup = this.initFormGroup();
  vendorRoleId: number;
  inProgress$ = this.store.select(selectAddCompanyWithOwnerLoading);
  nativeLanguages: IRadioButtonOption<number>[] = [];
  formSubmitted = false;
  warningMessage = '';
  arePropertiesChanged = false;
  selectedProperties: PropertyBasicInfo[] = [];
  allProperties$ = this.store.select(selectAllPropertiesStructure).pipe(
    filterNullish$(),
    map(allProperties => formatPropertyStructureToTree(allProperties))
  );

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddVendorModalComponent, RestCompanyModel>,
    store: Store<{}>,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: AddVendorModalData,
    cdr: ChangeDetectorRef
  ) {
    super(cdr, store);
  }

  get firstName(): FormControl<string> {
    return this.form.get(NewVendorFields.FirstName) as FormControl;
  }

  get lastName(): FormControl {
    return this.form.get(NewVendorFields.LastName) as FormControl;
  }

  get email(): FormControl {
    return this.form.get(NewVendorFields.Email) as FormControl;
  }

  get company(): FormControl {
    return this.form.get(NewVendorFields.Company) as FormControl;
  }

  get phoneNumber(): FormControl {
    return this.form.get(NewVendorFields.PhoneNumber) as FormControl;
  }

  get nativeLanguage(): FormControl {
    return this.form.get(NewVendorFields.NativeLanguage) as FormControl<number>;
  }

  ngOnInit(): void {
    this.store.dispatch(loadAllGeneralRoles());
    this.initForm();
    this.initNativeLanguages();
    this.initActiveUsers();
    this.initVendorRoleId();
    this.initNotifications();
    this.cdr.detectChanges();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  send(): void {
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    const value: NewVendor = this.form.value;
    const inviteUser: InviteRestUserModel = createInviteUser(value);
    const companyModel: RestCompanyModel = {
      displayName: value.company,
      id: 0,
      employees: [],
      addresses: [],
    };

    if (this.data.addOnlyVendor) {
      this.addCompany(companyModel);
      return;
    }

    this.addCompanyWithOwner(inviteUser, companyModel);
  }

  private initForm(): void {
    if (!this.data.addOnlyVendor) {
      this.form.addControl(NewVendorFields.FirstName, new FormControl('', Validators.required));
      this.form.addControl(NewVendorFields.LastName, new FormControl('', Validators.required));
      this.form.addControl(NewVendorFields.Email, new FormControl('', [Validators.required, Validators.email]));
      this.form.addControl(NewVendorFields.PhoneNumber, new FormControl('', Validators.pattern('[- +()0-9]+')));
      this.form.addControl(NewVendorFields.NativeLanguage, new FormControl(0, Validators.required));
    }
    correctEmailInput(this.email);
  }

  private initNativeLanguages(): void {
    this.store
      .select(selectNativeLanguagesEnumeration)
      .pipe(
        untilDestroyed(this),
        tap(values => {
          this.nativeLanguages = getEnumerationRadioListFunction(values, null, false);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private initActiveUsers(): void {
    this.store
      .select(selectActiveUsers)
      .pipe(
        untilDestroyed(this),
        filter((allUsers: RestUserModel[]) => !!allUsers)
      )
      .subscribe((allUsers: RestUserModel[]) => {
        const existingEmails = allUsers.map(item => item.email);
        if (this.email) {
          this.email.addValidators([UserExistsValidator(existingEmails)]);
        }
      })
      .untilDestroyed(this);
  }

  private initVendorRoleId(): void {
    this.store
      .select(selectAllGeneralRoles)
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(allRoles => (this.vendorRoleId = allRoles.find(e => e.roleName == VENDOR_ROLE_NAME)?.id))
      .untilDestroyed(this);
  }

  private addCompany(companyModel: RestCompanyModel): void {
    this.store.dispatch(addCompany({ companyModel }));
    this.actions$
      .pipe(
        ofType(addCompanySuccess),
        take(1),
        tap(action => {
          this.snackbarService.success(`${action.companyModel.displayName} added`);
          this.dialogRef.close(action.companyModel);
        }),
        untilDestroyed(this)
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private addCompanyWithOwner(inviteUser: InviteRestUserModel, companyModel: RestCompanyModel): void {
    this.store.dispatch(
      addCompanyWithOwner({
        user: inviteUser,
        roleId: this.vendorRoleId,
        companyModel,
      })
    );

    this.actions$
      .pipe(
        ofType(addCompanyWithOwnerSuccess),
        take(1),
        tap(action => {
          this.snackbarService.success(`${action.company.displayName} added`);
          this.dialogRef.close(action.company);
        }),
        untilDestroyed(this)
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private onInviteUser(inviteUser: InviteRestUserModel): void {
    const selectedProperty =
      this.arePropertiesChanged && this.selectedProperties ? this.selectedProperties.map(item => item.id) : [];

    this.store.dispatch(inviteNewUser({ user: inviteUser, roleId: this.vendorRoleId, selectedProperty }));
  }

  private initNotifications(): void {
    this.initCompanySuccess();
    this.initUserExistError();
  }

  private initFormGroup(): FormGroup {
    return this.fb.group({
      [NewVendorFields.Company]: ['', Validators.required],
    });
  }

  private initUserExistError(): void {
    this.actions$
      .pipe(
        ofType(addCompanyWithOwnerFailureUserExist),
        tap(error => this.snackbarService.error(`User already exists with email ${error.existedUserEmail}`)),
        untilDestroyed(this)
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private initCompanySuccess(): void {
    this.actions$
      .pipe(
        ofType(addCompanyWithOwnerSuccess),
        tap(action => {
          this.snackbarService.success(`${action.company.displayName} added`);
          this.dialogRef.close(action.company);
        }),
        untilDestroyed(this)
      )
      .subscribe()
      .untilDestroyed(this);
  }

  selectedRootsChange(selectedRoots: PropertyBasicInfo[]): void {
    this.arePropertiesChanged = true;
    this.selectedProperties = selectedRoots;
  }
}

function createInviteUser(formData: NewVendor): InviteRestUserModel {
  return {
    email: formData[NewVendorFields.Email],
    firstName: formData[NewVendorFields.FirstName],
    lastName: formData[NewVendorFields.LastName],
    userGroupType: UserType.Contractor,
    displayName: `${formData.firstName} ${formData.lastName}`,
    nativeLanguage: formData[NewVendorFields.NativeLanguage],
    phoneNumber: formData[NewVendorFields.PhoneNumber],
  };
}
