import * as moment from 'moment/moment';

import { InspectionSummaryFilters } from '@main-application/inspections/models/inspection-summary.model';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { RestPortfolioModel, RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import { DynamicInspectionModel } from '../models/dynamic-inspection.model';
import { InspectionListItemModel } from '../models/inspection-list.model';
import { RestDynamicInspectionModel, RestInspectionsModel } from '../models/rest-inspections-model.interface';

export function mapInspectionsDataSource(
  inspectionList: RestInspectionsModel[],
  templateList: RestTemplateModel[],
  properties: RestPropertyModel[],
  selectedPropertyIds: number[],
  users: RestUserModel[]
): InspectionListItemModel[] {
  const usersMap = new Map(users.map(user => [user.id, user]));

  return inspectionList
    .filter((inspection: RestInspectionsModel) => {
      if (!inspection.unitId && inspection.propertyId) {
        return !selectedPropertyIds || selectedPropertyIds.includes(inspection.propertyId);
      }
      return inspection.unitId && (!selectedPropertyIds || selectedPropertyIds.includes(inspection.unit.property.id));
    })
    .map(
      inspection =>
        new InspectionListItemModel(
          inspection,
          templateList.find(template => template.id == inspection.inspectionTemplateId),
          properties,
          usersMap
        )
    );
}

export function mapDynamicInspectionsDataSource(
  templates: RestDynamicInspectionModel[],
  templateList: RestTemplateModel[],
  properties: RestPropertyModel[],
  workflowSteps: EnumerationItem[],
  users: RestUserModel[],
  portfolios?: RestPortfolioModel[]
): any {
  return templates.map(
    inspection =>
      new DynamicInspectionModel(
        inspection,
        templateList.find(template => template.id == inspection.inspectionTemplateId),
        properties,
        workflowSteps,
        users,
        portfolios
      )
  );
}

export default function getInspectionsSummaryFiltersKey(filter: InspectionSummaryFilters): string {
  return JSON.stringify(
    {
      ...filter,
      endDate: moment(filter.endDate).format('YYYY-MM-DD'),
      startDate: moment(filter.startDate).format('YYYY-MM-DD'),
    } || {}
  );
}
