<mat-chip-list [selectable]="selectable" [ngClass]="pillListCss">
  <mat-chip
    [ngClass]="pillCss"
    [selectable]="selectable"
    [disabled]="disabled"
    [matTooltipDisabled]="!option.tooltip"
    [matTooltip]="option.tooltip"
    [selected]="option.value === this.control.value"
    *ngFor="let option of availableOptionsValue"
    [value]="option.value"
    (click)="selectOption(option.value)">
    {{ option.label }}
  </mat-chip>
</mat-chip-list>
