import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatDatepickerIntl } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';

@UntilDestroy()
@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CalendarHeaderComponent extends ComponentAbstract implements OnInit {
  constructor(
    private _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) public calendar: MatCalendar<Date>,
    @Optional() private _dateAdapter: DateAdapter<Date>,
    @Optional() @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.calendar.stateChanges
      .pipe(
        untilDestroyed(this),
        tap(() => this.cdr.markForCheck())
      )
      .subscribe()
      .untilDestroyed(this);
  }

  get periodLabel() {
    return this._dateAdapter.format(this.calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
  }

  previousClicked() {
    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  nextClicked() {
    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }
}
