import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RestUserModel } from '@shared/interfaces/user.interface';

import * as fromAdministration from '../reducers/administration.reducer';

export const selectAdministrationState = createFeatureSelector<fromAdministration.State>(
  fromAdministration.administrationFeatureKey
);

export const selectPortfolioActiveUsers = createSelector(
  selectAdministrationState,
  administrationState => administrationState.portfolioActiveUsers
);

export const selectPortfolioUsersLoading = createSelector(
  selectAdministrationState,
  administrationState => administrationState.portfolioUsersLoading
);

export const selectActiveUsers = createSelector(
  selectAdministrationState,
  administrationState => administrationState.activeUsers
);

export const selectInactiveUsers = createSelector(
  selectAdministrationState,
  administrationState => administrationState.inactiveUsers
);

export const selectAllUsers = createSelector(
  selectActiveUsers,
  selectInactiveUsers,
  (activeUsers, inactiveUsers) => [].concat(activeUsers).concat(inactiveUsers) as RestUserModel[]
);

export const selectAllUsersLoading = createSelector(
  selectAdministrationState,
  administrationState => administrationState.usersLoading
);

export const selectAllPermissionLevels = createSelector(
  selectAdministrationState,
  administrationState => administrationState.permissionLevels
);

export const selectAllUserToGeneralRoleMapping = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userToGeneralRole
);

export const selectAllGeneralRoles = createSelector(
  selectAdministrationState,
  administrationState => administrationState.generalRoles
);

export const selectAllUsersWithGeneralRoles = createSelector(
  selectAdministrationState,
  administrationState => administrationState.usersWithGeneralRoles
);

export const selectAllUsersWithGeneralRolesLoading = createSelector(
  selectAdministrationState,
  administrationState => administrationState.usersWithGeneralRolesLoading
);

export const selectAllPropertiesStructure = createSelector(
  selectAdministrationState,
  administrationState => administrationState.propertiesStructure
);

export const selectUserListFilter = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userListFilter
);

/**
 * save User To General Role
 */
export const selectSaveUserToGeneralRoleInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.saveUserToGeneralRoleInProgress
);

export const selectSaveUserToGeneralRoleStatus = createSelector(
  selectAdministrationState,
  administrationState => administrationState.saveUserToGeneralRoleStatus
);
/**
 * assign user into property
 */
export const selectAssignUserIntoPropertyInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.assignUserIntoPropertyInProgress
);

export const selectAssignUserIntoPropertyStatus = createSelector(
  selectAdministrationState,
  administrationState => administrationState.assignUserIntoPropertyStatus
);

/**
 * removing property assigned user
 */
export const selectRemovingPropertyAssignedUserInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.removingPropertyAssignedUserInProgress
);
export const selectRemovingPropertyAssignedUserStatus = createSelector(
  selectAdministrationState,
  administrationState => administrationState.removingPropertyAssignedUserStatus
);

export const selectIsDeleteGeneralRoleInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.deleteGeneralRoleInProgress
);

export const selectAllUserToPropertyMappings = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userToPropertyMapping
);

export const selectPropertyUsers = createSelector(
  selectAdministrationState,
  administrationState => administrationState.activePropertyUsers
);

export const selectPropertyUsersLoading = createSelector(
  selectAdministrationState,
  administrationState => administrationState.propertyUsersLoading
);

export const selectGeneralRoleRadioList = createSelector(
  selectAdministrationState,
  administrationState => administrationState.generalRoleRadioList
);

/**
 * User invite
 **/
export const selectUserInviteInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userInviteInProgress
);

export const selectUserInviteStatus = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userInviteStatus
);

export const selectUserInvited = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userInvited
);

/**
 * User Update
 **/
export const selectUserUpdateInProgress = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userUpdateInProgress
);

export const selectUserUpdateStatus = createSelector(
  selectAdministrationState,
  administrationState => administrationState.userUpdateStatus
);
