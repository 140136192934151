import { createAction, props } from '@ngrx/store';

import { PropertySelectorSwitchStatus } from '../reducers/property-selector.reducer';

export const initPropertySelector = createAction(
  '[PropertySelector] initPropertySelector',
  props<{
    userId: number;
    tenantId: number;
  }>()
);

export const setPropertySelectorSwitchStatus = createAction(
  '[PropertySelector] Set Properties or Collections',
  props<{ propertySelectorSwitchStatus: PropertySelectorSwitchStatus }>()
);

export const setSelectedPortfolioId = createAction(
  '[PropertySelector] Selected portfolio id',
  props<{
    portfolioId: number | null;
  }>()
);

export const setSelectedPropertyIds = createAction(
  '[PropertySelector] Selected property ids',
  props<{
    propertyIds: number[] | null;
    allPropertiesSelected: boolean;
  }>()
);

export const setSelectedCollectionIds = createAction(
  '[PropertySelector] Selected collection ids',
  props<{ collectionIds: number[] | null }>()
);

export const setJoinedCollections = createAction('[PropertySelector] join collections', props<{ state: boolean }>());

export const setJoinedProperties = createAction('[PropertySelector] join properties', props<{ state: boolean }>());
