import { createReducer, on } from '@ngrx/store';

import {
  loadAllUserProperties,
  loadAllUserPropertiesFailed,
  loadAllUserPropertiesSucceeded,
} from '@main-application/store/actions/user.actions';
import { UpdateStatus } from '@shared/enums/update-status';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import {
  RestPortfolioModel,
  RestPropertyCollectionModel,
  RestPropertyManagerAccessModel,
  RestPropertyModel,
} from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/unit.interface';

import {
  addPropertiesToCollectionSuccess,
  addPropertyCollectionSuccess,
  addPropertyToPortfolio,
  addUsersToCollectionSuccess,
  clearPortfolioData,
  clearPropertyData,
  createNewPortfolio,
  deletePortfolio,
  deletePropertyCollection,
  deletePropertyCollectionError,
  deletePropertyCollectionSuccess,
  deleteUnitAttachment,
  deleteUnitAttachmentFailed,
  deleteUnitAttachmentSuccess,
  getPropertyUnits,
  getPropertyUnitsFailed,
  getPropertyUnitsSuccess,
  getUnitAttachments,
  getUnitAttachmentsFail,
  getUnitAttachmentsSuccess,
  loadAllUserCollections,
  loadAllUserCollectionsFailed,
  loadAllUserCollectionsSuccess,
  loadAllUserPortfolios,
  loadAllUserPortfoliosFailed,
  loadAllUserPortfoliosSuccess,
  loadAllUserUnassignedProperties,
  loadAllUserUnassignedPropertiesSucceeded,
  loadPortfolioDetails,
  loadPortfolioDetailsFailed,
  loadPortfolioDetailsSuccess,
  loadPropertyCollections,
  loadPropertyCollectionsSuccess,
  loadPropertyDetails,
  loadPropertyDetailsFailed,
  loadPropertyDetailsSuccess,
  loadPropertyManagers,
  loadPropertyManagersFailed,
  loadPropertyManagersForProperty,
  loadPropertyManagersForPropertyFailed,
  loadPropertyManagersForPropertySuccess,
  loadPropertyManagersSucceeded,
  removePropertiesFromCollectionSuccess,
  removePropertyFromPortfolio,
  removeUsersFromCollectionSuccess,
  savePropertyCollectionSuccess,
  setUnitAttachment,
  setUnitAttachmentFail,
  setUnitAttachmentSuccess,
  updatePortfolioName,
  updateProperty,
  updatePropertyFailed,
} from './portfolio.actions';

export const portfolioFeatureKey = 'portfolio';

export interface PortfolioState {
  portfolioItemsLoading?: boolean;
  portfolioItems?: RestPortfolioModel[];

  collectionItemsLoading?: boolean;
  collectionItems?: RestPropertyCollectionModel[];

  portfolioDetailsLoading?: boolean;
  portfolioDetails?: RestPortfolioModel;

  propertyDetailsLoading?: boolean;
  propertyDetails?: RestPropertyModel;

  userProperties?: RestPropertyModel[];
  userPropertiesLoading?: boolean;

  propertyCollectionsLoading?: boolean;
  propertyCollections?: RestPropertyCollectionModel[];
  propertyCollectionDeleteInProgress?: boolean;

  portfolioUpdateOngoing?: boolean;
  portfolioNewPropertyUpdateOngoing?: boolean;
  portfolioRemovePropertyUpdateOngoing?: boolean;

  propertyDetailsUpdateOngoing?: boolean;

  deletePortfolioOngoing?: boolean;

  unassignedProperties?: RestPropertyModel[];
  unassignedPropertiesLoading?: boolean;

  allPropertyManagers?: RestPropertyManagerAccessModel[];
  allPropertyManagersLoading?: boolean;

  propertyManagersForProperty?: RestPropertyManagerAccessModel[];
  propertyManagersForPropertyLoading?: boolean;

  propertyUnits?: RestUnitSummaryModel[];
  propertyUnitsLoading?: boolean;
  setUnitAttachmentInProgress?: boolean;

  unitAttachments?: RestGenericTypedAttachment[];
  unitAttachmentsLoading?: boolean;

  deleteUnitAttachmentInProgress?: boolean;
  deleteUnitAttachmentStatus?: UpdateStatus;
}

export const initialState: PortfolioState = {
  portfolioItemsLoading: false,
  propertyManagersForPropertyLoading: false,
  allPropertyManagersLoading: false,
  unitAttachmentsLoading: false,
  deleteUnitAttachmentInProgress: false,
  deleteUnitAttachmentStatus: null,
};

export const reducer = createReducer(
  initialState,
  /* Portfolios List */
  on(loadAllUserPortfolios, state => ({ ...state, portfolioItemsLoading: true })),
  on(loadAllUserPortfoliosSuccess, (state, action) => ({
    ...state,
    portfolioItemsLoading: false,
    portfolioItems: action.portfolioItems,
    deletePortfolioOngoing: false,
  })),
  on(loadAllUserPortfoliosFailed, state => ({
    ...state,
    portfolioItemsLoading: false,
    portfolioItems: [],
    deletePortfolioOngoing: false,
  })),

  /* Portfolio Details */
  on(loadPortfolioDetails, state => ({ ...state, portfolioDetailsLoading: true })),
  on(loadPortfolioDetailsSuccess, (state, action) => ({
    ...state,
    portfolioDetailsLoading: false,
    portfolioUpdateOngoing: false,
    portfolioNewPropertyUpdateOngoing: false,
    portfolioRemovePropertyUpdateOngoing: false,
    portfolioDetails: action.portfolioDetails,
  })),
  on(loadPortfolioDetailsFailed, state => ({
    ...state,
    portfolioDetailsLoading: false,
    portfolioDetails: null,
  })),

  /* Property Details */
  on(loadPropertyDetails, state => ({ ...state, propertyDetailsLoading: true })),
  on(loadPropertyDetailsSuccess, (state, action) => ({
    ...state,
    propertyDetailsLoading: false,
    propertyDetails: action.propertyDetails,
    propertyDetailsUpdateOngoing: false,
  })),
  on(loadPropertyDetailsFailed, state => ({
    ...state,
    propertyDetailsLoading: false,
    propertyDetails: null,
  })),

  /* All Properties */
  on(loadAllUserProperties, state => ({ ...state, userPropertiesLoading: true })),
  on(loadAllUserPropertiesSucceeded, (state, action) => ({
    ...state,
    userProperties: action.properties,
    userPropertiesLoading: false,
  })),
  on(loadAllUserPropertiesFailed, state => ({
    ...state,
    userProperties: [],
    userPropertiesLoading: false,
  })),

  on(updatePortfolioName, createNewPortfolio, state => ({ ...state, portfolioUpdateOngoing: true })),
  on(addPropertyToPortfolio, state => ({ ...state, portfolioNewPropertyUpdateOngoing: true })),
  on(removePropertyFromPortfolio, state => ({ ...state, portfolioRemovePropertyUpdateOngoing: true })),
  on(clearPortfolioData, state => ({
    ...state,
    portfolioDetailsLoading: false,
    portfolioUpdateOngoing: false,
    portfolioNewPropertyUpdateOngoing: false,
    portfolioRemovePropertyUpdateOngoing: false,
    portfolioDetails: null,
  })),

  on(updateProperty, state => ({ ...state, propertyDetailsUpdateOngoing: true })),
  on(updatePropertyFailed, state => ({ ...state, propertyDetailsUpdateOngoing: false })),
  on(deletePortfolio, state => ({ ...state, deletePortfolioOngoing: true })),

  on(loadAllUserUnassignedProperties, state => ({ ...state, unassignedPropertiesLoading: true })),
  on(loadAllUserUnassignedPropertiesSucceeded, (state, action) => ({
    ...state,
    unassignedProperties: action.unassignedProperties,
    unassignedPropertiesLoading: false,
  })),
  on(deletePortfolio, state => ({
    ...state,
    unassignedPropertiesLoading: false,
    unassignedProperties: [],
  })),

  on(loadPropertyManagers, state => ({ ...state, allPropertyManagersLoading: true })),
  on(loadPropertyManagersSucceeded, (state, action) => ({
    ...state,
    allPropertyManagers: action.propertyManagers,
    allPropertyManagersLoading: false,
  })),
  on(loadPropertyManagersFailed, state => ({
    ...state,
    allPropertyManagers: [],
    allPropertyManagersLoading: false,
  })),

  on(loadPropertyManagersForProperty, state => ({ ...state, propertyManagersForPropertyLoading: true })),
  on(loadPropertyManagersForPropertySuccess, (state, action) => ({
    ...state,
    propertyManagersForProperty: action.propertyManagers,
    propertyManagersForPropertyLoading: false,
  })),
  on(loadPropertyManagersForPropertyFailed, state => ({
    ...state,
    propertyManagersForProperty: [],
    propertyManagersForPropertyLoading: false,
  })),
  /**
   * get unit attachments
   */
  on(getUnitAttachments, state => ({ ...state, unitAttachmentsLoading: true })),
  on(getUnitAttachmentsSuccess, (state, action) => ({
    ...state,
    unitAttachments: action.unitAttachments,
    unitAttachmentsLoading: false,
  })),
  on(getUnitAttachmentsFail, state => ({
    ...state,
    unitAttachments: [],
    unitAttachmentsLoading: false,
  })),

  /**
   * get Property Units
   */
  on(getPropertyUnits, state => ({ ...state, propertyUnitsLoading: true })),
  on(getPropertyUnitsSuccess, (state, action) => ({
    ...state,
    propertyUnits: action.propertyUnits,
    propertyUnitsLoading: false,
  })),
  on(getPropertyUnitsFailed, state => ({
    ...state,
    propertyUnits: [],
    propertyUnitsLoading: false,
  })),

  /**
   * set unit attachment
   */
  on(setUnitAttachment, state => ({ ...state, setUnitAttachmentInProgress: true })),
  on(setUnitAttachmentSuccess, state => ({
    ...state,
    setUnitAttachmentInProgress: false,
  })),
  on(setUnitAttachmentFail, state => ({
    ...state,
    setUnitAttachmentInProgress: false,
  })),

  /**
   * delete unit attachment
   */
  on(deleteUnitAttachment, state => ({ ...state, deleteUnitAttachmentInProgress: true })),
  on(deleteUnitAttachmentSuccess, state => ({
    ...state,
    deleteUnitAttachmentInProgress: false,
    deleteUnitAttachmentStatus: UpdateStatus.DELETED,
  })),
  on(deleteUnitAttachmentFailed, state => ({
    ...state,
    deleteUnitAttachmentInProgress: false,
    deleteUnitAttachmentStatus: UpdateStatus.ERROR,
  })),
  // property collections
  on(loadPropertyCollections, state => ({ ...state, propertyCollectionsLoading: true })),
  on(loadPropertyCollectionsSuccess, (state, action) => ({
    ...state,
    propertyCollections: action.items,
    propertyCollectionsLoading: false,
  })),
  on(loadAllUserPropertiesFailed, state => ({
    ...state,
    propertyCollections: [],
    propertyCollectionsLoading: false,
  })),
  on(
    addPropertyCollectionSuccess,
    (state: PortfolioState, { collection }): PortfolioState => ({
      ...state,
      propertyCollections: [...state.propertyCollections, collection],
      propertyCollectionsLoading: false,
    })
  ),
  on(
    savePropertyCollectionSuccess,
    (state: PortfolioState, { collection }): PortfolioState => ({
      ...state,
      propertyCollections: state.propertyCollections.map(existingCollection =>
        existingCollection.id === collection.id ? collection : existingCollection
      ),
    })
  ),
  on(deletePropertyCollection, state => ({ ...state, propertyCollectionDeleteInProgress: true })),
  on(deletePropertyCollectionSuccess, (state, { id }) => ({
    ...state,
    propertyCollectionDeleteInProgress: false,
    propertyCollections: state.propertyCollections.filter(el => el.id !== id),
  })),
  on(deletePropertyCollectionError, state => ({
    ...state,
    propertyCollectionDeleteInProgress: false,
  })),
  on(
    addPropertiesToCollectionSuccess,
    removePropertiesFromCollectionSuccess,
    addUsersToCollectionSuccess,
    removeUsersFromCollectionSuccess,
    (state: PortfolioState, { collection }): PortfolioState => ({
      ...state,
      propertyCollections: state.propertyCollections.map(existingCollection =>
        existingCollection.id === collection.id ? collection : existingCollection
      ),
    })
  ),
  /**
   * get Property Units
   */
  on(clearPropertyData, state => ({
    ...state,
    propertyDetails: null,
    propertyDetailsLoading: false,
    propertyManagersForProperty: [],
    propertyManagersForPropertyLoading: false,
    propertyUnits: [],
    propertyUnitsLoading: false,
    propertyCollections: [],
    propertyCollectionsLoading: false,
  })),
  on(loadAllUserCollections, state => ({
    ...state,
    collectionItemsLoading: true,
  })),
  on(loadAllUserCollectionsSuccess, (state, action) => ({
    ...state,
    collectionItems: action.collectionItems,
    collectionItemsLoading: false,
  })),
  on(loadAllUserCollectionsFailed, (state, action) => ({
    ...state,
    collectionItemsLoading: false,
  }))
);
