import { Directive } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { selectUserPermissionLevel } from '@main-application/store/selectors/user.selectors';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { checkPermissionLevel } from '@shared/functions/check-permission-level.function';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@Directive()
export abstract class TemplateEditorBase extends PuSubscribable {
  hasEditRight = false;
  hasEditRight$: Observable<boolean>;

  constructor(protected store: Store<{}>, protected snackbarService: SnackbarService) {
    super();
    this.hasEditRight$ = store.select(selectUserPermissionLevel).pipe(
      untilDestroyed(this),
      filter((permissionLevel: PermissionLevelType[]) => !!permissionLevel?.length),
      map(permissionLevels => checkPermissionLevel(permissionLevels, PermissionLevelType.InspectionTemplates_Edit)),
      tap(hasRight => (this.hasEditRight = hasRight))
    );
    this.hasEditRight$.subscribe().untilDestroyed(this);
  }

  checkEditRight(isEditable: boolean, notify = true): boolean {
    if (!isEditable) {
      this.snackbarService.warning(SnackbarErrorMessage.CannotEditGlobalTemplate, 5000);
    }
    if (!this.hasEditRight && notify) {
      this.snackbarService.warning(SnackbarErrorMessage.TemplateEditPermissionRequired);
    }
    return isEditable && this.hasEditRight;
  }
}
