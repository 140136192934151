import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LottieModule } from 'ngx-lottie';
import { QuillModule } from 'ngx-quill';
import { SwiperModule } from 'swiper/angular';

import { LeasingUnitsChartCardModule } from '@dashboards/components/leasing-units-chart-card/leasing-units-chart-card.module';
import { TurnoversArcChartCardModule } from '@dashboards/components/turnovers-arc-chart-card/turnovers-arc-chart-card.module';
import { VacancyLossChartCardModule } from '@dashboards/components/vacancy-loss-chart-card/vacancy-loss-chart-card.module';
import { TemplateEditorAreaComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-area.component';
import { TemplateEditorDialogQuestionComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-item/question/template-editor-question.component';
import { TemplateEditorDialogAnimationComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-item/rating-group/animation/template-editor-animation.component';
import { TemplateEditorDialogRatingGroupItemComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-item/rating-group/rating-group-item/template-editor-rating-group-item.component';
import { TemplateEditorDialogRatingGroupComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-item/rating-group/template-editor-rating-group.component';
import { TemplateEditorDialogItemComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-area/template-editor-item/template-editor-item.component';
import { TemplateEditorDialogDescriptionComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-description/template-editor-description.component';
import { TemplateEditorDialogComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-dialog.component';
import { TemplateEditorDialogHeaderComponent } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-header/template-editor-header.component';
import { TemplateEditorDialogService } from '@main-application/shared/template-editor-dialog/services/template-editor-dialog.service';
import { FadeTextComponent } from '@shared/components/ellipsis-one-row/fade-text.component';
import { CheckRoleDirective } from '@shared/directives/fade-text-on-width.directive';
import { PreventKeyboardEventsDirective } from '@shared/directives/prevent-keyboard-events.directive';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { SkeletonLoaderComponent } from '@ui-components/components/skeleton-loader/skeleton-loader.component';
import { WysiwygEditorModule } from '@ui-components/components/wysiwyg-editor/wysiwyg-editor.module';
import { MaskedNumberInputComponent } from '@ui-components/controls/masked-number-input/masked-number-input.component';
import { NumberInputModule } from '@ui-components/controls/number-input/number-input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { SlideToggleComponent } from '@ui-components/controls/slide-toggle/slide-toggle.component';
import { DragNDropCursorDirectiveModule } from '@ui-components/directives/drag-drop/drag-n-drop-cursor-directive.module';

import { DndExpandableService } from './services/dnd-expandable.service';
import { SparePartsService } from './services/spare-parts.service';

@NgModule({
  declarations: [
    TemplateEditorDialogComponent,
    TemplateEditorAreaComponent,
    TemplateEditorDialogHeaderComponent,
    TemplateEditorDialogDescriptionComponent,
    TemplateEditorDialogItemComponent,
    TemplateEditorDialogAnimationComponent,
    TemplateEditorDialogRatingGroupComponent,
    TemplateEditorDialogRatingGroupItemComponent,
    TemplateEditorDialogQuestionComponent,
  ],
  imports: [
    SlideToggleComponent,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    DragDropModule,
    NumberInputModule,
    CdkScrollableModule,
    MatDialogModule,
    MatIconModule,
    IconComponent,
    LottieModule,
    DragNDropCursorDirectiveModule,
    MatButtonModule,
    MatMenuModule,
    TooltipDirectiveModule,
    MatDividerModule,
    SkeletonLoaderComponent,
    StopPropagationDirective,
    PreventKeyboardEventsDirective,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    QuillModule.forRoot(),
    WysiwygEditorModule,
    LeasingUnitsChartCardModule,
    TurnoversArcChartCardModule,
    VacancyLossChartCardModule,
    SwiperModule,
    RadioButtonDropdownModule,
    MaskedNumberInputComponent,
    FadeTextComponent,
    CheckRoleDirective,
  ],
  providers: [TemplateEditorDialogService, DndExpandableService, SparePartsService],
  exports: [TemplateEditorDialogAnimationComponent],
})
export class TemplateEditorDialogModule {}
