<div class="display-flex align-items-center justify-content-center close-button" (click)="close()">
  <mat-icon>close_small</mat-icon>
</div>

<div class="slide">
  <ng-container *appIsVideo="currentUrl['cloudUri']; else imageOrFileTemplate">
    <video
      class="image-size"
      controls
      preload="metadata"
      [class.landscape]="currentUrl.imageOrientation === ImageOrientation.LandscapeRight"
      [src]="currentUrl['cloudUri']"
      [poster]="currentUrl?.thumbnailFileUploadCloudUri || ''"
      (click)="$event.stopPropagation()"></video>
    <div class="action-buttons">
      <div
        *ngIf="data.isEditorMode"
        class="display-flex align-items-center justify-content-center"
        (click)="deleteImage(currentUrl.cloudUri)">
        <mat-icon>delete</mat-icon>
      </div>
    </div>
  </ng-container>

  <ng-template #imageOrFileTemplate>
    <ng-container *ngIf="isImage(currentUrl['cloudUri']); else fileTemplate">
      <img
        class="image-size"
        [src]="currentUrl['cloudUri']"
        [class.landscape]="currentUrl.imageOrientation === ImageOrientation.LandscapeRight"
        (click)="$event.stopPropagation()" />
      <div class="action-buttons">
        <div
          *ngIf="data.isEditorMode"
          class="display-flex align-items-center justify-content-center"
          (click)="deleteImage(currentUrl.cloudUri)">
          <mat-icon>delete</mat-icon>
        </div>

        <div class="display-flex align-items-center justify-content-center" (click)="openInNewTab(currentUrl.cloudUri)">
          <mat-icon>open_in_new</mat-icon>
        </div>
      </div>
    </ng-container>

    <ng-template #fileTemplate>
      <mat-icon class="size-100 image-size" [svgIcon]="EIcon.FILE_PREVIEW"></mat-icon>
      <div class="action-buttons">
        <div class="display-flex align-items-center justify-content-center" (click)="downloadFile(currentUrl.cloudUri)">
          <mat-icon>download</mat-icon>
        </div>
        <div
          *ngIf="data.isEditorMode"
          class="display-flex align-items-center justify-content-center"
          (click)="deleteImage(currentUrl.cloudUri)">
          <mat-icon>delete</mat-icon>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>

<div class="images-list display-flex">
  <div class="thumbs display-flex" (click)="$event.stopPropagation()">
    <ng-container *ngFor="let mediaUrl of data.imageUrls">
      <ng-container *appIsVideo="mediaUrl['cloudUri']; else imageOrFileTemplateThumb">
        <video
          class="thumb-video"
          preload="metadata"
          [class.landscape]="mediaUrl.imageOrientation === ImageOrientation.LandscapeRight"
          [src]="mediaUrl['cloudUri']"
          [poster]="mediaUrl?.thumbnailFileUploadCloudUri || ''"
          (click)="onChange(mediaUrl['cloudUri'])"
          [class.current]="mediaUrl['cloudUri'] === currentUrl['cloudUri']"></video>
      </ng-container>

      <ng-template #imageOrFileTemplateThumb>
        <ng-container *ngIf="isImage(mediaUrl['cloudUri']); else fileTemplateThumb">
          <img
            class="thumb-image"
            [class.landscape]="mediaUrl.imageOrientation === ImageOrientation.LandscapeRight"
            [src]="mediaUrl['cloudUri']"
            (click)="onChange(mediaUrl['cloudUri'])"
            [class.current]="mediaUrl['cloudUri'] === currentUrl['cloudUri']" />
        </ng-container>

        <ng-template #fileTemplateThumb>
          <mat-icon
            [class.current]="mediaUrl['cloudUri'] === currentUrl['cloudUri']"
            (click)="onChange(mediaUrl['cloudUri'])"
            class="size-100"
            [svgIcon]="EIcon.FILE_PREVIEW"></mat-icon>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>
