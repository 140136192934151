import { createAction, props } from '@ngrx/store';

import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import { KpiWindowType } from '@shared/enums/kpi-window-type';
import { PortfolioKpiFilter } from '@shared/interfaces/portfolio-kpi-filter';

/**
 * load portfolio kpi
 */
export const loadPortfolioKpisTrend = createAction(
  '[Dashboard] load portfolio kpis trend',
  props<{ portfolioId: number; kpiTypes: PortfolioKpiType[]; dateOffset: KpiWindowType }>()
);
export const loadPortfolioKpis = createAction(
  '[Dashboard] load portfolio kpis',
  props<{ portfolioId: number; kpiTypes: PortfolioKpiType[] }>()
);
export const setPortfolioKpis = createAction('[Dashboard] set portfolio kpis', props<{ kpis: RestPortfolioKpi[] }>());
export const setPortfolioKpisTrend = createAction(
  '[Dashboard] set portfolio kpis trend',
  props<{ kpis: RestPortfolioKpi[] }>()
);
export const loadPortfolioKpisFailed = createAction('[Dashboard] load portfolio kpis failed');

export const loadPortfolioKpisTrendFailed = createAction('[Dashboard] load portfolio kpis trend failed');

export const setPortfolioKpisFilter = createAction(
  '[Dashboard] set portfolio kpi filter',
  props<{ portfolioKpisFilter: PortfolioKpiFilter }>()
);

export const clearPortfolioKpis = createAction('[Dashboard] clear portfolio kpis');

export const loadPortfolioKpiTrendLines = createAction(
  '[Dashboard] load portfolio kpi trend lines',
  props<{ portfolioId: number; trendDays: number[]; kpiTypes: PortfolioKpiType[] }>()
);

export const setPortfolioKpiTrendLines = createAction(
  '[Dashboard] set portfolio kpi trend lines',
  props<{ kpis: RestPortfolioKpi[] }>()
);

export const clearPortfolioKpiTrendLines = createAction('[Dashboard] clear portfolio kpi trends');

export const setChartSelectorValue = createAction(
  '[Dashboard] set chart selector value',
  props<{ selectorId: string; value: number }>()
);

export const setChartSettingsValue = createAction(
  '[Dashboard] set chart settings value',
  props<{ chartId: string; value: any }>()
);

export const loadPortfolioKpiWorkflowSteps = createAction(
  '[Dashboard] load portfolio kpi workflow steps',
  props<{ portfolioId: number; trendDays: number[]; kpiTypes: number[] }>()
);

export const setPortfolioKpiWorkflowSteps = createAction(
  '[Dashboard] set portfolio kpis workflow steps',
  props<{ kpis: RestPortfolioKpi[] }>()
);

export const loadPortfolioKpisWorkflowStepsFailed = createAction(
  '[Dashboard] load portfolio kpis workflow steps failed'
);

export const clearPortfolioKpiWorkflowSteps = createAction('[Dashboard] clear portfolio kpi workflow steps');
