import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { selectPortfolios } from '@portfolio/store/portfolio.selectors';
import { InputModule } from '@ui-components/controls/input/input.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

@UntilDestroy()
@Component({
  selector: 'app-select-portfolio',
  templateUrl: './select-portfolio.component.html',
  styleUrls: ['./select-portfolio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    RadioButtonDropdownModule,
    InputModule,
    ReactiveFormsModule,
    MatButtonModule,
    A11yModule,
    MatDialogModule,
  ],
})
export class SelectPortfolioDialogComponent {
  @Output() onSelected = new EventEmitter<number>();

  protected formBuilder = inject(FormBuilder);
  protected store = inject(Store<{}>);

  public getPortfoliosList$ = this.store
    .select(selectPortfolios)
    .pipe(map(list => list.map(el => ({ value: el.id, label: el.name }))));

  public portfolioIdControl = this.formBuilder.control<number | null>(null, [Validators.required]);

  public get isFormValid(): boolean {
    return !this.portfolioIdControl.valid;
  }

  public onSelectPortfolio(): void {
    this.onSelected.emit(this.portfolioIdControl.value);
  }
}
