import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { each } from 'lodash';
import { Observable, filter, iif, mergeMap, of, take, tap, withLatestFrom } from 'rxjs';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { getElapsedDaysThresholds } from '@main-application/management/pages/turnover-configuration/store/actions/turnover-configuration.actions';
import { selectThresholdsConfiguration } from '@main-application/management/pages/turnover-configuration/store/selectors/turnover-configuration.selectors';
import { deleteFile } from '@main-application/store/actions/file-upload.actions';
import {
  clearSelectedPunch,
  loadPunchesByTurnoverId,
  sendPunch,
  sendPunchFinished,
} from '@main-application/turnovers/store/actions/punch.actions';
import {
  deleteFileFromRepository,
  deleteTurnoverTicketAttachment,
  setTicketAttachment,
  setTicketAttachmentSuccess,
} from '@main-application/turnovers/store/actions/turnovers.actions';
import { selectSelectedPunch } from '@main-application/turnovers/store/selectors/punch.selectors';
import {
  selectTurnoverDataId,
  selectTurnoverUnitName,
} from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { PriorityTypeListConst } from '@shared/constants/priority-type-list.const';
import { TicketAttachmentType } from '@shared/enums/attachment-type';
import { PriorityType } from '@shared/enums/priority-type';
import { TicketStatusType } from '@shared/enums/ticket-status-type';
import { TicketType } from '@shared/enums/ticket-type';
import { TurnoverPunchFields } from '@shared/enums/turnover-punch-fields.enum';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { getAttachmentToEntityParams } from '@shared/functions/ticket-attachment.utils';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { TurnoverPunch } from '@shared/interfaces/turnover-punch';
import { RestTicketModel } from '@shared/interfaces/turnover.interface';
import { TurnoverAttachmentPipe } from '@shared/pipes/turnover-attachment.pipe';

import { DialogResult } from '../config/dialog-result.enum';

@UntilDestroy()
@Component({
  selector: 'app-turnover-punch-modal',
  templateUrl: './turnover-punch-modal.component.html',
  styleUrls: ['./turnover-punch-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnoverPunchModalComponent extends PuSubscribable implements OnInit {
  TicketAttachmentType = TicketAttachmentType;
  TicketStatusType = TicketStatusType;
  form: UntypedFormGroup;
  isNewPunch = false;
  newAttachment: AttachmentItem;
  allAttachments: RestGenericTypedAttachment[];
  cleanInputAttachment: EventEmitter<void> = new EventEmitter<void>();
  isCompleted: boolean;
  turnoverUnitName$: Observable<string>;
  inProgress = false;

  readonly priorityItems: IRadioButtonOption<PriorityType>[] = PriorityTypeListConst;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: RestTicketModel,
    private dialogRef: MatDialogRef<TurnoverPunchModalComponent>,
    private store: Store<{}>,
    private actions$: Actions,
    private cdr: ChangeDetectorRef,
    private timezoneService: TimezoneService,
    private turnoverAttachment: TurnoverAttachmentPipe
  ) {
    super();
  }

  get dueDate(): UntypedFormControl {
    return this.form.get(TurnoverPunchFields.DueDate) as UntypedFormControl;
  }

  get priority(): UntypedFormControl {
    return this.form.get(TurnoverPunchFields.Priority) as UntypedFormControl;
  }

  get title(): UntypedFormControl {
    return this.form.get(TurnoverPunchFields.Title) as UntypedFormControl;
  }

  get description(): UntypedFormControl {
    return this.form.get(TurnoverPunchFields.Description) as UntypedFormControl;
  }

  get attachment(): UntypedFormControl {
    return this.form.get(TurnoverPunchFields.Attachment) as UntypedFormControl;
  }

  ngOnInit(): void {
    this.isNewPunch = !this.data.id;
    this.isCompleted = this.data?.ticketStatusType === TicketStatusType.Resolved;
    this.allAttachments = this.data?.allAttachments;

    /* reset modal */
    this.store.dispatch(clearSelectedPunch());
    this.store.dispatch(getElapsedDaysThresholds());

    /**
     * after deleting attachment on existing punch
     * it will be reloaded
     */
    this.store
      .select(selectSelectedPunch)
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        tap(punch => {
          this.data = punch;
          this.allAttachments = this.data?.allAttachments;
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.turnoverUnitName$ = this.store.select(selectTurnoverUnitName);
    this.store
      .select(selectThresholdsConfiguration)
      .pipe(untilDestroyed(this), filter(Boolean), take(1))
      .subscribe(config => {
        const punchLowThreshold = config.stepThresholds.find(
          e => e.workflowStepEnumType === WorkflowStepEnumType.Punch
        )?.lowThresholdValue;
        const currentDate = this.timezoneService.getCurrentDateOnly();
        const defaultDate = new Date(currentDate.setDate(currentDate.getDate() + punchLowThreshold));

        this.form = this.fb.group({
          [TurnoverPunchFields.DueDate]: [this.data?.dueDate ?? defaultDate, Validators.required],
          [TurnoverPunchFields.Priority]: [this.data?.ticketPriorityType ?? PriorityType.Medium, Validators.required],
          // let's hardcode title as "Punch List" for now - lyman
          // [TurnoverPunchFields.Title]: [this.data?.name, Validators.maxLength(50)],
          [TurnoverPunchFields.Title]: ['Punch List', Validators.maxLength(255)],
          [TurnoverPunchFields.Description]: [this.data?.detailedInformation, Validators.maxLength(500)],
          [TurnoverPunchFields.Attachment]: [],
        });
        this.cdr.detectChanges();
      })
      .untilDestroyed(this);
  }

  deletePunchAttachment(attachment: RestGenericTypedAttachment) {
    const ticketType = TicketType.Punch;
    this.store.dispatch(deleteTurnoverTicketAttachment({ ticketType, attachment, ticketId: this.data.id }));
  }

  deleteNewPunchAttachment() {
    if (this.newAttachment?.upload) {
      this.store.dispatch(deleteFile({ fileId: this.newAttachment?.upload?.id }));
    }
    this.cleanInputAttachment.emit();
    this.newAttachment = null;
    this.cdr.detectChanges();
  }

  private removeAttachmentsIfNeeded() {
    if (this.hasAttachments()) {
      each(this.getAttachments(), item => this.deletePunchAttachment(item));
    }
  }

  addAttachment(attachmentItem: AttachmentItem) {
    const isNewTicket = !this.data.id;

    if (isNewTicket) {
      if (attachmentItem?.upload?.id !== this.newAttachment?.upload?.id && this.newAttachment?.upload?.id) {
        this.store.dispatch(deleteFileFromRepository({ fileId: this.newAttachment?.upload?.id }));
      }
      this.newAttachment = attachmentItem;
    } else {
      this.removeAttachmentsIfNeeded();

      this.store.dispatch(
        setTicketAttachment({
          ticketType: TicketType.Punch,
          attachmentToEntity: getAttachmentToEntityParams(attachmentItem, this.data.id),
        })
      );
    }

    this.cdr.detectChanges();
  }

  cancel() {
    this.close(DialogResult.Fail);
  }

  private close(result: DialogResult) {
    if (this.newAttachment?.upload) {
      this.store.dispatch(deleteFile({ fileId: this.newAttachment?.upload?.id }));
    }

    this.dialogRef.close(result);
  }

  private getAttachments(): RestGenericTypedAttachment[] {
    return this.turnoverAttachment.transform(this.data.allAttachments, TicketAttachmentType.Attachment);
  }

  hasAttachments(): boolean {
    const attachments = this.getAttachments();

    return this.isNewPunch ? !!this.newAttachment : !!attachments?.length;
  }

  send() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    this.inProgress = true;
    const formValue = this.form.value as TurnoverPunch;
    const punch = {
      ...this.data,
      dueDate: formValue.DueDate,
      ticketPriorityType: formValue.Priority,
      name: formValue.Title || '',
      detailedInformation: formValue.Description || '',
    } as RestTicketModel;

    this.store.dispatch(sendPunch({ punch }));

    const attachmentAdded$ = this.actions$.pipe(
      ofType(setTicketAttachmentSuccess),
      take(1),
      tap(() => {
        this.newAttachment = null;
        this.cdr.detectChanges();
      })
    );

    /* send punch and assign attachment if needed */
    this.actions$
      .pipe(
        untilDestroyed(this),
        ofType(sendPunchFinished),
        take(1),
        mergeMap(action => {
          const isNewTicket = !this.data.id;

          const addAttachmentAndWaitForSuccess$ = of(action.punch.id).pipe(
            tap(id => {
              this.store.dispatch(
                setTicketAttachment({
                  ticketType: TicketType.Punch,
                  attachmentToEntity: getAttachmentToEntityParams(this.newAttachment, action.punch.id),
                })
              );
            }),
            mergeMap(() => attachmentAdded$)
          );

          return iif(() => isNewTicket, addAttachmentAndWaitForSuccess$, of(null));
        }),
        withLatestFrom(this.store.select(selectTurnoverDataId)),
        tap(([, turnoverId]) => {
          this.store.dispatch(loadPunchesByTurnoverId({ turnoverId }));
          this.close(DialogResult.Success);
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }
}
