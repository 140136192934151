import * as moment from 'moment/moment';

export enum KpiWindowType {
  OpenTurns = 1,
  Days30 = 30,
  Days60 = 60,
  Days90 = 90,
  Months6 = 180,
  Months12 = 365,
}

export const getTrendDaysDiff = (trendDaysValue: KpiWindowType, currentDate: Date): number[] => {
  const startOfMonth = moment(currentDate).startOf('month');

  const prevMonth1Middle = moment(startOfMonth).add(-15, 'days');
  const prevMonth1Start = moment(startOfMonth).add(-1, 'month');

  const prevMonth2Middle = moment(prevMonth1Start).add(-15, 'days');
  const prevMonth2Start = moment(prevMonth1Start).add(-1, 'month');

  const prevMonth3Middle = moment(prevMonth2Start).add(-15, 'days');
  const prevMonth3Start = moment(prevMonth2Start).add(-1, 'month');

  const trendDays = [moment(currentDate), startOfMonth, prevMonth1Middle, prevMonth1Start];

  if (trendDaysValue > 30) {
    trendDays.push(prevMonth2Middle, prevMonth2Start);
  }

  if (trendDaysValue > 60) {
    trendDays.push(prevMonth3Middle, prevMonth3Start);
  }

  const trendDaysDiff = trendDays.map(i => getDaysDiff(currentDate, i) * -1);

  if (trendDaysValue === KpiWindowType.Months6) {
    while (Math.abs(trendDaysDiff[trendDaysDiff.length - 1]) < KpiWindowType.Months6) {
      trendDaysDiff.push(trendDaysDiff[trendDaysDiff.length - 1] + -30);
    }

    trendDaysDiff[trendDaysDiff.length - 1] = -KpiWindowType.Months6;
  }

  if (trendDaysValue === KpiWindowType.Months12) {
    while (Math.abs(trendDaysDiff[trendDaysDiff.length - 1]) < KpiWindowType.Months12) {
      trendDaysDiff.push(trendDaysDiff[trendDaysDiff.length - 1] + -30);
    }

    trendDaysDiff[trendDaysDiff.length - 1] = -KpiWindowType.Months12;
  }
  return trendDaysDiff;
};

function getDaysDiff(getCurrentDate: Date, dt: moment.Moment): number {
  return moment(getCurrentDate).diff(dt, 'days');
}
