import { MetaReducer, createReducer, on } from '@ngrx/store';

import { RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import {
  clearPortfolioKpiTrendLines,
  clearPortfolioKpiWorkflowSteps,
  clearPortfolioKpis,
  loadPortfolioKpiTrendLines,
  loadPortfolioKpiWorkflowSteps,
  loadPortfolioKpis,
  loadPortfolioKpisFailed,
  loadPortfolioKpisTrend,
  loadPortfolioKpisTrendFailed,
  loadPortfolioKpisWorkflowStepsFailed,
  setChartSelectorValue,
  setChartSettingsValue,
  setPortfolioKpiTrendLines,
  setPortfolioKpiWorkflowSteps,
  setPortfolioKpis,
  setPortfolioKpisFilter,
  setPortfolioKpisTrend,
} from '@dashboards/store/actions/kpi.actions';
import { hydrationMetaReducer, patchInitialValue } from '@main-application/store/reducers/hydration-meta.reducer';
import { KpiType } from '@shared/enums/kpi-type';
import { KpiWindowType } from '@shared/enums/kpi-window-type';
import { PortfolioKpiFilter } from '@shared/interfaces/portfolio-kpi-filter';

export const kpiFeatureKey = 'dashboard-kpis';

export interface DashboardKpisState {
  portfolioKpis: RestPortfolioKpi[];
  portfolioKpisLoading: boolean;
  portfolioKpisTrend: RestPortfolioKpi[];
  portfolioKpisTrendLoading: boolean;
  portfolioKpisFilter: PortfolioKpiFilter;
  portfolioKpiTrendLines: RestPortfolioKpi[];
  portfolioKpiTrendLinesLoading: boolean;
  portfolioKpiWorkflowSteps: RestPortfolioKpi[];
  portfolioKpiWorkflowStepsLoading: boolean;
  chartSelectors: {
    [key: string]: number;
  };
  chartSettings: {
    [key: string]: any;
  };
}

export const initialState: DashboardKpisState = patchInitialValue(
  {
    portfolioKpis: [],
    portfolioKpisTrendLoading: false,
    portfolioKpisFilter: {
      windowType: KpiWindowType.Days30,
      type: KpiType.All,
    },
    portfolioKpisLoading: false,
    portfolioKpisTrend: [],
    portfolioKpiTrendLines: [],
    portfolioKpiTrendLinesLoading: false,
    portfolioKpiWorkflowSteps: [],
    portfolioKpiWorkflowStepsLoading: false,
    chartSelectors: {},
    chartSettings: {},
  },
  kpiFeatureKey
);

export const metaDashboardKpiReducers: Array<MetaReducer<any, any>> = [
  hydrationMetaReducer<DashboardKpisState>(kpiFeatureKey),
];

export const reducer = createReducer(
  initialState,
  on(setPortfolioKpis, (state, action) => ({
    ...state,
    portfolioKpisLoading: false,
    portfolioKpis: action.kpis,
  })),
  on(setPortfolioKpisTrend, (state, action) => ({
    ...state,
    portfolioKpisTrendLoading: false,
    portfolioKpisTrend: action.kpis,
  })),
  on(loadPortfolioKpis, (state, action) => ({
    ...state,
    portfolioKpisLoading: true,
  })),
  on(loadPortfolioKpisTrend, (state, action) => ({
    ...state,
    portfolioKpisTrendLoading: true,
  })),
  on(loadPortfolioKpisFailed, (state, action) => ({
    ...state,
    portfolioKpisLoading: false,
  })),
  on(loadPortfolioKpisTrendFailed, (state, action) => ({
    ...state,
    portfolioKpisTrendLoading: false,
  })),
  on(setPortfolioKpisFilter, (state, action) => ({
    ...state,
    portfolioKpisFilter: action.portfolioKpisFilter,
  })),
  on(clearPortfolioKpis, state => ({
    ...state,
    portfolioKpis: [],
    portfolioKpisTrend: [],
  })),
  on(setPortfolioKpiTrendLines, (state, action) => ({
    ...state,
    portfolioKpiTrendLines: action.kpis,
    portfolioKpiTrendLinesLoading: false,
  })),
  on(clearPortfolioKpiTrendLines, state => ({
    ...state,
    portfolioKpiTrendLines: [],
  })),
  on(setChartSelectorValue, (state, action) => ({
    ...state,
    chartSelectors: { ...state.chartSelectors, [action.selectorId]: action.value },
  })),
  on(setChartSettingsValue, (state, action) => ({
    ...state,
    chartSettings: { ...state.chartSettings, [action.chartId]: action.value },
  })),
  on(loadPortfolioKpiTrendLines, state => ({
    ...state,
    portfolioKpiTrendLinesLoading: true,
  })),
  on(loadPortfolioKpiWorkflowSteps, state => ({
    ...state,
    portfolioKpiWorkflowStepsLoading: true,
  })),
  on(setPortfolioKpiWorkflowSteps, (state, action) => ({
    ...state,
    portfolioKpiWorkflowSteps: action.kpis,
    portfolioKpiWorkflowStepsLoading: false,
  })),
  on(loadPortfolioKpisWorkflowStepsFailed, (state, action) => ({
    ...state,
    portfolioKpiWorkflowStepsLoading: false,
  })),
  on(clearPortfolioKpiWorkflowSteps, state => ({
    ...state,
    portfolioKpiWorkflowSteps: [],
  }))
);
