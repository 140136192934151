import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { some } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { AdministrationAbstract } from '@main-application/administration/abstract/administration-abstract';
import { VENDOR_ROLE_NAME } from '@main-application/administration/constants/vendor-role-name.constant';
import { combineUserWithProperties } from '@main-application/administration/functions/combineUserWithProperties';
import { formatPropertyStructureToTree } from '@main-application/administration/functions/propertyStructureToTree';
import {
  clearUserPreviewData,
  inviteNewUser,
  loadAllGeneralRoles,
} from '@main-application/administration/store/actions/administration.actions';
import {
  selectAllGeneralRoles,
  selectAllPropertiesStructure,
  selectAllUserToPropertyMappings,
  selectAllUsersWithGeneralRoles,
  selectAssignUserIntoPropertyInProgress,
  selectAssignUserIntoPropertyStatus,
  selectInactiveUsers,
  selectSaveUserToGeneralRoleInProgress,
  selectSaveUserToGeneralRoleStatus,
  selectUserInviteInProgress,
  selectUserInviteStatus,
  selectUserInvited,
} from '@main-application/administration/store/selectors/administration.selectors';
import { UserPreview, UserPreviewFields } from '@main-application/administration/users/config/enums/user-preview';
import { selectNativeLanguagesEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { selectUserData } from '@main-application/store/selectors/user.selectors';
import { getCompanyList } from '@main-application/turnovers/store/actions/turnovers.actions';
import {
  selectCompanyList,
  selectCompanyListLoading,
} from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { UpdateStatus } from '@shared/enums/update-status';
import { UserType } from '@shared/enums/user-type';
import { getCompanyRadioList } from '@shared/functions/get-company-radio-list.function';
import { getEnumerationRadioListFunction } from '@shared/functions/get-enumeration-radio-list.function';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserData } from '@shared/interfaces/user-data';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import { InviteRestUserModel, RestUserModel } from '@shared/interfaces/user.interface';
import { UserExistsValidator } from '@shared/validators/user-exists.validator';
import { UserInactiveValidator } from '@shared/validators/user-inactive.validator';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { EmailPatternValidator, correctEmailInput } from '@ui-components/validators/email.validator';

export interface AddVendorUserModalData {
  vendorId?: number;
  header?: string;
}

export interface AddVendorUserModalResult {
  invitedUser: RestUserModel;
}

@UntilDestroy()
@Component({
  selector: 'app-add-vendor-user-modal',
  templateUrl: './add-vendor-user-modal.component.html',
  styleUrls: ['./add-vendor-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddVendorUserModalComponent extends AdministrationAbstract implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  selectedProperties: PropertyBasicInfo[] = [];
  propertiesToSelect: PropertyBasicInfo[] = [];
  arePropertiesChanged = false;
  root = [];
  warningMessage = '';
  companyRadioList: IRadioButtonOption<number>[] = [];
  nativeLanguages: IRadioButtonOption<number>[] = [];
  companyListLoading$: Observable<boolean>;
  userToPropertyMapping: UserToPropertyMapping[];
  vendorRoleId: number;
  header: string;
  saveButtonText: string;
  formSubmitted = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected store: Store<{}>,
    public dialogRef: MatDialogRef<AddVendorUserModalComponent, AddVendorUserModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: AddVendorUserModalData,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService
  ) {
    super(cdr, store);

    this.header = data.header;
    this.saveButtonText = data.vendorId ? 'Add and Assign' : 'Send invitation';
  }

  get firstName(): FormControl<string> {
    return this.form.get(UserPreviewFields.FirstName) as FormControl<string>;
  }

  get lastName(): FormControl<string> {
    return this.form.get(UserPreviewFields.LastName) as FormControl<string>;
  }

  get email(): FormControl<string> {
    return this.form.get(UserPreviewFields.Email) as FormControl<string>;
  }

  get companyId(): FormControl<number> {
    return this.form.get(UserPreviewFields.CompanyId) as FormControl<number>;
  }

  get phoneNumber(): FormControl {
    return this.form.get(UserPreviewFields.PhoneNumber) as FormControl;
  }

  get nativeLanguage(): FormControl {
    return this.form.get(UserPreviewFields.NativeLanguage) as FormControl<number>;
  }

  ngOnInit(): void {
    this.initForm();
    this.store.dispatch(getCompanyList());
    this.store.dispatch(loadAllGeneralRoles());
    this.companyListLoading$ = this.store.select(selectCompanyListLoading);

    this.store
      .select(selectAllGeneralRoles)
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(allRoles => (this.vendorRoleId = allRoles.find(e => e.roleName == VENDOR_ROLE_NAME)?.id))
      .untilDestroyed(this);

    this.store
      .select(selectNativeLanguagesEnumeration)
      .pipe(
        untilDestroyed(this),
        tap(values => {
          this.nativeLanguages = getEnumerationRadioListFunction(values, null, false);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.store
      .select(selectCompanyList)
      .pipe(
        untilDestroyed(this),
        filter((companyList: RestCompanyModel[]) => !!companyList),
        tap((companyList: RestCompanyModel[]) => {
          this.companyRadioList = getCompanyRadioList(companyList);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.store
      .select(selectUserData)
      .pipe(
        untilDestroyed(this),
        filter((userData: UserData) => !!userData),
        tap((userData: UserData) => {
          this.userData = userData;
        })
      )
      .subscribe()
      .untilDestroyed(this);

    combineLatest([this.store.select(selectAllUsersWithGeneralRoles), this.store.select(selectInactiveUsers)])
      .pipe(
        untilDestroyed(this),
        filter(([allUsers, inactiveUsers]) => !!allUsers && !!inactiveUsers),
        tap(([allUsers, inactiveUsers]: [UserWithGeneralRole[], RestUserModel[]]) => {
          const existingActiveEmails = allUsers.map(item => item.email);
          const existingInactiveEmails = inactiveUsers.map(item => item.email);
          this.email.addValidators(UserExistsValidator(existingActiveEmails));
          this.email.addValidators(UserInactiveValidator(existingInactiveEmails));
        })
      )
      .subscribe()
      .untilDestroyed(this);

    combineLatest([
      this.store.select(selectAllPropertiesStructure),
      this.store.select(selectAllUserToPropertyMappings),
      this.store.select(selectUserData),
    ])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(
          ([allProperties, userToPropertyMapping, userData]) => !!allProperties && !!userToPropertyMapping && !!userData
        ),
        tap(([allProperties, userToPropertyMapping, userData]) => {
          this.userToPropertyMapping = userToPropertyMapping;
          this.root = formatPropertyStructureToTree(allProperties);
          this.propertiesToSelect = combineUserWithProperties(userData.id, allProperties, userToPropertyMapping);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.inProgress$ = combineLatest([
      this.store.select(selectUserInviteInProgress),
      this.store.select(selectSaveUserToGeneralRoleInProgress),
      this.store.select(selectAssignUserIntoPropertyInProgress),
    ]).pipe(map(operationsInProgress => some(operationsInProgress, Boolean)));

    combineLatest([
      this.store.select(selectUserInvited),
      this.store.select(selectUserInviteStatus),
      this.store.select(selectSaveUserToGeneralRoleStatus),
      this.store.select(selectAssignUserIntoPropertyStatus),
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([invitedUser, userInviteStatus, saveUserToGeneralRoleStatus, assignUserIntoPropertyStatus]) =>
            invitedUser &&
            userInviteStatus === UpdateStatus.OK &&
            saveUserToGeneralRoleStatus === UpdateStatus.UPDATED &&
            assignUserIntoPropertyStatus === UpdateStatus.UPDATED
        )
      )
      .subscribe(([invitedUser]) => {
        const company = this.companyRadioList.find(c => c.value === invitedUser.companyId);
        this.snackbarService.success(`${invitedUser.displayName} added to ${company?.label}`);
        this.close(DialogResult.Success, invitedUser);
      })
      .untilDestroyed(this);

    if (this.data.vendorId) {
      this.companyId.setValue(this.data.vendorId);
    }
  }

  save() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    const { firstName, lastName, email, userGroupType, companyId, phoneNumber, nativeLanguage } = this.form
      .value as UserPreview;
    let selectedProperty: number[] = [];
    const inviteUser: InviteRestUserModel = {
      email,
      firstName,
      lastName,
      userGroupType: UserType.Contractor,
      displayName: `${firstName} ${lastName}`,
      companyId,
      phoneNumber,
      nativeLanguage,
    };

    if (this.arePropertiesChanged) {
      selectedProperty = this.selectedProperties.map(item => item.id);
    }

    this.store.dispatch(inviteNewUser({ user: inviteUser, roleId: this.vendorRoleId, selectedProperty }));
  }

  close(result: DialogResult = DialogResult.Fail, invitedUser?: RestUserModel) {
    if (result === DialogResult.Fail) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close({
        invitedUser,
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(clearUserPreviewData());
  }

  private initForm() {
    this.form = this.formBuilder.group({
      [UserPreviewFields.FirstName]: [null, [Validators.required, Validators.maxLength(24)]],
      [UserPreviewFields.LastName]: [null, [Validators.required, Validators.maxLength(24)]],
      [UserPreviewFields.Email]: [null, [Validators.required, EmailPatternValidator(), Validators.email]],
      [UserPreviewFields.CompanyId]: [null, [Validators.required]],
      [UserPreviewFields.PhoneNumber]: [null, [Validators.pattern('[- +()0-9]+')]],
      [UserPreviewFields.NativeLanguage]: [0, Validators.required],
    });
    correctEmailInput(this.email).subscribe().untilDestroyed(this);
  }

  selectedRootsChange($event: PropertyBasicInfo[]) {
    this.arePropertiesChanged = true;
    this.selectedProperties = $event;
  }
}
