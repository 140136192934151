import { sortBy } from 'lodash';

import { WorkflowStepListConst } from '@shared/constants/workflow-step-list.const';
import { UserType } from '@shared/enums/user-type';
import { IRadioButtonOption, IUserRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserData } from '@shared/interfaces/user-data';
import { RestUserModel, RestUserModelExtended } from '@shared/interfaces/user.interface';

export function getUserList(users: RestUserModelExtended[]): IUserRadioButtonOption<number>[] {
  const map: any = {};
  return users
    .filter(item => !!item.id)
    .map(item => {
      let defaultLabel = `${item.firstName} ${item.lastName}`;
      if (!item.firstName && !item.lastName) {
        defaultLabel = item.email;
      }

      let label = '';
      if (item.firstName && item.lastName && !item?.isFullName) {
        let charAmount = 1;
        label = `${item.firstName} ${item.lastName.substring(0, charAmount).toUpperCase()}`;
        while (map[label]) {
          charAmount++;
          label = (
            item.lastName.length >= charAmount
              ? `${item.firstName} ${item.lastName.substring(0, charAmount)}`
              : `${item.firstName} ${item.lastName} ${item.lastName.length - charAmount + 1}`
          ).trim();
        }
      }
      if (!label) {
        label = defaultLabel;
      } else if (map[label]) {
        label = defaultLabel;
      }

      if (item.isDefaultAssignee) {
        const stepName = WorkflowStepListConst.find(s => s.value === item.defaultAssigneeForStep)?.label;
        label += ` (default ${stepName} assignee)`;
      }

      if (item.isResidentAssignee) {
        label += ` (resident assignee)`;
      }

      if (item.isAssignToResident) {
        label = `Resident (dynamic)`;
      }

      if (item.isMe) {
        label += ' (me)';
      }
      map[label] = true;
      const user = {
        value: item.id,
        label,
        tooltip: defaultLabel,
        userGroupType: item.userGroupType,
      };
      if (item.lastName) user['additionalSearchLabel'] = item.lastName;

      return user;
    });
}

export function getUserDisplayName(user: Pick<RestUserModel, 'firstName' | 'lastName' | 'email'>) {
  if (!user) {
    return '';
  }
  let defaultLabel = `${user.firstName} ${user.lastName}`;
  if (!user.firstName && !user.lastName) {
    defaultLabel = user.email;
  }
  let label = null;
  if (user.firstName && user.lastName) {
    label = `${user.firstName} ${user.lastName.substring(0, 1).toUpperCase()}`;
  }
  return label ?? defaultLabel;
}

export function getUserLastName(user: Pick<RestUserModel, 'firstName' | 'lastName' | 'email'>): string {
  return user?.lastName;
}

export function getUserOrVendorList(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<number>>(item => {
      return item.userGroupType === UserType.Contractor
        ? {
            value: item.id,
            label: item.companyName,
          }
        : {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
    });
}

export function getUserOrVendorItems(users: RestUserModel[]): IRadioButtonOption<RestUserModel>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<RestUserModel>>(item => {
      return item.userGroupType === UserType.Contractor
        ? {
            value: item,
            label: item.companyName,
          }
        : {
            value: item,
            label: `${item.firstName} ${item.lastName}`,
          };
    });
}

export function getUserItems(users: RestUserModel[]): IRadioButtonOption<RestUserModel>[] {
  return users
    .filter(item => !!item.id)
    .map<IRadioButtonOption<RestUserModel>>(item => {
      return {
        value: item,
        label: `${item.firstName} ${item.lastName}`,
      };
    });
}

export function getSortedUserItems(users: RestUserModel[], userData?: UserData): IRadioButtonOption<number>[] {
  return sortBy(
    users
      .filter(item => !!item.id)
      .map<IRadioButtonOption<number>>(item => {
        return {
          value: item.id,
          label: getUserDisplayName(item),
          additionalSearchLabel: getUserLastName(item),
        };
      }),
    [item => item.label.toUpperCase()]
  );
}

export function getInternalUserList(users: RestUserModel[]): IRadioButtonOption<number>[] {
  return users
    .filter(item => !!item.id && item.userGroupType === UserType.Employee)
    .map<IRadioButtonOption<number>>(item => {
      return {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
    });
}

export function currentLabelMe(label: string): string {
  return `${label} (me)`;
}
