import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMaskModule } from 'ngx-mask';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import { EIcon } from '@shared/enums/icon.enum';
import { parseNumber } from '@shared/functions/parse-number';
import { IconComponent } from '@ui-components/components/icon/icon.component';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-masked-number-input',
  templateUrl: './masked-number-input.component.html',
  styleUrls: ['../number-input/number-input.component.scss'],
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, NgxMaskModule, IconComponent, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskedNumberInputComponent),
      multi: true,
    },
  ],
})
export class MaskedNumberInputComponent extends PuSubscribable implements ControlValueAccessor, OnInit {
  @Input() prefix: string;
  @Input() postfix: string;
  @Input() attrPlaceholder: string;
  @Input() attrMask = 'separator.2';
  @Input() attrThousandSeparator = ',';
  @Input() min = 0;
  @Input() max: number = null;
  @Input() isSmallFont = false;

  @Input() contentCss = 'display-flex align-items-start flex-column';
  @Input() containerCss = 'display-flex flex-column';
  @Input() inputCss = 'display-flex align-items-center';
  @Input() classList = '';

  @Input() label: string;
  @Input() labelCss = 'label-input body-small-bold';
  @Input() labelRequired = false;
  @Input() labelInside = false;
  @Input() labelTooltip = '';

  @Input() icon: EIcon;
  @Input() iconTooltip = '';

  innerControl = new FormControl<any>(null);

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.innerControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), untilDestroyed(this))
      .subscribe(v => this.propagateChange(v))
      .untilDestroyed(this);
  }

  onPaste(event: ClipboardEvent, inputControl: HTMLInputElement) {
    event.preventDefault();

    const commaToDotRegex = /(\d+),(\d{2})$/;
    const dotToCommaRegex = /(\d+)\.(\d{3,})$/;
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedValue = clipboardData.getData('text')?.trim();
    if (commaToDotRegex.test(pastedValue)) {
      pastedValue = pastedValue.replace(commaToDotRegex, '$1.$2');
      inputControl.value = pastedValue;
    } else if (dotToCommaRegex.test(pastedValue)) {
      pastedValue = pastedValue.replace(dotToCommaRegex, '$1,$2');
      inputControl.value = pastedValue;
    } else {
      inputControl.value = pastedValue;
    }
    this.innerControl.setValue(parseNumber(inputControl.value.toString()));
  }

  writeValue(value: string | null): void {
    this.innerControl.setValue(value || null, { emitEvent: false });
    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateChange = (_: string | null) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateTouched = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
