export interface InspectionsFilterByAssignee {
  value: string;
  all_count: number;
  expired_count: number;
}

export enum InspectionsHeaderViewEnum {
  LIST = 'LIST',
  SUMMARY = 'SUMMARY',
}
