<div class="turnovers-arc-chart-card-container">
  <app-chart-card
    [chartIcon]="EIcon.SAD"
    [title]="selectedPhase === WorkflowPhaseType.Ready ? 'Ready' : 'Not Ready'"
    [subTitle]="subTitle"
    [isLoading]="isLoading"
    class="w-100"
    [customDescriptionTemplate]="switcher">
    <div chart class="chart-container">
      <div class="legend">
        <a
          class="legend-item"
          *ngFor="let item of chartDataList"
          [routerLink]="item.link?.href"
          [queryParams]="item.link?.queryParams">
          <div class="legend-item-title" [matTooltip]="'Jump to ' + item.label + ' in List View'">{{ item.label }}</div>
          <div class="legend-item-value">
            <div class="legend-icon" [ngStyle]="{ 'background-color': item.backgroundColor }"></div>
            <span>
              {{ item.value }} {{ item.value === 1 ? 'turn' : 'turns' }}
              <span class="legend-item-value-ext" *ngIf="item.additionalValue">
                ({{ item.additionalValue }} NOTICE)
              </span>
            </span>
          </div>
        </a>
      </div>
      <div class="chart-wrapper">
        <app-chart
          [chartDataList]="chartDataList"
          [chartType]="ChartTypes.DOUGHNUT"
          [chartConfig]="chartConfig"
          [displayLegend]="false"
          class="chart"></app-chart>
      </div>
    </div>
  </app-chart-card>
</div>

<ng-template #switcher>
  <div class="switcher">
    <app-slide-toggle
      [attrLeftLabel]="'Not Ready'"
      [attrRightLabel]="'Ready'"
      [formControl]="modeControl"></app-slide-toggle>
  </div>
</ng-template>
