import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RouteData } from '@shared/enums/route-data';

@Injectable({
  providedIn: 'root',
})
export class HelpRouterService {
  constructor(private activatedRoute: ActivatedRoute) {}

  public getGlobalPreservedParams() {
    const queryParams = this.activatedRoute?.snapshot?.queryParams || {};
    return {
      [RouteData.PORTFOLIO_ID]: queryParams[RouteData.PORTFOLIO_ID],
      [RouteData.PROPERTY_IDS]: queryParams[RouteData.PROPERTY_IDS],
    };
  }
}
