import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { sumBy } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { KpiService } from '@app/services/kpi.service';
import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';
import { selectPortfolioKpisBasedOnView } from '@dashboards/store/selectors/kpi.selectors';
import { selectWorkflowStepsEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { RouteData } from '@shared/enums/route-data';
import { RoutePath } from '@shared/enums/route-path.enum';
import { WorkflowPhaseToStepConst } from '@shared/enums/workflow-phase-to-step.const';
import { WorkflowPhaseType } from '@shared/enums/workflow-phase-type';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { ChartConfig, ChartDataItemWithLink } from '@shared/interfaces/chart-data-item';
import { HelpRouterService } from '@shared/services/help-router.service';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';

@UntilDestroy()
@Component({
  selector: 'app-turnovers-arc-chart-card',
  templateUrl: './turnovers-arc-chart-card.component.html',
  styleUrls: ['./turnovers-arc-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnoversArcChartCardComponent extends ComponentAbstract implements OnInit {
  isLoading = true;
  modeControl: UntypedFormControl = new UntypedFormControl(false);
  selectedPhase: WorkflowPhaseType = WorkflowPhaseType.NotReady;

  chartDataList: ChartDataItemWithLink[] = [];
  chartConfig: ChartConfig = {
    showTooltip: true,
    showDataLabel: false,
    cutoutLevel: 78,
    rotation: -191,
    circumference: 200,
    spacing: 10,
  };

  kpis: RestPortfolioKpi[] = [];
  subTitle: string;
  withNotice: number;

  constructor(
    protected cdr: ChangeDetectorRef,
    private store: Store<{}>,
    private kpiService: KpiService,
    protected router: Router,
    private helpRouterService: HelpRouterService
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.store
      .select(selectPortfolioKpisBasedOnView)
      .pipe(
        untilDestroyed(this),
        filter(kpis => !!kpis),
        tap(kpis => {
          this.kpis = kpis;
          this.calculate();
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.modeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.selectedPhase = value ? WorkflowPhaseType.Ready : WorkflowPhaseType.NotReady;
          this.calculate();
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private calculate() {
    this.getChartData()
      .subscribe(chartDataList => {
        this.chartDataList = chartDataList;
        this.cdr.detectChanges();
      })
      .untilDestroyed(this);
    this.calculateNotice();
    this.calculateTotal();
  }

  private getChartData(): Observable<ChartDataItemWithLink[]> {
    return this.store.select(selectWorkflowStepsEnumeration).pipe(
      filterNullish$(),
      map(steps => {
        let maxValue = 0;
        const result = WorkflowPhaseToStepConst[this.selectedPhase]
          .filter(s => s.value !== WorkflowStepEnumType.Archive)
          .map<ChartDataItemWithLink>(phaseStep => {
            const step = steps.find(x => x.enumerationValue === phaseStep.value);
            const item: ChartDataItemWithLink = {
              value: 0,
              backgroundColor: EColorPalette.cGray8,
              label: step.displayText,
            };
            const link = {
              href: ['/', this.RoutePath.TURNOVERS, RoutePath.ACTIVE_TURNOVERS],
            };
            const globalParams = this.helpRouterService.getGlobalPreservedParams();
            switch (phaseStep.value) {
              case WorkflowStepEnumType.Notice: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Notices);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.Notice,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.MoveOut: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.MoveOutTurnovers);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.MoveOut,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.MakeReady: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.MakeReady);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.MakeReady,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.Reno: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Reno);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.Reno,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.Approval: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Approval);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.Approval,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.Punch: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Punch);
                item.link = {
                  ...link,
                  queryParams: {
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.Punch,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.Marketing: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Marketing);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.Marketing,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.LeaseUp: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.Leasup);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.LeaseUp,
                  },
                };
                break;
              }
              case WorkflowStepEnumType.MoveIn: {
                item.value = this.kpiService.sum(this.kpis, PortfolioKpiType.MoveIn);
                item.link = {
                  ...link,
                  queryParams: {
                    ...globalParams,
                    [RouteData.WORKFLOW_STEP]: WorkflowStepEnumType.MoveIn,
                  },
                };
                break;
              }
            }
            maxValue = Math.max(item.value, maxValue);
            return item;
          });

        result.find(r => r.value === maxValue).backgroundColor = EColorPalette.cGreen3;
        return result;
      })
    );
  }

  private calculateNotice() {
    this.withNotice = this.kpiService.sum(this.kpis, PortfolioKpiType.Notices);
  }

  private calculateTotal() {
    this.subTitle = `${sumBy(this.chartDataList, c => c.value)} turns`;
  }
}
