import { ChangeDetectorRef, Directive } from '@angular/core';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { KpiService } from '@app/services/kpi.service';
import { PortfolioKpiType, RestPortfolioKpi } from '@dashboards/models/portfolio-kpi';

@Directive()
export abstract class KpisAbstract extends ComponentAbstract {
  trendDaysValue?: number;
  kpis: RestPortfolioKpi[] = [];

  constructor(protected kpiService: KpiService, protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  getNewValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.NewApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.NewApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.NewApplications30);
      }
    }
  }

  getCanceledValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.CanceledApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.CanceledApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.CanceledApplications30);
      }
    }
  }

  getPendingValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.PendingApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.PendingApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.PendingApplications30);
      }
    }
  }

  getDeniedValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.DeniedApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.DeniedApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.DeniedApplications30);
      }
    }
  }

  getApprovedValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ApprovedApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ApprovedApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ApprovedApplications30);
      }
    }
  }

  getConvertingValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertingApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertingApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertingApplications30);
      }
    }
  }

  getConvertedValue() {
    switch (this.trendDaysValue) {
      case 60: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertedApplications60);
      }
      case 90: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertedApplications90);
      }
      default: {
        return this.kpiService.sum(this.kpis, PortfolioKpiType.ConvertedApplications30);
      }
    }
  }
}
