import * as moment from 'moment';

export const oneSecondInMilliseconds = 1000;
export const oneMinuteInMilliseconds = oneSecondInMilliseconds * 60; // 1000 * 60
export const oneHourInMilliseconds = oneMinuteInMilliseconds * 60; // 1000 * 60 * 60
export const oneDayInMilliseconds = oneHourInMilliseconds * 24; // 1000 * 60 * 60 * 24

export function daysDiff(firstDate: Date, secondDate: Date): number {
  return moment(firstDate).diff(moment(secondDate), 'days');
}
