import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, first, map, mergeMap, tap } from 'rxjs';

import { RestapiServiceWithoutTimezone } from '@app/services/restapi.service';
import { PuSubscribable } from '@app/utils/pu-subscribe';

import { RestTimezone } from './model/timezone';
import { TimezoneHelper } from '../timezone.helper';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService extends PuSubscribable {
  private wasInitilized = false;
  private readonly currentTimezone = new ReplaySubject<RestTimezone>(1);
  public readonly currentTimezone$ = this.currentTimezone.asObservable().pipe(first());
  private currentTimezoneValue: RestTimezone;

  constructor(private service: RestapiServiceWithoutTimezone) {
    super();
  }

  getList(): Observable<RestTimezone[]> {
    return this.service.getData<RestTimezone[]>('system/getTimezones');
  }

  set(value: RestTimezone) {
    this.wasInitilized = true;
    this.currentTimezoneValue = value;
    this.currentTimezone.next(value);
    return this.service.post<void>('system/setTimezone?timeZoneId=' + value.id);
  }

  get() {
    this.wasInitilized = true;
    return this.service.getData<RestTimezone>('system/getSelectedTimezone').pipe(
      tap(value => {
        this.currentTimezoneValue = value;
        this.currentTimezone.next(value);
        // const uiTimezone = moment.tz.zone(value.uiName);
        // console.log(moment.tz(moment.utc(new Date()), value.uiName).toDate());
      })
    );
  }

  getCurrentDateOnly(): Date {
    return new Date(this.getCurrentDate().toDateString());
  }

  getCurrentDate(): Date {
    return TimezoneHelper.replaceTimezoneToClient(new Date(), this.currentTimezoneValue);
  }

  addTimezoneForEntity<T>(f: (entity: T, timezone: RestTimezone) => T) {
    if (!this.wasInitilized) {
      this.get().subscribe().untilDestroyed(this);
    }
    return mergeMap((data: T) => this.currentTimezone$.pipe(map(timezone => f(data, timezone))));
  }
}
