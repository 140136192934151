import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'lodash';
import { combineLatest, filter, map } from 'rxjs';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import {
  selectSelectedPortfolioId,
  selectSelectedPropertyIds,
} from '@dashboards/store/selectors/property-selector.selectors';
import { allPortfoliosValue } from '@main-application/application-menu/property-selector/constants';
import { selectProperties } from '@portfolio/store/portfolio.selectors';
import { getPortfolioProperties } from '@shared/functions/get-portfolio-properties.function';
import { ModalsService } from '@ui-components/modals/modals.service';

@UntilDestroy()
@Component({
  selector: 'app-non-pms-add-modal',
  templateUrl: './non-pms-add-modal.component.html',
  styleUrls: ['./non-pms-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NonPmsAddModalComponent extends PuSubscribable implements OnInit {
  form = this.formBuilder.group({
    propertyId: [null as number, [Validators.required]],
    unitName: ['non-PMS unitxxx', [Validators.required]],
  });

  formSubmited = false;
  acceptChargesText$ = this.modalService.getAcceptChargesText();

  userProperties$ = combineLatest([
    this.store.select(selectProperties),
    this.store.select(selectSelectedPortfolioId),
  ]).pipe(
    untilDestroyed(this),
    filter(([userProperties]) => !!userProperties),
    map(([userProperties, currentPortfolioId]) =>
      getPortfolioProperties(
        userProperties.filter(e => currentPortfolioId === allPortfoliosValue || e.portfolioId === currentPortfolioId)
      )
    )
  );

  constructor(
    private modalService: ModalsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NonPmsAddModalComponent>,
    private store: Store<{}>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    super();
    if (data) {
      this.form.controls.unitName.setValue(data);
    }
  }

  ngOnInit(): void {
    combineLatest([this.store.select(selectSelectedPropertyIds)])
      .pipe(untilDestroyed(this))
      .subscribe(([propertyIds]) => {
        this.form.controls.propertyId.setValue(first(propertyIds));
      })
      .untilDestroyed(this);
  }

  ok() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      this.formSubmited = true;
      this.form.markAllAsTouched();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
