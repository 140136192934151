import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, filter, first, take } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PuSubscribable } from '@app/utils/pu-subscribe';
import {
  addTurnoverBidCategory,
  addTurnoverBidCategorySuccess,
  createTurnoverBid,
  createTurnoverBidSuccess,
} from '@main-application/turnovers/store/actions/turnover-bids.actions';
import { getCompanyList } from '@main-application/turnovers/store/actions/turnovers.actions';
import {
  selectTurnoverBidAdding,
  selectTurnoverBidCategories,
} from '@main-application/turnovers/store/selectors/turnover-bids.selectors';
import { selectCompanyList } from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { getBidCategoriesRadioList } from '@shared/functions/get-bid-categories-radio-list.function';
import { getCompanyRadioList } from '@shared/functions/get-company-radio-list.function';
import { Upload } from '@shared/interfaces';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { AddUpdateTurnoverBid } from '@shared/interfaces/turnover-bids.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { ModalsService } from '@ui-components/modals/modals.service';

export interface AddBidModalResult {
  bid: AddUpdateTurnoverBid;
}

export interface AddBidModalData {
  turnoverId: number;
}

@UntilDestroy()
@Component({
  selector: 'app-add-bid-modal',
  templateUrl: './add-bid-modal.component.html',
  styleUrls: ['./add-bid-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBidModalComponent extends PuSubscribable implements OnInit {
  inProgress$: Observable<boolean>;
  formSubmitted = false;
  vendorList: IRadioButtonOption<any>[] = [];
  categoryList: IRadioButtonOption<any>[] = [];
  today = new Date();
  form = new FormGroup({
    file: new UntypedFormControl(null as Upload, [Validators.required]),
    bidAmount: new UntypedFormControl(null, [Validators.required]),
    vendorId: new UntypedFormControl(null, [Validators.required]),
    categoryId: new UntypedFormControl(null, [Validators.required]),
    bidDate: new UntypedFormControl(this.today, [Validators.required]),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddBidModalComponent, AddBidModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: AddBidModalData,
    private store: Store<{}>,
    private modalsService: ModalsService,
    private actions$: Actions,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.inProgress$ = this.store.select(selectTurnoverBidAdding);

    this.store
      .select(selectCompanyList)
      .pipe(
        untilDestroyed(this),
        filter((companyList: RestCompanyModel[]) => !!companyList),
        tap((companyList: RestCompanyModel[]) => {
          this.vendorList = getCompanyRadioList(companyList);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.store
      .select(selectTurnoverBidCategories)
      .pipe(
        untilDestroyed(this),
        tap(categories => {
          this.categoryList = getBidCategoriesRadioList(categories);
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  fileUploaded(item: AttachmentItem) {
    this.form.controls.file.setValue(item?.upload);
    this.cdr.detectChanges();
  }

  save() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.form.markAsTouched();
      this.cdr.detectChanges();
      return;
    }
    const { file, bidAmount, bidDate, vendorId, categoryId } = this.form.value;
    const result = {
      bid: {
        bidAmount,
        bidDate,
        vendorId,
        turnoverId: this.data.turnoverId,
        status: 0,
        draftInvoiceFileUploadId: file.id,
        expenseCategoryId: categoryId,
      },
    };

    this.store.dispatch(createTurnoverBid(result));

    this.actions$
      .pipe(
        untilDestroyed(this),
        ofType(createTurnoverBidSuccess),
        take(1),
        tap(action => {
          this.dialogRef.close(result);
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  close() {
    this.dialogRef.close();
  }

  addNewVendor() {
    this.modalsService
      .openNewVendorModal({})
      .afterClosed()
      .pipe(
        tap(company => {
          if (company) {
            this.form.controls.vendorId.setValue(company.id);
            this.store.dispatch(getCompanyList());
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  addNewCategory() {
    this.modalsService
      .openPrompt({
        title: 'Add new category',
        fieldName: 'Name',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        tap(result => {
          if (result.value) {
            this.actions$
              .pipe(
                untilDestroyed(this),
                ofType(addTurnoverBidCategorySuccess),
                first(),
                tap(({ categoryId }) => {
                  this.form.controls.categoryId.setValue(categoryId);
                  this.snackbarService.success(`Category ${result.value} added`);
                  this.cdr.detectChanges();
                })
              )
              .subscribe()
              .untilDestroyed(this);
            this.store.dispatch(addTurnoverBidCategory({ name: result.value }));
          }
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }
}
