import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { maxBy, sumBy } from 'lodash';
import { filter, tap } from 'rxjs/operators';

import { KpiService } from '@app/services/kpi.service';
import { KpisAbstract } from '@dashboards/components/abstract/kpis.abstract.directive';
import { selectPortfolioKpisBasedOnView } from '@dashboards/store/selectors/kpi.selectors';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartConfig, ChartDataItem } from '@shared/interfaces/chart-data-item';

@UntilDestroy()
@Component({
  selector: 'app-leasing-units-chart-card',
  templateUrl: './leasing-units-chart-card.component.html',
  styleUrls: ['./leasing-units-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeasingUnitsChartCardComponent extends KpisAbstract implements OnInit {
  isLoading = true;
  chartDataList: ChartDataItem[] = [];
  modeControl: UntypedFormControl = new UntypedFormControl();
  chartConfig: ChartConfig = {
    showTooltip: true,
    showDataLabel: true,
    maxBarThickness: 42,
    borderRadius: 3,
    dataLabelAbsolute: true,
  };
  subTitle: string;

  @Input() set trendDays(value: number | undefined) {
    this.trendDaysValue = value;
    if (this.kpis) {
      this.calculate();
    }
    this.cdr.detectChanges();
  }

  constructor(protected cdr: ChangeDetectorRef, private store: Store<{}>, protected kpiService: KpiService) {
    super(kpiService, cdr);
  }

  ngOnInit(): void {
    this.store
      .select(selectPortfolioKpisBasedOnView)
      .pipe(
        untilDestroyed(this),
        filter(kpis => !!kpis),
        tap(kpis => {
          this.isLoading = false;
          this.kpis = kpis;
          this.calculate();
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);

    this.modeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.chartConfig.dataLabelAbsolute = !value;
          this.calculate();
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private calculate() {
    this.chartDataList = this.getChartData();
    this.subTitle = `${sumBy(this.chartDataList, c => c.value)} total`;
  }

  private getChartData(): ChartDataItem[] {
    const values: ChartDataItem[] = [
      {
        value: this.getNewValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'New',
      },
      {
        value: this.getCanceledValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Canceled',
      },
      {
        value: this.getPendingValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Pending',
      },
      {
        value: this.getDeniedValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Denied',
      },
      {
        value: this.getApprovedValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Approved',
      },
      {
        value: this.getConvertingValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Converting',
      },
      {
        value: this.getConvertedValue(),
        backgroundColor: EColorPalette.cGray8,
        label: 'Converted',
      },
    ];

    const item = maxBy(values, i => i.value);
    if (item) {
      item.backgroundColor = EColorPalette.cBlue2;
      item.labelColor = EColorPalette.cWhite;
    }
    return values;
  }
}
