import { Observable, ReplaySubject, filter, take, tap } from 'rxjs';

import { PuSubscribable } from '@app/utils/pu-subscribe';

export abstract class CommonInMemoryUpdatingService<T extends { id: number }, Filter> extends PuSubscribable {
  protected _list$: ReplaySubject<T[]>;

  protected updateSavedData(updateFunction: (list: T[]) => T[]) {
    if (!this._list$ || this._list$.closed) {
      return;
    }
    this._list$
      .pipe(take(1))
      .subscribe(list => {
        const updatedList = updateFunction(list);
        this._list$.next(updatedList);
      })
      .untilDestroyed(this);
  }

  getForceServerReloadList(filterParams: Filter) {
    if (!this._list$ || this._list$.closed) {
      this._list$ = new ReplaySubject<T[]>(1);
    } else {
      this._list$.next(undefined);
    }
    this.getListInternal(filterParams)
      .subscribe({
        next: list => this._list$.next(list),
        error: error => this._list$.error(error),
      })
      .untilDestroyed(this);
    return this._list$.pipe(filter(data => typeof data !== 'undefined'));
  }

  getList(filterParams: Filter) {
    if (!this._list$ || this._list$.closed) {
      this._list$ = new ReplaySubject<T[]>(1);
      this.getListInternal(filterParams)
        .subscribe({
          next: list => this._list$.next(list),
          error: error => this._list$.error(error),
        })
        .untilDestroyed(this);
    }
    return this._list$.pipe(filter(data => typeof data !== 'undefined'));
  }

  protected abstract getListInternal(filter: Filter): Observable<T[]>;

  delete(id: number): Observable<void> {
    return this.deleteInternal(id).pipe(
      tap(() =>
        this.updateSavedData(list => {
          const index = list.findIndex(x => x.id === id);
          if (index >= 0) {
            list.splice(index, 1);
          }
          return list;
        })
      )
    );
  }

  protected abstract deleteInternal(id: number): Observable<void>;
}
