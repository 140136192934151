import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartOptions,
  DefaultDataPoint,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartTrendline from 'chartjs-plugin-trendline';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { ChartTypes } from '@dashboards/models/chart-types.enum';
import { EColorPalette } from '@shared/enums/color-palette.enum';

Chart.register(
  ArcElement,
  BarElement,
  BarController,
  LineController,
  PieController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels,
  PointElement,
  LineElement,
  ChartTrendline,
  Filler
);

Chart.defaults.set('plugins.datalabels', {
  display: false,
});

interface TrendlineLinearOptions {
  colorMin: string;
  colorMax: string;
  lineStyle: 'dotted' | 'solid';
  width: number;
}

export type LineChartDatasetOptions = ChartDataset<'line', DefaultDataPoint<'line'>> & {
  trendlineLinear?: TrendlineLinearOptions;
};

export interface LineChartDateset {
  labels: string[];
  datasets: LineChartDatasetOptions[];
}

export type LineChartOptions = ChartOptions<'line'>;

@UntilDestroy()
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends ComponentAbstract implements OnInit, OnDestroy {
  @Input() displayLegend = true;
  @Input() options: LineChartOptions = {};
  @Input() emptyTemplate: TemplateRef<any>;
  @Input() emptyChartLabel = 'No data to display';
  @Input() containerCss = '';
  @Input() legendCss = 'body-description';

  @Input() set dataset(datasets: LineChartDateset) {
    this.dataListBSubject.next(datasets);
    this.cdr.detectChanges();
  }

  @ViewChild('canvas', { static: false }) set content(canvas: ElementRef) {
    this.canvasBSubject.next(canvas);
    this.cdr.detectChanges();
  }

  id = `line-chart_${new Date().getTime()}`;
  chart: Chart;
  dataListBSubject = new BehaviorSubject<LineChartDateset>(null);
  canvasBSubject: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null);
  hasData = true;

  defaultConfiguration: ChartConfiguration<'line', DefaultDataPoint<'line'>> = {
    type: ChartTypes.LINE,
    data: {
      labels: [],
      datasets: [],
    },
  };

  defaultChartOptions: LineChartOptions = {
    responsive: true,
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: EColorPalette.cWhite,
        font: {
          size: 10,
        },
        display: false,
        formatter: v => v,
      },
    },
  };

  defaultDatasetConfiguration: LineChartDatasetOptions = {
    data: [],
    label: '',
    borderWidth: 2,
    borderColor: EColorPalette.cGreen3,
    backgroundColor: EColorPalette.cGreen3,
  };

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    combineLatest([this.dataListBSubject, this.canvasBSubject])
      .pipe(
        untilDestroyed(this),
        filter(([dataset, canvas]) => !!dataset?.datasets?.length && !!canvas),
        tap(([dataset, canvas]) => {
          this.chart = this.createChart(canvas, dataset);
          this.hasData = dataset.datasets.length > 0;
          this.cdr.detectChanges();
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  showActiveElement(index) {
    if (this.chart) {
      this.chart.setActiveElements([{ datasetIndex: 0, index }]);
      this.chart.update();
    }
  }

  reset() {
    if (this.chart) {
      this.chart.setActiveElements([]);
    }
  }

  private createChart(canvas: ElementRef, dataset: LineChartDateset): Chart {
    this.destroyChart();
    const config = {
      ...this.defaultConfiguration,
      options: { ...this.defaultChartOptions, ...this.options },
    };
    if (dataset.datasets.length) {
      config.data.labels = dataset.labels;
      config.data.datasets = dataset.datasets.map(d => ({
        ...this.defaultDatasetConfiguration,
        ...d,
      }));
    }
    return new Chart(canvas.nativeElement, config);
  }

  private destroyChart() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyChart();
  }
}
