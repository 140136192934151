import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({ standalone: true, selector: '[appFadeTextOnWidth]' })
export class CheckRoleDirective implements AfterViewInit, OnChanges {
  @Input() appFadeTextOnWidth = 250;
  @Input() titleLength: number;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.checkWidthAndApplyStyles();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkWidthAndApplyStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['titleLength']?.firstChange) this.checkWidthAndApplyStyles();
  }

  private checkWidthAndApplyStyles(): void {
    const element = this.el.nativeElement;
    const width = element.offsetWidth;

    if (width >= this.appFadeTextOnWidth) {
      this.renderer.setStyle(element, 'max-width', `${this.appFadeTextOnWidth}px`);
      this.renderer.setStyle(element, 'overflow', 'hidden');
      this.renderer.setStyle(element, 'white-space', 'nowrap');
      this.renderer.setStyle(element, '-webkit-mask-image', 'linear-gradient(90deg, #000 80%, transparent)');
      this.renderer.setStyle(element, 'mask-image', 'linear-gradient(90deg, #000 80%, transparent)');
    } else {
      this.renderer.removeStyle(element, 'max-width');
      this.renderer.removeStyle(element, 'overflow');
      this.renderer.removeStyle(element, 'white-space');
      this.renderer.removeStyle(element, '-webkit-mask-image');
      this.renderer.removeStyle(element, 'mask-image');
    }
  }
}
