import { AbstractControl, ValidatorFn } from '@angular/forms';

import { LatviaValidPhoneNumberLength, USAValidPhoneNumberLength } from '@shared/constants/country-phone-codes.const';

export const PhoneNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value) {
      return null;
    }

    const { phoneCode, phoneNumber } = parsePhoneNumber(control.value);
    const phoneCodeN = parseInt(phoneCode);
    const phoneNumberN = parseInt(phoneNumber);

    return !isNaN(phoneNumberN) &&
      (phoneNumber.length === USAValidPhoneNumberLength || phoneNumber.length === LatviaValidPhoneNumberLength) &&
      !isNaN(phoneCodeN) &&
      phoneCode.length > 0 &&
      phoneCode.length < 5
      ? null
      : { phoneNumber: true };
  };
};

export const parsePhoneNumber = (
  value: string,
  validPhoneNumberLength = USAValidPhoneNumberLength
): { phoneNumber: string; phoneCode: string } => {
  const number = (value || '').replace(/\D/g, '');
  let phoneNumber = null;
  let phoneCode = null;
  if (number.length >= validPhoneNumberLength) {
    phoneNumber = number.substring(number.length - validPhoneNumberLength);
    phoneCode = number.substring(0, number.length - validPhoneNumberLength);
  } else {
    phoneNumber = number;
    phoneCode = null;
  }
  return { phoneNumber, phoneCode };
};
