import { VacancyLossAttrType } from '@main-application/turnovers/interfaces/vacancy-loss-attr';
import { ITooltipRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export const VacancyLossTypeGross = 'gross';
export const VacancyLossTypeTurn = 'turn';
export const VacancyLossTypeUnit = 'unit';

export const VacancyLossTypeListConst: ITooltipRadioButtonOption<VacancyLossAttrType>[] = [
  {
    value: VacancyLossAttrType.Turn,
    label: VacancyLossTypeTurn,
    tooltip: '',
  },
  {
    value: VacancyLossAttrType.Unit,
    label: VacancyLossTypeUnit,
    tooltip: '',
  },
  {
    value: VacancyLossAttrType.Gross,
    label: VacancyLossTypeGross,
    tooltip: '',
  },
];
