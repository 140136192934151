import { createAction, props } from '@ngrx/store';

import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import {
  AddPropertiesToCollectionReqI,
  AddUsersToCollectionReqI,
  CreatePropertyCollectionReqI,
  ReplacePropertyManagers,
  RestPortfolioModel,
  RestPropertyCollectionModel,
  RestPropertyManagerAccessModel,
  RestPropertyModel,
  UpdatePropertyManagerModel,
} from '@shared/interfaces/rest-portfolio-model.interface';
import { UpdateTypedAttachmentToEntityModel } from '@shared/interfaces/turnover.interface';
import { RestUnitSummaryModel } from '@shared/interfaces/unit.interface';

/* Portfolios List */
export const loadAllUserPortfolios = createAction('[Portfolio] Load all portfolio');
export const loadAllUserPortfoliosSuccess = createAction(
  '[Portfolio] All portfolio loaded successfully',
  props<{ portfolioItems: RestPortfolioModel[] }>()
);
export const loadAllUserPortfoliosFailed = createAction('[Portfolio] All portfolio loading failed');

export const loadAllUserCollections = createAction('[Portfolio] loadAllUserCollections');
export const loadAllUserCollectionsSuccess = createAction(
  '[Portfolio] loadAllUserCollections success',
  props<{ collectionItems: RestPropertyCollectionModel[] }>()
);
export const loadAllUserCollectionsFailed = createAction('[Portfolio] loadAllUserCollections failed');

/* Portfolio Details */
export const loadPortfolioDetails = createAction(
  '[Portfolio] Load portfolio details',
  props<{ portfolioId: string }>()
);
export const loadPortfolioDetailsSuccess = createAction(
  '[Portfolio] portfolio details loaded successfully',
  props<{ portfolioDetails: RestPortfolioModel }>()
);
export const loadPortfolioDetailsFailed = createAction('[Portfolio] portfolio details loading failed');

/* Property Details */
export const loadPropertyDetails = createAction('[Portfolio] Load property details', props<{ propertyId: number }>());
export const loadPropertyDetailsSuccess = createAction(
  '[Portfolio] property details loaded successfully',
  props<{ propertyDetails: any }>()
);
export const loadPropertyDetailsFailed = createAction('[Portfolio] property details loading failed');

/* Update Property Details */
export const updatePortfolioName = createAction(
  '[Portfolio] Update property name',
  props<{ portfolioDetails: RestPortfolioModel }>()
);
export const updatePortfolioAutoAdd = createAction(
  '[Portfolio] Update property auto add',
  props<{ portfolioDetails: RestPortfolioModel }>()
);

export const updatePortfolioDetails = createAction(
  '[Portfolio] updatePortfolioDetails',
  props<{ portfolioDetails: RestPortfolioModel; doNotRefetchPortfolios?: boolean }>()
);
export const updatePortfolioDetailsSuccess = createAction(
  '[Portfolio] Update property details finished successfully',
  props<{ portfolioDetails: RestPortfolioModel }>()
);
export const updatePortfolioDetailsFailed = createAction('[Portfolio] Update property details failed');

/* Add new property to portfolio */
export const addPropertyToPortfolio = createAction(
  '[Portfolio] Add new property to portfolio',
  props<{ portfolioId: string; propertyIds: number[] }>()
);
/* Remove property from portfolio */
export const removePropertyFromPortfolio = createAction(
  '[Portfolio] Remove property from portfolio',
  props<{ portfolioId: any; propertyId: string }>()
);

/* Add new portfolio */
export const createNewPortfolio = createAction('[Portfolio] Add new portfolio', props<{ portfolioDetails: any }>());
/* Delete portfolio */
export const deletePortfolio = createAction(
  '[Portfolio] Remove portfolio',
  props<{ portfolioName: string; portfolioId: any }>()
);
export const clearPortfolioData = createAction('[Portfolio] Clear portfolio data');

/* Load Property Managers */
export const loadPropertyManagers = createAction('[Portfolio] Load property managers');
export const loadPropertyManagersSucceeded = createAction(
  '[Portfolio] Load property managers succeeded',
  props<{ propertyManagers: RestPropertyManagerAccessModel[] }>()
);
export const loadPropertyManagersFailed = createAction('[Portfolio] Load property managers failed');

/* Update property */
export const updateProperty = createAction('[Portfolio] Update property data', props<{ propertyDetails: any }>());
export const updatePropertyFailed = createAction('[Portfolio] Update property data failed');

/* Load unassigned properties */
export const loadAllUserUnassignedProperties = createAction('[Portfolio] Load unassigned properties');
export const loadAllUserUnassignedPropertiesSucceeded = createAction(
  '[Portfolio] Load unassigned properties succeeded',
  props<{ unassignedProperties: RestPropertyModel[] }>()
);
export const loadAllUserUnassignedPropertiesFailed = createAction('[Portfolio] Load unassigned properties failed');

/* load Property Managers For Property */
export const loadPropertyManagersForProperty = createAction(
  '[Portfolio] load Property Managers For Property',
  props<{ propertyId: number }>()
);
export const loadPropertyManagersForPropertySuccess = createAction(
  '[Portfolio] load Property Managers For Property success',
  props<{ propertyManagers: RestPropertyManagerAccessModel[] }>()
);
export const loadPropertyManagersForPropertyFailed = createAction(
  '[Portfolio] load Property Managers For Property failed'
);

/**
 * remove property managers
 */
export const removePropertyManagers = createAction(
  '[Portfolio] remove property managers',
  props<{ propertyManagerModels: UpdatePropertyManagerModel[] }>()
);
export const removePropertyManagersSuccess = createAction('[Portfolio] remove property managers success');
export const removePropertyManagersFailed = createAction('[Portfolio] remove property managers failed');

/**
 * remove property managers
 */
export const setPropertyManagers = createAction(
  '[Portfolio] set property manager',
  props<{ propertyManagerModels: UpdatePropertyManagerModel[] }>()
);
export const setPropertyManagersSuccess = createAction('[Portfolio] set property managers success');
export const setPropertyManagersFailed = createAction('[Portfolio] set property managers failed');

/**
 * change property managers
 */
export const changePropertyManagers = createAction(
  '[Portfolio] change property manager',
  props<{ replacePropertyManagers: ReplacePropertyManagers }>()
);
export const changePropertyManagersSuccess = createAction('[Portfolio] change property managers success');
export const changePropertyManagersFailed = createAction('[Portfolio] change property managers failed');

/**
 * get Property Units
 */
export const getPropertyUnits = createAction('[Portfolio] get Property Units', props<{ propertyId: number }>());
export const getPropertyUnitsSuccess = createAction(
  '[Portfolio] get Property Units success',
  props<{ propertyUnits: RestUnitSummaryModel[] }>()
);
export const getPropertyUnitsFailed = createAction('[Portfolio] get Property Units failed');

/**
 * get unit attachments
 */
export const getUnitAttachments = createAction('[Portfolio] get unit attachments', props<{ unitId: number }>());
export const getUnitAttachmentsSuccess = createAction(
  '[Portfolio] get unit attachments success',
  props<{ unitAttachments: RestGenericTypedAttachment[] }>()
);
export const getUnitAttachmentsFail = createAction('[Portfolio] get unit attachments failed');

/**
 * set unit attachment
 */
export const setUnitAttachment = createAction(
  '[Portfolio] set unit attachment',
  props<{ attachmentToEntity: UpdateTypedAttachmentToEntityModel }>()
);
export const setUnitAttachmentSuccess = createAction('[Portfolio] set unit attachment success');
export const setUnitAttachmentFail = createAction('[Portfolio] set unit attachment failed');

/**
 * delete unit attachment
 */
export const deleteUnitAttachment = createAction(
  '[Portfolio] delete unit attachment',
  props<{ unitId: number; attachment: RestGenericTypedAttachment }>()
);
export const deleteUnitAttachmentSuccess = createAction('[Portfolio] delete unit attachment success');
export const deleteUnitAttachmentFailed = createAction('[Portfolio] delete unit attachment error');

/**
 * delete file from repository
 */
export const deleteFileFromRepository = createAction(
  '[Portfolio] delete file from repository',
  props<{ fileId: number }>()
);
export const deleteFileFromRepositorySuccess = createAction('[Portfolio] delete file from repository success');
export const deleteFileFromRepositoryFailed = createAction('[Portfolio] delete file from repository failed');

// property collections

export const loadPropertyCollections = createAction(
  '[Portfolio] Load property collections',
  props<{ portfolioId: number }>()
);

export const loadPropertyCollectionsSuccess = createAction(
  '[Portfolio] Load property collections success',
  props<{ items: RestPropertyCollectionModel[] }>()
);
export const loadPropertyCollectionsFailed = createAction('[Portfolio] Load property collections error');

export const addPropertyCollection = createAction(
  '[Portfolio] Add property collections',
  props<{ data: CreatePropertyCollectionReqI }>()
);

export const addPropertyCollectionSuccess = createAction(
  '[Portfolio] Add property collections success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const addPropertyCollectionError = createAction('[Portfolio] Add property collections error');

export const deletePropertyCollection = createAction(
  '[Portfolio] Delete property collection',
  props<{ id: number; collectionName: string }>()
);

export const deletePropertyCollectionSuccess = createAction(
  '[Portfolio] Delete property collection success',
  props<{ id: number }>()
);

export const deletePropertyCollectionError = createAction('[Portfolio] Delete property collection error');

export const savePropertyCollection = createAction(
  '[Portfolio] Save property collection',
  props<{ name: string; id: number }>()
);
export const savePropertyCollectionSuccess = createAction(
  '[Portfolio] Save property collection success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const savePropertyCollectionError = createAction('[Portfolio] Save property collection error');

export const addPropertiesToCollection = createAction(
  '[Portfolio] Add properties to collection',
  props<{ data: AddPropertiesToCollectionReqI & { collectionId: number } }>()
);

export const addPropertiesToCollectionSuccess = createAction(
  '[Portfolio] Add properties to collection success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const addPropertiesToCollectionError = createAction('[Portfolio] Add properties to collection error');

export const removePropertiesFromCollection = createAction(
  '[Portfolio] Remove properties from collection',
  props<{ data: AddPropertiesToCollectionReqI & { collectionId: number } }>()
);

export const removePropertiesFromCollectionSuccess = createAction(
  '[Portfolio] Remove properties from collection success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const removePropertiesFromCollectionError = createAction('[Portfolio] Remove properties from collection error');

export const addUsersToCollection = createAction(
  '[Portfolio] Add users to collection',
  props<{ data: AddUsersToCollectionReqI & { collectionId: number } }>()
);

export const addUsersToCollectionSuccess = createAction(
  '[Portfolio] Add users to collection success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const removeUsersFromCollection = createAction(
  '[Portfolio] Remove users from collection',
  props<{ data: AddUsersToCollectionReqI & { collectionId: number } }>()
);

export const removeUsersFromCollectionSuccess = createAction(
  '[Portfolio] Remove users from collection success',
  props<{ collection: RestPropertyCollectionModel }>()
);

export const removeUsersFromCollectionError = createAction('[Portfolio] Remove users from collection error');

export const addUsersToCollectionError = createAction('[Portfolio] Add users to collection error');

/**
 * clear Property Data
 */
export const clearPropertyData = createAction('[Portfolio] clear property data');
