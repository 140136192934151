import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPortfolio from './portfolio.reducer';

export const selectPortfolioState = createFeatureSelector<fromPortfolio.PortfolioState>(
  fromPortfolio.portfolioFeatureKey
);

export const selectPortfolios = createSelector(selectPortfolioState, portfolioState => portfolioState.portfolioItems);

export const selectPortfoliosLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioItemsLoading
);

export const selectPortfolioDetails = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioDetails
);
export const selectPortfolioDetailsLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioDetailsLoading
);

export const selectPropertyDetails = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyDetails
);
export const selectPropertyDetailsLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyDetailsLoading
);
export const selectPropertyDetailsUpdateOngoing = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyDetailsUpdateOngoing
);

export const selectProperties = createSelector(selectPortfolioState, portfolioState => portfolioState.userProperties);

export const portfolioSelectAllPropertyIds = createSelector(selectPortfolioState, portfolioState =>
  portfolioState?.userProperties ? portfolioState?.userProperties.map(el => el.id) : []
);

export const selectPropertiesByPortfolioId = (portfolioId: number) =>
  createSelector(selectPortfolioState, portfolioState =>
    (portfolioState?.userProperties || []).filter(property => property.portfolioId === portfolioId)
  );

export const selectPropertiesLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.userPropertiesLoading
);

export const selectPortfolioUpdate = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioUpdateOngoing
);

export const selectPortfolioNewPropertyUpdateOngoing = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioNewPropertyUpdateOngoing
);
export const selectPortfolioRemovePropertyUpdateOngoing = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.portfolioRemovePropertyUpdateOngoing
);

export const selectPortfolioDeleteOngoing = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.deletePortfolioOngoing
);

export const selectUnassignedProperties = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.unassignedProperties
);

export const selectUnassignedPropertiesLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.unassignedPropertiesLoading
);

export const selectPropertyManagersForProperty = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyManagersForProperty
);

export const selectPropertyUnits = createSelector(selectPortfolioState, portfolioState => portfolioState.propertyUnits);

export const selectPropertyUnitsLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyUnitsLoading
);

export const selectUnitAttachments = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.unitAttachments
);

export const selectDeleteUnitAttachmentInProgress = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.deleteUnitAttachmentInProgress
);

export const selectPropertyCollections = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyCollections
);

export const selectPropertyCollectionsLoading = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyCollectionsLoading
);

export const selectPropertyCollectionsDeleteInProgress = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.propertyCollectionDeleteInProgress
);

export const selectCollectionById = (id: number) =>
  createSelector(selectPortfolioState, portfolioState =>
    (portfolioState?.propertyCollections || []).find(collection => collection.id === id)
  );

export const selectUserCollections = createSelector(
  selectPortfolioState,
  portfolioState => portfolioState.collectionItems
);
