import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { UnitNonPmsService } from '@app/services/unit-non-pms.service';
import { setSelectedPropertyIds } from '@dashboards/store/actions/property-selector.actions';
import { selectSelectedPortfolioId } from '@dashboards/store/selectors/property-selector.selectors';
import { loadAllUsers } from '@main-application/administration/store/actions/administration.actions';
import { selectActiveUsers } from '@main-application/administration/store/selectors/administration.selectors';
import { NonPmsAddModalComponent } from '@main-application/boards/non-pms-add-modal/non-pms-add-modal.component';
import {
  InspectionAddDialogComponent,
  InspectionDialogData,
} from '@main-application/inspections/components/inspection-add-dialog/inspection-add-dialog.component';
import { InspectionsSummaryFiltersChangedConfig } from '@main-application/inspections/components/inspections-list/components/inspections-summary/inspections-summary.model';
import { InspectionsHeaderViewEnum } from '@main-application/inspections/models/inspections-header.model';
import { InspectionViewEnum } from '@main-application/inspections/models/inspections-views';
import { InspectionService } from '@main-application/inspections/services/inspection.service';
import {
  selectInReviewInspections,
  selectInspections,
} from '@main-application/inspections/store/inspections.selectors';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { selectUserData } from '@main-application/store/selectors/user.selectors';
import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';
import { RouteData } from '@shared/enums/route-data';
import { UserType } from '@shared/enums/user-type';
import { enumToSource } from '@shared/functions/enum-to-source.function';
import { currentLabelMe, getSortedUserItems } from '@shared/functions/get-user-list.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { IForm } from '@shared/types/form-model.type';
import { getStorageItem, setStorageItem } from '@shared/utils/extensions';
import { debounceSafe$ } from '@shared/utils/rxjs/debounced-auto-save.rxjs.util';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import {
  IInspectionFilter,
  IInspectionFilterForm,
  RepeatingInspectionFilterForm,
} from '@template/interfaces/filter.interface';
import { loadAllUserTemplates } from '@template/store/template.actions';
import { selectTemplates } from '@template/store/template.selectors';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { DefaultModalConfigConstant } from '@ui-components/modals/config/default-modal-config.constant';
import { ModalsService } from '@ui-components/modals/modals.service';

import { ASSIGNEE_FILTER_DIVIDER_POSITION, InspectionsListConstants } from './constants';
import { SelectPortfolioDialogComponent } from './dialogs/select-portfolio';
import { InspectionSectionsExpandState } from './interfaces';
import { InspectionScheduleType, InspectionStatus } from '../../models/rest-inspections-model.interface';
import {
  setInReviewInspectionsFilter,
  setInspectionsFilter,
  setNewlyAddedInspection,
  setRepeatingInspectionsFilter,
} from '../../store/inspections.actions';

export enum InspectionsType {
  User = 'user',
  Dynamic = 'dynamic',
  Repeating = 'repeating',
  Resident = 'resident',
  InReview = 'in-review',
}

const InspectionTabStorageKeys = {
  [InspectionsType.User]: LocalStorageDataEnum.INSPECTION_LIST_USER_FILTER,
  [InspectionsType.Dynamic]: LocalStorageDataEnum.INSPECTION_LIST_DYNAMIC_FILTER,
  [InspectionsType.Repeating]: LocalStorageDataEnum.INSPECTION_LIST_REPEATING_FILTER,
  [InspectionsType.Resident]: LocalStorageDataEnum.INSPECTION_LIST_RESIDENT_FILTER,
  [InspectionsType.InReview]: LocalStorageDataEnum.INSPECTION_LIST_IN_REVIEW_FILTER,
};

@UntilDestroy()
@Component({
  selector: 'app-inspections-list',
  templateUrl: './inspections-list.component.html',
  styleUrls: ['./inspections-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionsListComponent extends ComponentAbstract implements OnInit, OnDestroy {
  private readonly _currentDate = this.timezoneService.getCurrentDateOnly();

  formGroup!: FormGroup<IForm<Partial<IInspectionFilterForm>>>;
  readonly showSummaryControl = new FormControl(true);

  readonly statuses = enumToSource(InspectionStatus);
  readonly timeframes = InspectionsListConstants.timeFrames;
  readonly filterGroupKey = LocalStorageDataEnum.InspectionFilterOffsetKey;
  readonly dividerPosition = ASSIGNEE_FILTER_DIVIDER_POSITION;
  readonly InspectionsType = InspectionsType;
  readonly TimeFrame = InspectionsListConstants.TimeFrame;
  readonly InspectionsHeaderViewEnum = InspectionsHeaderViewEnum;

  protected filter: IInspectionFilter = {};

  includeArchivedList = InspectionsListConstants.includeArchivedList;

  templates$: Observable<IRadioButtonOption<number>[]> = this.initTemplates();
  parentInspectionIds$: Observable<IRadioButtonOption<number>[]> = this.initTemplatesWithParentInspectionId();
  dynamicInspectionIds$: Observable<IRadioButtonOption<number>[]> = this.initTemplatesWithDynamicInspectionId();
  repeatingInspectionsIds$ = this.getRepeatingInspectionsIds();
  dynamicInspectionsIds$ = this.getDynamicInspectionsIds();
  assignees$ = this.getSortedUsers();

  dynamicItemsAmount$ = new BehaviorSubject(0);
  repeatingItemsAmount$ = new BehaviorSubject(0);
  itemsAmount$ = new BehaviorSubject(0);
  isUnitAdding$ = new BehaviorSubject(false);
  portfolioId$ = new BehaviorSubject<number | null>(null);
  isShowCustomPeriod$ = new BehaviorSubject(false);
  customPeriod$ = new BehaviorSubject<{ startDate: string; endDate: string }>(null);
  inspectionsViewType$ = new BehaviorSubject<InspectionsHeaderViewEnum>(InspectionsHeaderViewEnum.LIST);

  maxHeight = 400;
  inspectionsType: InspectionsType;
  sectionsExpandState: InspectionSectionsExpandState = InspectionsListConstants.sectionsExpandInitialState;

  isResident$ = new BehaviorSubject(false);
  editInspectionOpened = false;

  summaryModalPending$ = new BehaviorSubject(false);
  inReviewFormExcludedItems = [
    'doNotReturnContent',
    'inReviewOnly',
    'isResident',
    'returnStandardAndResidentBoth',
    'unitOrAssignee',
  ];

  excludedFromFilterCounter: string[];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateMaxHeight();
  }

  constructor(
    cdr: ChangeDetectorRef,
    private router: Router,
    private readonly store: Store<{}>,
    private readonly formBuilder: FormBuilder,
    private readonly timezoneService: TimezoneService,
    private readonly snackbarService: SnackbarService,
    private readonly inspectionService: InspectionService,
    private dialog: MatDialog,
    private actions$: Actions,
    private unitNonPmsService: UnitNonPmsService,
    private route: ActivatedRoute,
    private modalsService: ModalsService
  ) {
    super(cdr);
  }

  get filterStorageKey(): string {
    return this.inspectionsType
      ? InspectionTabStorageKeys[this.inspectionsType]
      : this.LocalStorageDataEnum.INSPECTION_LIST_USER_FILTER;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.snackbarService.dismiss();
  }

  ngOnInit(): void {
    this.route.url
      .subscribe(urlSegments => {
        this.inspectionsType = urlSegments.map(segment => segment.path).join('/') as InspectionsType;
      })
      .untilDestroyed(this);

    this.excludedFromFilterCounter = this.getExcludedFromFilterCounter();

    this.formGroup = this.initFormGroup();

    this.sectionsExpandState = {
      Dynamic: this.inspectionsType === InspectionsType.Dynamic,
      Repeating: this.inspectionsType === InspectionsType.Repeating,
      Standard: this.inspectionsType === InspectionsType.User,
      Resident: this.inspectionsType === InspectionsType.Resident,
      InReview: this.inspectionsType === InspectionsType.InReview,
    };
    if (this.inspectionsType === 'user') {
      this.isResident$.next(false);
    } else if (this.inspectionsType === 'resident') {
      this.isResident$.next(true);
    }

    if (this.inspectionsType !== InspectionsType.User && this.inspectionsType !== InspectionsType.InReview) {
      this.formGroup.patchValue({
        parentInspectionId: null,
        dynamicInspectionId: null,
        timeframe: null,
      });
    }

    if (this.inspectionsType !== InspectionsType.User && this.inspectionsType !== InspectionsType.InReview) {
      this.formGroup.patchValue({
        status: null,
      });
    }

    if (
      this.inspectionsType !== InspectionsType.User &&
      this.inspectionsType !== InspectionsType.Resident &&
      this.inspectionsType !== InspectionsType.InReview
    ) {
      this.formGroup.patchValue({
        includeArchived: null,
      });
    }

    const savedFilterState = getStorageItem<IInspectionFilterForm>(this.filterStorageKey);
    if (savedFilterState && this.inspectionsType !== InspectionsType.InReview) {
      //TODO: is it a magic?
      savedFilterState.startDate = savedFilterState.startDate
        ? new Date(savedFilterState.startDate)
        : savedFilterState.startDate;
      savedFilterState.endDate = savedFilterState.endDate
        ? new Date(savedFilterState.endDate)
        : savedFilterState.endDate;
      this.store.dispatch(setInspectionsFilter(savedFilterState));
      this.formGroup.patchValue(savedFilterState);
      this.showSummaryControl.patchValue(savedFilterState?.showSummary);

      this.setFilters(savedFilterState);
    } else if (savedFilterState && this.inspectionsType === InspectionsType.InReview) {
      this.store.dispatch(setInReviewInspectionsFilter(savedFilterState));
      this.formGroup.patchValue(savedFilterState);
      this.setFilters(savedFilterState);
    }

    this.store.dispatch(loadAllUserTemplates());
    this.useLoadUsers();
    // this.store.dispatch(loadAllUsers({}));

    this.formGroup.valueChanges
      .pipe(debounceSafe$(), untilDestroyed(this))
      .subscribe(value => {
        let patchForm = false;

        if (
          value.timeframe &&
          this.filter.timeframe &&
          value.timeframe != this.filter.timeframe &&
          this.filter.timeframe !== this.TimeFrame.Custom &&
          this.inspectionsType !== InspectionsType.InReview
        ) {
          switch (value.timeframe) {
            case this.TimeFrame.Week:
              value.startDate = moment(this._currentDate).subtract().week(1).toDate();
              break;
            case this.TimeFrame.Month:
              value.startDate = moment(this._currentDate).subtract().month(1).toDate();
              break;
            case this.TimeFrame.Month_3:
              value.startDate = moment(this._currentDate).subtract().month(3).toDate();
              break;
            case this.TimeFrame.Month_6:
              value.startDate = moment(this._currentDate).subtract().month(6).toDate();
              break;
            case this.TimeFrame.Year:
              value.startDate = moment(this._currentDate).subtract().year(1).toDate();
              break;
            case this.TimeFrame.AllTime:
              value.startDate = moment(this._currentDate).year(2000).toDate();
              break;
          }

          value.endDate = moment(this._currentDate).add(1, 'days').toDate();

          patchForm = true;
        }

        this.updateFilters(value);

        if (patchForm) {
          this.formGroup.patchValue(this.filter);
        }
      })
      .untilDestroyed(this);

    this.showSummaryControl.valueChanges
      .pipe(debounceSafe$())
      .subscribe(value => this.setFilters({ showSummary: value }))
      .untilDestroyed(this);

    this.actions$
      .pipe(
        ofType(setNewlyAddedInspection),
        tap(action => {
          if (action.inspection) {
            switch (action.inspectionType) {
              case InspectionScheduleType.DynamicMoveIn:
              case InspectionScheduleType.DynamicMoveOut:
              case InspectionScheduleType.DynamicMakeReady:
              case InspectionScheduleType.DynamicNotice: {
                this.sectionsExpandState['Dynamic'] = this.inspectionsType === InspectionsType.Dynamic;
                break;
              }
              case InspectionScheduleType.Repeating: {
                this.sectionsExpandState['Repeating'] = this.inspectionsType === InspectionsType.Repeating;
                break;
              }
              case InspectionScheduleType.Standard: {
                this.sectionsExpandState['Standard'] = this.inspectionsType === InspectionsType.User;
                this.sectionsExpandState['Resident'] = this.inspectionsType === InspectionsType.User;
                break;
              }
            }
            this.clearFilter(true);
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe()
      .untilDestroyed(this);

    if (this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.InReview) {
      this.route.queryParams
        .subscribe(params => {
          const parentInspectionId = params['parentInspectionId'];
          const dynamicInspectionId = params['dynamicInspectionId'];
          if (parentInspectionId || dynamicInspectionId) {
            this.formGroup.patchValue({
              unitOrAssignee: null,
              status: null,
              assigneeId: null,
              templateIds: null,
              parentInspectionId: parentInspectionId ? [+parentInspectionId] : null,
              dynamicInspectionId: dynamicInspectionId ? [+dynamicInspectionId] : null,
              timeframe: null,
              includeArchived:
                this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.Resident
                  ? InspectionsListConstants.IncludeArchived.No
                  : null,
            });
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { parentInspectionId: null, dynamicInspectionId: null },
              queryParamsHandling: 'merge',
            });
          }
        })
        .untilDestroyed(this);
    }

    this.calculateMaxHeight();

    this.setPortfolioId();

    this.setInspectionViewType();

    this.route.queryParams
      .subscribe(params => {
        const inspectionView = params[InspectionViewEnum.INSPECTION_VIEW];

        if (inspectionView && inspectionView !== this.inspectionsViewType$.value) {
          this.inspectionsViewType$.next(inspectionView);
        }
      })
      .untilDestroyed(this);
  }

  private setPortfolioId(): void {
    this.store
      .select(selectSelectedPortfolioId)
      .pipe(
        filterNullish$(),
        tap(portfolioId => {
          this.portfolioId$.next(portfolioId);
        }),
        untilDestroyed(this)
      )
      .subscribe()
      .untilDestroyed(this);
  }

  summaryStatsFiltersApplied(value: boolean): void {
    this.summaryModalPending$.next(value);
  }

  updateFiltersFromSummaryReport(value: InspectionsSummaryFiltersChangedConfig): void {
    this.formGroup.patchValue({
      status: value.status,
      assigneeId: value.assigneeId,
      templateIds: value.templateIds,
      timeframe: value.timeframe,
      startDate: new Date(value.startDate),
      endDate: new Date(value.endDate),
    });

    if (value.groupByPropertyId) {
      const propertyIds = [value.groupByPropertyId];

      this.store.dispatch(setSelectedPropertyIds({ propertyIds: propertyIds, allPropertiesSelected: false }));

      this.updateRouterParam({
        [RouteData.PORTFOLIO_ID]: String(this.portfolioId$.value),
        [RouteData.PROPERTY_IDS]: JSON.stringify(propertyIds),
      });
    }

    if (value.isCustomPeriod && value.startDate && value.endDate) {
      this.isShowCustomPeriod$.next(true);
      this.customPeriod$.next({ startDate: value.startDate, endDate: value.endDate });
    }
  }

  private updateRouterParam(params: Params) {
    const currentParams = this.route.snapshot.queryParams;
    const currentFragment = this.route.snapshot.fragment;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...currentParams, ...params },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      fragment: currentFragment,
    });
  }

  private updateFilters(value: Partial<IInspectionFilterForm>): void {
    this.setFilters(value);

    if (this.inspectionsType === InspectionsType.InReview) {
      this.store.dispatch(setInReviewInspectionsFilter(value));
    } else if (this.inspectionsType === InspectionsType.Repeating) {
      this.store.dispatch(setRepeatingInspectionsFilter(value));
    } else {
      this.store.dispatch(setInspectionsFilter(value));
    }
  }

  private useLoadUsers() {
    const isResident = this.inspectionsType === InspectionsType.Resident;

    const getTypesResident = [UserType.Resident, UserType.Employee, UserType.Contractor];
    const getTypesBase = [UserType.Employee, UserType.Contractor];
    const userGroupTypes = isResident ? getTypesResident : getTypesBase;

    this.store.dispatch(loadAllUsers({ filter: { userGroupTypes } }));
  }

  private setFilters(value: Partial<IInspectionFilterForm>): void {
    if (this.inspectionsType === InspectionsType.InReview) {
      this.filter = {
        propertyIds: this.filter.propertyIds,
        propertyId: this.filter.propertyId,
        assigneeId: value.assigneeId,
        unitOrAssignee: value.unitOrAssignee,
        templateIds: value.templateIds,
        parentInspectionId: value.parentInspectionId,
        dynamicInspectionId: value.dynamicInspectionId,
      };
    } else if (this.inspectionsType === InspectionsType.Repeating) {
      this.filter = {
        propertyIds: this.filter.propertyIds,
        propertyId: this.filter.propertyId,
        assigneeId: value.assigneeId,
        unitOrAssignee: value.unitOrAssignee,
        templateIds: value.templateIds,
      };
    } else {
      this.filter = {
        ...this.filter,
        ...value,
      };
    }

    setStorageItem(this.filterStorageKey, this.filter);
  }

  clearFilter(keepTimeFrame = false): void {
    if (this.formGroup.get('unitOrAssignee')) this.formGroup.get('unitOrAssignee').setValue(null);
    if (this.formGroup.get('status')) this.formGroup.get('status').setValue(null);
    if (this.formGroup.get('assigneeId')) this.formGroup.get('assigneeId').setValue(null);
    if (this.formGroup.get('templateIds')) this.formGroup.get('templateIds').setValue(null);
    if (this.formGroup.get('timeframe')) {
      keepTimeFrame
        ? this.formGroup.get('timeframe').setValue(this.formGroup.value.timeframe)
        : this.formGroup.get('timeframe').setValue(null);
    }
    if (this.formGroup.get('includeArchived')) {
      this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.Resident
        ? this.formGroup.get('includeArchived').setValue(0)
        : this.formGroup.get('includeArchived').setValue(null);
    }
    if (this.formGroup.get('parentInspectionId')) this.formGroup.get('parentInspectionId').setValue(null);
    if (this.formGroup.get('dynamicInspectionId')) this.formGroup.get('dynamicInspectionId').setValue(null);
    if (this.formGroup.get('startDate')) {
      keepTimeFrame
        ? this.formGroup.get('startDate').setValue(moment(this._currentDate).subtract(30, 'days').toDate())
        : this.formGroup.get('startDate').setValue(null);
    }
    if (this.formGroup.get('endDate')) {
      keepTimeFrame
        ? this.formGroup.get('endDate').setValue(moment(this._currentDate).add(1, 'days').toDate())
        : this.formGroup.get('endDate').setValue(null);
    }
    this.cdr.markForCheck();
  }

  clearSearchControl(): void {
    this.formGroup.patchValue({ unitOrAssignee: null as string });
  }

  addInspection(inspectionFormat = InspectionScheduleType.Standard) {
    this.editInspectionOpened = true;
    if (this.inspectionsType === InspectionsType.Dynamic) {
      inspectionFormat = InspectionScheduleType.DynamicNotice;
    }
    if (this.inspectionsType === InspectionsType.Repeating) {
      inspectionFormat = InspectionScheduleType.Repeating;
    }
    if (this.inspectionsType === InspectionsType.Resident) {
      inspectionFormat = InspectionScheduleType.Resident;
    }

    this.portfolioId$
      .pipe(
        take(1),
        switchMap(portfolioId => {
          if (portfolioId === -1) {
            const config: MatDialogConfig = {
              width: '610px',
              height: 'auto',
              enterAnimationDuration: '0ms',
              panelClass: 'no-padding-dialog',
            };

            const dialogRef = this.dialog.open(SelectPortfolioDialogComponent, config);

            return dialogRef.componentInstance.onSelected.pipe(
              filter(Boolean),
              take(1),
              tap(() => dialogRef.close()),
              switchMap(portfolioId => dialogRef.afterClosed().pipe(map(() => portfolioId)))
            );
          }

          return of(portfolioId);
        }),
        switchMap(portfolioId => {
          return this.dialog
            .open<InspectionAddDialogComponent, InspectionDialogData>(InspectionAddDialogComponent, {
              data: {
                inspectionFormat,
                portfolioId,
              },
            })
            .afterClosed()
            .pipe(take(1));
        })
      )
      .subscribe(() => {
        this.editInspectionOpened = false;
      })
      .untilDestroyed(this);
  }

  toggleSection(name: string, isExpanded: boolean) {
    if (isExpanded) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  addNonPmsUnit() {
    const config: MatDialogConfig = {
      ...DefaultModalConfigConstant,
      width: '400px',
      panelClass: 'user-preview-modal-container',
    };

    this.dialog
      .open<void, void, { unitName: string; propertyId: number }>(NonPmsAddModalComponent, config)
      .afterClosed()
      .pipe(
        take(1),
        filter(unitInfo => !!unitInfo),
        map(({ unitName, propertyId }) => ({
          propertyId: propertyId,
          unitName: unitName,
        })),
        switchMap(({ propertyId, unitName }) => {
          if (!unitName) {
            return of({});
          }
          this.isUnitAdding$.next(true);
          return this.unitNonPmsService
            .add({
              propertyId: propertyId,
              name: unitName,
            })
            .pipe(
              tap((unitId: number) => {
                this.snackbarService.success(`Unit ${unitName} created`);
              })
            );
        }),
        finalize(() => {
          this.isUnitAdding$.next(false);
        })
      )
      .subscribe()
      .untilDestroyed(this);
  }

  private initTemplates(): Observable<IRadioButtonOption<number>[]> {
    return this.store.select(selectTemplates).pipe(
      filterNullish$(),
      map(list =>
        list.filter((e, i, array) => array.indexOf(e) === i && e).map((e, i) => ({ value: e.id, label: e.name }))
      )
    );
  }

  private initTemplatesWithParentInspectionId(): Observable<IRadioButtonOption<number>[]> {
    return combineLatest([
      this.store.select(selectInspections),
      this.store.select(selectInReviewInspections),
      this.store.select(selectTemplates),
    ]).pipe(
      filter(
        ([inspectionList, inReviewInspectionList, templates]) =>
          !!inspectionList && !!inReviewInspectionList && !!templates
      ),
      map(([inspectionList, inReviewInspectionList, templates]) => {
        const inspections = this.inspectionsType === InspectionsType.InReview ? inReviewInspectionList : inspectionList;
        const uniqueParentIds = new Set<number>();
        return inspections
          .filter(inspection => inspection.parentInspectionId)
          .filter(inspection => {
            if (!uniqueParentIds.has(inspection.parentInspectionId)) {
              uniqueParentIds.add(inspection.parentInspectionId);
              return true;
            }
            return false;
          })
          .map(inspection => {
            const template = templates.find(t => t.id === inspection.inspectionTemplateId);
            const templateName = template ? template.name : 'Unknown Template';
            return {
              value: inspection.parentInspectionId,
              label: `${inspection.unit.property.name} - ${templateName}`,
            };
          });
      })
    );
  }

  private initTemplatesWithDynamicInspectionId(): Observable<IRadioButtonOption<number>[]> {
    return combineLatest([
      this.store.select(selectInspections),
      this.store.select(selectInReviewInspections),
      this.store.select(selectTemplates),
    ]).pipe(
      filter(
        ([inspectionList, inReviewInspectionList, templates]) =>
          !!inspectionList && !!inReviewInspectionList && !!templates
      ),
      map(([inspectionList, inReviewInspectionList, templates]) => {
        const inspections = this.inspectionsType === InspectionsType.InReview ? inReviewInspectionList : inspectionList;
        const uniqueDynamicIds = new Set<number>();
        return inspections
          .filter(inspection => inspection.dynamicInspectionId)
          .filter(inspection => {
            if (!uniqueDynamicIds.has(inspection.dynamicInspectionId)) {
              uniqueDynamicIds.add(inspection.dynamicInspectionId);
              return true;
            }
            return false;
          })
          .map(inspection => {
            const template = templates.find(t => t.id === inspection.inspectionTemplateId);
            const templateName = template ? template.name : 'Unknown Template';
            return {
              value: inspection.dynamicInspectionId,
              label: ` ${inspection.unit.property.name} - ${templateName}`,
            };
          });
      })
    );
  }

  private initFormGroup(): FormGroup<IForm<Partial<IInspectionFilterForm>>> {
    if (this.inspectionsType === InspectionsType.InReview) {
      return this.formBuilder.group({
        unitOrAssignee: null,
        assigneeId: null,
        templateIds: null,
        parentInspectionId: null,
        dynamicInspectionId: null,
      }) as FormGroup<IForm<Partial<IInspectionFilterForm>>>;
    }

    if (this.inspectionsType === InspectionsType.Repeating) {
      return this.formBuilder.group({
        unitOrAssignee: null,
        assigneeId: null,
        templateIds: null,
      }) as FormGroup<IForm<Partial<RepeatingInspectionFilterForm>>>;
    }

    return this.formBuilder.group({
      unitOrAssignee: null,
      status: null,
      // isBulk: null,
      assigneeId: null,
      templateIds: null,
      timeframe: InspectionsListConstants.TimeFrame.Month,
      includeArchived:
        this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.Resident
          ? InspectionsListConstants.IncludeArchived.No
          : null,
      parentInspectionId: null,
      dynamicInspectionId: null,
      startDate: null,
      endDate: null,
    }) as FormGroup<IForm<Partial<IInspectionFilterForm>>>;
  }

  private getSortedUsers(): Observable<IRadioButtonOption<number>[]> {
    return combineLatest([this.store.select(selectUserData), this.store.select(selectActiveUsers)]).pipe(
      map(([userData, activeUsers]) => {
        const sortedList = [{ value: -1, label: 'Unassigned' }].concat(getSortedUserItems(activeUsers));
        const currentUserIndex = sortedList.findIndex(user => user.value === userData.id);
        if (currentUserIndex > 1) {
          const [currentUser] = sortedList.splice(currentUserIndex, 1);
          sortedList.splice(1, 0, { ...currentUser, label: currentLabelMe(currentUser.label) });
        }

        return sortedList;
      })
    );
  }

  getDynamicInspectionsIds(): Observable<{ dynamicInspectionId: number; count: number }[]> {
    const inspections$ =
      this.inspectionsType === InspectionsType.InReview
        ? this.store.select(selectInReviewInspections)
        : this.store.select(selectInspections);

    return inspections$.pipe(
      filterNullish$(),
      map(inspections => {
        const inspectionCountMap = new Map<number, number>();

        inspections
          .filter(inspection => inspection.dynamicInspectionId)
          .forEach(inspection => {
            const dynamicId = inspection.dynamicInspectionId;
            if (inspectionCountMap.has(dynamicId)) {
              inspectionCountMap.set(dynamicId, inspectionCountMap.get(dynamicId)! + 1);
            } else {
              inspectionCountMap.set(dynamicId, 1);
            }
          });

        return Array.from(inspectionCountMap, ([dynamicInspectionId, count]) => ({
          dynamicInspectionId,
          count,
        }));
      })
    );
  }

  getRepeatingInspectionsIds(): Observable<{ parentInspectionId: number; count: number }[]> {
    const inspections$ =
      this.inspectionsType === InspectionsType.InReview
        ? this.store.select(selectInReviewInspections)
        : this.store.select(selectInspections);

    return inspections$.pipe(
      filterNullish$(),
      map(inspections => {
        const inspectionCountMap = new Map<number, number>();

        inspections
          .filter(inspection => inspection.parentInspectionId)
          .forEach(inspection => {
            const parentId = inspection.parentInspectionId;
            if (inspectionCountMap.has(parentId)) {
              inspectionCountMap.set(parentId, inspectionCountMap.get(parentId)! + 1);
            } else {
              inspectionCountMap.set(parentId, 1);
            }
          });

        return Array.from(inspectionCountMap, ([parentInspectionId, count]) => ({
          parentInspectionId,
          count,
        }));
      })
    );
  }

  private calculateMaxHeight(): void {
    const itemHeight = 40;
    const screenHeight = window.innerHeight;
    this.maxHeight = Math.floor(screenHeight / itemHeight) * itemHeight - 210;
  }

  private setInspectionViewType(): void {
    if (this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.Resident) {
      const inspectionView = getStorageItem(LocalStorageDataEnum.INSPECTION_VIEW_MODE);
      if (inspectionView) {
        this.updateRouteAndState(inspectionView[InspectionViewEnum.INSPECTION_VIEW]);
      } else {
        this.changedInspectionsViewType(InspectionsHeaderViewEnum.LIST);
      }
    }
  }

  public changedInspectionsViewType(view: InspectionsHeaderViewEnum): void {
    setStorageItem(LocalStorageDataEnum.INSPECTION_VIEW_MODE, { [InspectionViewEnum.INSPECTION_VIEW]: view });

    this.updateRouteAndState(view);
  }

  private updateRouteAndState(view: InspectionsHeaderViewEnum): void {
    this.inspectionsViewType$.next(view);

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { inspectionsView: view },
        queryParamsHandling: 'merge',
      })
      .then();
  }

  private getExcludedFromFilterCounter(): string[] {
    if (this.inspectionsType === InspectionsType.InReview) {
      return [
        'doNotReturnContent',
        'inReviewOnly',
        'isResident',
        'returnStandardAndResidentBoth',
        'unitOrAssignee',
        'includeArchived',
      ];
    } else if (this.inspectionsType === InspectionsType.User || this.inspectionsType === InspectionsType.Resident) {
      return ['unitOrAssignee', 'startDate', 'endDate'];
    }
    return ['unitOrAssignee', 'startDate', 'endDate', 'includeArchived'];
  }
}
