import { InspectionsHeaderViewEnum } from '@main-application/inspections/models/inspections-header.model';

export enum INSPECTIONS_VIEWS {
  NEW_INSPECTIONS = 'new-inspections',
  SURVEY_VIEW = 'survey',
}

export enum InspectionViewEnum {
  INSPECTION_VIEW = 'inspectionsView',
}

export type InspectionViewConfig = {
  [InspectionViewEnum.INSPECTION_VIEW]: InspectionsHeaderViewEnum;
};
