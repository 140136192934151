<div class="templates-list">
  <div class="header-padding">
    <app-floating-buttons (clickEvent)="addInspection()"></app-floating-buttons>

    <app-floating-buttons [inProgress$]="isUnitAdding$" [hasMenu]="true">
      <button (click)="addInspection()" mat-menu-item>New inspection</button>
      <button (click)="addNonPmsUnit()" mat-menu-item>New non-PMS unit</button>
    </app-floating-buttons>

    <app-inspections-header
      [portfolioId]="portfolioId$ | async"
      [showSummary]="showSummaryControl.value"
      [inspectionType]="inspectionsType"
      [inspectionsViewType]="inspectionsViewType$ | async"
      (updateViewState)="changedInspectionsViewType($event)"
      (summaryModalPending)="summaryStatsFiltersApplied($event)">
      <div [formGroup]="formGroup" class="button-container">
        <app-collapsible-search-input
          class="button-item"
          formControlName="unitOrAssignee"
          [matTooltip]="'Search for anything your heart desires'"
          [attrPlaceholder]="'Search...'"
          [inputCss]="'text-color dark'"
          (clear)="clearSearchControl()"></app-collapsible-search-input>

        <app-turnover-filter-menu
          class="button-item"
          [style.padding]="'10px'"
          [matTooltip]="'More filters than you can shake a stick at'"
          [filter]="formGroup.value"
          [excludedProps]="excludedFromFilterCounter"
          (filterCleared)="clearFilter()"
          [padding]="'0 14px 14px'">
          <app-radio-button-dropdown
            [containerWidthCss]="'btn-small wp-220'"
            [label]="'Assignee:'"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [multiSelect]="true"
            [attrAppendTo]="'body'"
            [dividerPosition]="dividerPosition"
            formControlName="assigneeId"
            [autoSortList]="false"
            [filterGroupKey]="filterGroupKey"
            [items]="assignees$ | async"
            [allowSearch]="(assignees$ | async)?.length > 7"></app-radio-button-dropdown>

          <!--        <app-radio-button-dropdown-->
          <!--          [containerWidthCss]="'btn-small wp-220'"-->
          <!--          [label]="'Bulk:'"-->
          <!--          [isSmallFont]="true"-->
          <!--          [filterGroupKey]="filterGroupKey"-->
          <!--          [items]="bulkOptions"-->
          <!--          [allowSearch]="false"-->
          <!--          [isLabelInside]="true"-->
          <!--          [attrAppendTo]="'body'"-->
          <!--          formControlName="isBulk"></app-radio-button-dropdown>-->

          <app-radio-button-dropdown
            [containerWidthCss]="'btn-small wp-220'"
            [label]="'Type:'"
            [multiSelect]="true"
            [filterGroupKey]="filterGroupKey"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [items]="templates$ | async"
            [allowSearch]="(templates$ | async)?.length > 7"
            [attrAppendTo]="'body'"
            formControlName="templateIds"></app-radio-button-dropdown>

          <app-radio-button-dropdown
            *ngIf="
              InspectionsType.User === inspectionsType ||
              InspectionsType.Resident === inspectionsType ||
              InspectionsType.InReview === inspectionsType
            "
            [containerWidthCss]="'btn-small wp-220'"
            [label]="'Dynamic:'"
            [multiSelect]="true"
            [filterGroupKey]="filterGroupKey"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [items]="dynamicInspectionIds$ | async"
            [allowSearch]="(dynamicInspectionIds$ | async)?.length > 7"
            [attrAppendTo]="'body'"
            formControlName="dynamicInspectionId"></app-radio-button-dropdown>

          <app-radio-button-dropdown
            *ngIf="
              InspectionsType.User === inspectionsType ||
              InspectionsType.Resident === inspectionsType ||
              InspectionsType.InReview === inspectionsType
            "
            [containerWidthCss]="'btn-small wp-220'"
            [label]="'Repeating:'"
            [multiSelect]="true"
            [filterGroupKey]="filterGroupKey"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [items]="parentInspectionIds$ | async"
            [allowSearch]="(parentInspectionIds$ | async)?.length > 7"
            [attrAppendTo]="'body'"
            formControlName="parentInspectionId"></app-radio-button-dropdown>

          <app-radio-button-dropdown
            *ngIf="InspectionsType.User === inspectionsType || InspectionsType.Resident === inspectionsType"
            [containerWidthCss]="'btn-small wp-220'"
            [label]="'Status:'"
            [isSmallFont]="true"
            [filterGroupKey]="filterGroupKey"
            [multiSelect]="true"
            [isLabelInside]="true"
            [items]="statuses"
            [allowSearch]="statuses?.length > 7"
            [attrAppendTo]="'body'"
            formControlName="status"></app-radio-button-dropdown>

          <app-radio-button-dropdown
            *ngIf="InspectionsType.User === inspectionsType || InspectionsType.Resident === inspectionsType"
            [containerWidthCss]="'btn-small wp-220'"
            [autoSortList]="false"
            [label]="'Time:'"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [filterGroupKey]="filterGroupKey"
            [items]="timeframes"
            [allowSearch]="timeframes?.length > 7"
            [attrAppendTo]="'body'"
            formControlName="timeframe"></app-radio-button-dropdown>

          <app-date-dropdown
            *ngIf="filter.timeframe == TimeFrame.Custom"
            formControlName="startDate"
            label="From:"
            [valueCss]="'pre-text'"
            [filterGroupKey]="filterGroupKey"
            [labelInside]="true"
            [isFullWidth]="true"
            [buttonCss]="'date-dropdown-button-small'"
            [containerCss]="'display-flex flex-column'"
            [attrPlaceholder]="'Select date'"></app-date-dropdown>
          <app-date-dropdown
            *ngIf="filter.timeframe == TimeFrame.Custom"
            formControlName="endDate"
            label="To:"
            [valueCss]="'pre-text'"
            [filterGroupKey]="filterGroupKey"
            [labelInside]="true"
            [isFullWidth]="true"
            [buttonCss]="'date-dropdown-button-small'"
            [containerCss]="'display-flex flex-column'"
            [attrPlaceholder]="'Select date'"></app-date-dropdown>

          <app-radio-button-dropdown
            *ngIf="InspectionsType.User === inspectionsType || InspectionsType.Resident === inspectionsType"
            [containerWidthCss]="'btn-small wp-220'"
            [autoSortList]="false"
            [label]="'Include Archived:'"
            [isSmallFont]="true"
            [isLabelInside]="true"
            [allowClear]="false"
            [filterGroupKey]="filterGroupKey"
            [items]="includeArchivedList"
            [attrAppendTo]="'body'"
            formControlName="includeArchived"></app-radio-button-dropdown>

          <!--    TODO - Hidden for current release -->
          <!--          <div class="header-menu-notify">-->
          <!--            <mat-checkbox-->
          <!--              *ngIf="inspectionsType === InspectionsType.User || inspectionsType === InspectionsType.Resident"-->
          <!--              (click)="showSummaryControl.patchValue(!showSummaryControl.value)"-->
          <!--              color="primary"-->
          <!--              [formControl]="showSummaryControl">-->
          <!--              Show Summary-->
          <!--            </mat-checkbox>-->
          <!--          </div>-->
        </app-turnover-filter-menu>
      </div>
    </app-inspections-header>
  </div>

  <div *ngIf="(inspectionsViewType$ | async) === InspectionsHeaderViewEnum.LIST" class="template-list-wrapper">
    <div class="section" *ngIf="inspectionsType === 'dynamic'">
      <app-expand-panel
        [style.max-height.px]="maxHeight"
        [isBlockView]="true"
        [tooltip]="'Automatically create inspections when events occur such as Move Out, Move In, or Notice-to-Vacate'"
        [headerCounter]="dynamicItemsAmount$ | async"
        [header]="'Dynamic'"
        [useHidden]="true"
        (expandToggled)="toggleSection('Dynamic', $event)"
        [isExpanded]="sectionsExpandState['Dynamic']">
        <app-dynamic-inspection-list
          [editInspectionOpened]="editInspectionOpened"
          [style.max-height.px]="maxHeight"
          [maxHeight]="maxHeight - 30"
          [dynamicInspectionsIds]="dynamicInspectionsIds$ | async"
          [itemAmount$]="dynamicItemsAmount$"></app-dynamic-inspection-list>
      </app-expand-panel>
    </div>

    <div class="section" *ngIf="inspectionsType === 'repeating'">
      <app-expand-panel
        [style.max-height.px]="maxHeight"
        [isBlockView]="true"
        [tooltip]="'Create repeating inspections'"
        [headerCounter]="repeatingItemsAmount$ | async"
        [header]="'Repeating'"
        [useHidden]="true"
        (expandToggled)="toggleSection('Repeating', $event)"
        [isExpanded]="sectionsExpandState['Repeating']">
        <app-repeating-inspection-list
          [editInspectionOpened]="editInspectionOpened"
          [style.max-height.px]="maxHeight"
          [maxHeight]="maxHeight - 30"
          [repeatingInspectionsIds]="repeatingInspectionsIds$ | async"
          [itemAmount$]="repeatingItemsAmount$"></app-repeating-inspection-list>
      </app-expand-panel>
    </div>

    <div
      class="section"
      *ngIf="InspectionsType.User === inspectionsType || InspectionsType.Resident === inspectionsType">
      <app-expand-panel
        [isBlockView]="true"
        [tooltip]="'View or create one-time inspections'"
        [headerCounter]="itemsAmount$ | async"
        [header]="(isResident$ | async) ? 'Resident' : 'Standard'"
        [useHidden]="true"
        (expandToggled)="toggleSection(isResident$.value ? 'Resident' : 'Standard', $event)"
        [isExpanded]="sectionsExpandState[(isResident$ | async) ? 'Resident' : 'Standard']"
        [panelCss]="sectionsExpandState[(isResident$ | async) ? 'Resident' : 'Standard'] ? 'grid-gap-8' : 'grid-gap-8'"
        [style.max-height.px]="maxHeight">
        <app-one-time-inspection-list
          [editInspectionOpened]="editInspectionOpened"
          [summaryModalPending]="summaryModalPending$ | async"
          [style.max-height.px]="maxHeight"
          [maxHeight]="maxHeight - 70"
          [isResident]="isResident$ | async"
          [inspectionsType]="inspectionsType"
          [itemAmount$]="itemsAmount$"></app-one-time-inspection-list>
      </app-expand-panel>
    </div>

    <div class="section" *ngIf="inspectionsType === InspectionsType.InReview">
      <app-expand-panel
        [isBlockView]="true"
        [headerCounter]="itemsAmount$ | async"
        [header]="'Inspections Under Review'"
        [useHidden]="true"
        (expandToggled)="toggleSection('InReview', $event)"
        [isExpanded]="sectionsExpandState['InReview']"
        [panelCss]="'grid-gap-8'"
        [style.max-height.px]="maxHeight">
        <app-in-review-inspection-list
          [editInspectionOpened]="editInspectionOpened"
          [style.max-height.px]="maxHeight"
          [maxHeight]="maxHeight - 30"
          [itemAmount$]="itemsAmount$"></app-in-review-inspection-list>
      </app-expand-panel>
    </div>
  </div>

  <div *ngIf="(inspectionsViewType$ | async) === InspectionsHeaderViewEnum.SUMMARY" class="template-summary-wrapper">
    <div class="section">
      <app-inspections-summary
        [isResident]="isResident$ | async"
        [portfolioId]="portfolioId$ | async"
        (updateFilters)="updateFiltersFromSummaryReport($event)"
        (updateViewState)="changedInspectionsViewType($event)"></app-inspections-summary>
    </div>
  </div>
</div>
