<div>
  <div class="dialog-title">Select Portfolio</div>
  <div class="dropdowns-container">
    <app-radio-button-dropdown
      [autoSortList]="false"
      [multiSelect]="false"
      [allowSelectAll]="false"
      [items]="getPortfoliosList$ | async"
      [allowSearch]="(getPortfoliosList$ | async)?.length > 7"
      [label]="'Portfolio'"
      [containerWidthCss]="'w-123'"
      [formControl]="portfolioIdControl"
      [allowClear]="false"
      [attrPlaceholder]="'Select Portfolio'"
      [labelRequired]="true"
      attrAppendTo="body"></app-radio-button-dropdown>
  </div>
  <div class="actions">
    <button mat-stroked-button color="warn" [mat-dialog-close]="true">Cancel</button>
    <button (click)="onSelectPortfolio()" [disabled]="isFormValid" mat-flat-button color="primary">Select</button>
  </div>
</div>
