<form [formGroup]="inspectForm" class="inspect-form-content fade display-flex flex-column grid-gap-16">
  <!-- Header -->
  <div class="display-flex align-items-center justify-content-space-between grid-gap-8">
    <span class="display-flex align-items-center grid-gap-8 text-item-name">
      <span class="display-flex">
        {{ currentArea.areaName | truncateLength: 36:false }}
      </span>
      <app-icon
        class="separator-chevron"
        [svg]="EIcon.ANGLE_RIGHT"
        [iconWidth]="8"
        [iconHeight]="8"
        [iconColor]="EColorPalette.cGray2"></app-icon>
      <span class="display-flex">
        {{ inspectForm?.get('itemName')?.value | truncateLength: 36:false }}
      </span>
    </span>
    <div class="display-flex">
      <div class="display-flex grid-gap-2">
        <button
          *ngIf="hasUnsavedData(currentArea.items[currentItemIndex])"
          mat-button
          matRipple
          matTooltip="Discard local changes"
          color="primary"
          type="button"
          aria-label="help-button"
          class="icon icon-restore"
          (click)="userClearFormDataFromLocalStorage(currentArea.items[currentItemIndex])">
          <mat-icon class="display-flex align-items-center justify-content-center">restore</mat-icon>
        </button>
        <button
          *ngIf="currentArea.items[currentItemIndex]?.instructions"
          mat-button
          matRipple
          color="primary"
          type="button"
          aria-label="help-button"
          class="icon"
          (click)="openInstructions(currentArea.items[currentItemIndex]?.instructions)">
          <app-icon [svg]="EIcon.HELP" [iconWidth]="24" [iconHeight]="24" [iconColor]="EColorPalette.cGray5"></app-icon>
        </button>
      </div>
      <button
        mat-button
        matRipple
        color="primary"
        type="button"
        aria-label="close-button"
        class="icon"
        (click)="close()">
        <app-icon
          [svg]="EIcon.CLOSE_SMALL"
          [iconWidth]="24"
          [iconHeight]="24"
          [iconColor]="EColorPalette.cGray5"></app-icon>
      </button>
    </div>
  </div>

  <div class="display-flex flex-column grid-gap-16 content">
    <!-- Emojis -->
    <span
      class="text-item-section"
      *ngIf="
        inspectForm?.get('emojiDetails')?.value?.length > 0 && inspectForm?.get('emojiDetails')?.value[0]?.emojiTitle
      ">
      {{ inspectForm?.get('emojiDetails')?.value[0]?.emojiTitle | truncateLength: 36:true:'' }}
      <span class="required">*</span>
    </span>
    <div
      class="display-flex emoji-container"
      *ngIf="
        inspectForm?.get('emojiDetails')?.value?.length > 0 && inspectForm?.get('emojiDetails')?.value[0]?.emojiCode
      ">
      <div
        class="display-flex flex-column align-items-center"
        *ngFor="let emoji of inspectForm?.get('emojiDetails')?.value; let i = index"
        (click)="setEmojiDefault(i)">
        <app-template-editor-animation
          class="emoji"
          [ngClass]="[
            emoji.isDefault ? 'emoji-true' : '',
            inspectForm.get('emojiDetails').invalid && inspectForm.get('emojiDetails').touched ? 'emoji-invalid' : ''
          ]"
          [attrHeight]="'32px'"
          [attrWidth]="'32px'"
          [animationItem]="emoji.emojiCode"></app-template-editor-animation>
        <span class="display-flex align-items-center">{{ emoji.emojiName | truncateLength: 9:true }}</span>
      </div>
    </div>

    <!-- Questions -->
    <div class="display-flex flex-column grid-gap-8" *ngIf="booleanQuestions?.length > 0">
      <span class="text-item-section">
        Answer questions
        <span class="required">*</span>
      </span>
      <ng-container formArrayName="booleanQuestions">
        <ng-container *ngFor="let question of booleanQuestions?.controls; let i = index">
          <div [formGroupName]="i" class="display-flex justify-content-space-between">
            <span
              class="question"
              [ngClass]="{
                'question-invalid': question.get('answer')?.invalid && question.get('answer')?.touched
              }">
              {{ question.get('question')?.value }}
            </span>
            <div class="display-flex grid-gap-8">
              <div
                class="display-flex align-items-center justify-content-center icon-thumb icon-thumb-up"
                [ngClass]="{
                  'icon-thumb-up-chosen': question.get('answer')?.value === true,
                  'icon-thumb-invalid': question.get('answer')?.invalid && question.get('answer')?.touched
                }"
                (click)="question.get('answer').setValue(true); question.get('answer')?.markAsTouched()">
                <app-icon
                  [ngStyle]="{ opacity: question.get('answer')?.value === true ? 1 : 0.25 }"
                  [svg]="EIcon.THUMB_UP"
                  [iconWidth]="20"
                  [iconHeight]="20"
                  [iconColor]="EColorPalette.cGreen3"></app-icon>
              </div>
              <div
                class="display-flex align-items-center justify-content-center icon-thumb icon-thumb-down"
                [ngClass]="{
                  'icon-thumb-down-chosen': question.get('answer')?.value === false,
                  'icon-thumb-invalid': question.get('answer')?.invalid && question.get('answer')?.touched
                }"
                (click)="question.get('answer').setValue(false); question.get('answer')?.markAsTouched()">
                <app-icon
                  [ngStyle]="{ opacity: question.get('answer')?.value === false ? 1 : 0.25 }"
                  [svg]="EIcon.THUMB_DOWN"
                  [iconWidth]="20"
                  [iconHeight]="20"
                  [iconColor]="EColorPalette.cRed3"></app-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!-- Photo -->
    <div class="w-100 display-flex flex-column justify-content-space-between grid-gap-8">
      <span class="text-item-section">
        Add photo
        <span *ngIf="isPhotoRequired" class="required">*</span>
      </span>
      <div class="display-flex grid-gap-16">
        <app-multiple-attachments
          [cleanInputAttachment]="cleanInputAttachment"
          (attachmentItemEvent)="handleAttachment($event)"
          (isUploading)="isUploading = $event"
          [allowLink]="false"
          [isResident]="true"
          [acceptedFormats]="imageVideoFileExtensions"
          class="container-attachments"
          [ngClass]="{
            'container-attachments-filled': mappedImages.length > 0,
            'container-attachments-required':
              inspectForm.get('fileUploadIds').invalid && inspectForm.get('fileUploadIds').touched
          }"></app-multiple-attachments>
        <div
          *ngIf="mappedImages.length > 0"
          class="thumbnail-slider display-flex grid-gap-16"
          [ngClass]="{ 'thumbnail-slider display-flex grid-gap-16': mappedImages.length > 0 }">
          <div
            class="thumbnail-container display-flex align-items-center justify-content-center"
            *ngFor="let image of mappedImages; let i = index">
            <img
              class="thumbnail"
              [attr.loading]="'lazy'"
              [src]="image?.thumbnailUri || (image?.videoDuration > 0 ? thumbnailPlaceholder : image?.cloudUri)"
              alt="Image"
              width="90"
              height="90" />
            <div class="overlay" (click)="viewAllPhotos(mappedImages, true, i)">
              <span class="text-item-section desktop-only">
                View
                {{ mappedImages.length > 1 ? ' (' + (i + 1) + '/' + mappedImages.length + ')' : '' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Comments -->
    <div class="w-100 display-flex flex-column justify-content-space-between grid-gap-8">
      <span class="text-item-section">
        Add comment
        <span *ngIf="isCommentRequired" class="required">*</span>
      </span>
      <div
        class="container-comments overflow-hidden"
        [ngClass]="
          inspectForm.get('comment').invalid && inspectForm.get('comment').touched ? 'container-comments-required' : ''
        ">
        <textarea formControlName="comment" aria-label="comment" placeholder="Type your comment here..."></textarea>
      </div>
    </div>
  </div>

  <!-- Submit -->
  <div
    class="button-submit align-items-center justify-content-center"
    [ngClass]="{ 'button-submit-disabled': isUploading }"
    *ngIf="
      this.currentAreaIndex + 1 !== this.data.residentInspection.areas.length || currentItemIndex < totalItems - 1
    ">
    <app-button-menu
      [isDisabledStroke]="true"
      [options]="onSubmitOptions"
      [title]="onSubmitSelected?.label"
      [submitOnMainButtonClick]="true"
      size="xl"
      [btnResident]="true"
      (submitAction)="submitForm(onSubmitSelected?.id)"
      (action)="markAs($event)"></app-button-menu>
  </div>
  <div
    class="display-flex align-items-center justify-content-center"
    *ngIf="
      this.currentAreaIndex + 1 === this.data.residentInspection.areas.length && !(currentItemIndex < totalItems - 1)
    ">
    <button
      (click)="submitForm(true)"
      class="button-submit-simple"
      [ngClass]="{ 'button-submit-simple-disabled': isUploading }"
      type="button"
      mat-button
      [disabled]="isUploading">
      Done
    </button>
  </div>
</form>

<!-- Instructions -->
<ng-template #instructionsSheet>
  <div class="w-100 display-flex flex-column grid-gap-24">
    <div class="w-100 display-flex align-items-center justify-content-space-between">
      <span class="text-item-name">Instructions</span>
      <div class="display-flex">
        <button
          mat-button
          matRipple
          color="primary"
          type="button"
          aria-label="close-button"
          class="icon"
          (click)="closeInstructions()">
          <app-icon
            [svg]="EIcon.CLOSE_SMALL"
            [iconWidth]="24"
            [iconHeight]="24"
            [iconColor]="EColorPalette.cGray5"></app-icon>
        </button>
      </div>
    </div>
    <div class="text-instructions" [innerHTML]="currentArea?.items[currentItemIndex]?.instructions"></div>
    <div class="buttons-container">
      <button (click)="closeInstructions()" class="button-instructions" type="button" mat-button>Got it!</button>
      <button
        (click)="closeInstructions(true)"
        class="button-instructions button-instructions-secondary"
        type="button"
        mat-button>
        Don't show again
      </button>
    </div>
  </div>
</ng-template>
